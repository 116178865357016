<template>
  <div class="card">
    <div class="card-header">
      <h4 class="title text-center">Stay updated!</h4>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-12 text-center">
          <h5 class="description" style="margin-bottom: 30px">
            Join our newsletter and get news in your inbox!
          </h5>

          <form @submit.prevent="newsletterSubscription">
            <p-checkbox class="text-center" v-model="subscribed">
              <p class="m-0 p-0"> {{ $t('newsletter.subscribe_me') }}</p>
            </p-checkbox>

            <br>
            <p-button native-type="submit" slot="footer" type="primary" round class="mb-3"
                        :disabled="saving">{{ $t('common.save') }}</p-button>

            <div v-if="saving" class="loading"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.saving') }}</div>
          </form>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
  import axios from 'axios';
  import { Message } from 'element-ui';

  export default {
    name: 'NewsletterCard',
    components: {
    },
    data () {
      return {
        saving: false,
        subscribed: this.isSubscribed
      }
    },
    watch: {
        isSubscribed (newValue) {
            this.subscribed = newValue; // Update when the prop changes
        }
    },
    methods: {
      newsletterSubscription() {
        this.saving = true;

        let config = {
            method: 'patch',
            url: process.env.VUE_APP_SERVER_BASE_URL + 'users/' + this.$store.getters.getUserId + "/newsletter/",
            data: {newsletterSubscribed: this.subscribed},
            headers: {'Content-Type': 'application/merge-patch+json'}
        };

        axios(config).then(response => {
            Message.success("Subscription changed");

        }).catch(error => {
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
              this.$store.commit('logout');
              this.$router.push('/login');
          } else {
              Message.error(this.$i18n.t('common.err_try_again').toLocaleString());
              console.log(error);
          }
        }).finally(() => {this.saving = false;});
      }
    },
    props: ['isSubscribed']
  }

</script>
<style>

</style>
