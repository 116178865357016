<template>
  <div class="content">

    <div class="row">
      <div class="col-12">

        <div class="card">
          <div class="card-header">

            <h3>Orders in progress</h3>

            <div class="row">
              <div v-if="loading" class="loading col-12"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.loading') }}</div>
            </div>

            <div class="table-responsive">
              <el-table class="table-shopping"
                        style="width: 100%"
                        :data="inProgressTable">

                <el-table-column min-width="50">
                  <template slot-scope="props">
                    <p-button type="primary" size="sm" icon
                              v-on:click="$router.push({path: '/customer/order/' + props.row.id})">
                      <i class="nc-icon nc-glasses-2"></i>
                    </p-button>
                  </template>
                </el-table-column>

                <el-table-column min-width="100" label="Date" prop="date">
                </el-table-column>

                <el-table-column min-width="100">
                  <template slot-scope="props">
                    <div class="img-container">
                      <img :src="props.row.image" alt="">
                    </div>
                  </template>
                </el-table-column>
                <el-table-column min-width="220" class-name="td-name">
                  <template slot-scope="{row}">
                    <a style="font-size: 18px">{{row.title}}</a>
                    <br>
                    <small>{{row.subTitle}}</small>
                  </template>
                </el-table-column>
                <el-table-column min-width="60" label="Revision n." prop="activityNumber"></el-table-column>
                <el-table-column min-width="80" label="Status" prop="lastActivityStatus"></el-table-column>
                <el-table-column min-width="80" label="Price" header-align="right">
                  <template slot-scope="{row}">
                    <div class="text-right" style="font-size: 22px">
                      <small>&euro;</small> {{row.priceInEur}}
                    </div>
                  </template>
                </el-table-column>

              </el-table>
            </div>

          </div>
        </div>


      </div>
    </div>



    <div class="row">
      <div class="col-12">

        <div class="card">
          <div class="card-header">

            <h3>Past orders</h3>


            <div class="table-responsive">
              <el-table class="table-shopping"
                        style="width: 100%"
                        :data="completedTable">

                <el-table-column min-width="50">
                  <template slot-scope="props">
                    <p-button type="primary" size="sm" icon
                              v-on:click="$router.push({path: '/customer/order/' + props.row.id})">
                      <i class="nc-icon nc-glasses-2"></i>
                    </p-button>
                  </template>
                </el-table-column>

                <el-table-column min-width="100" label="Date" prop="date">
                </el-table-column>

                <el-table-column min-width="100">
                  <template slot-scope="props">
                    <div class="img-container">
                      <img :src="props.row.image" alt="">
                    </div>
                  </template>
                </el-table-column>
                <el-table-column min-width="220" class-name="td-name">
                  <template slot-scope="{row}">
                    <a style="font-size: 18px">{{row.title}}</a>
                    <br>
                    <small>{{row.subTitle}}</small>
                  </template>
                </el-table-column>
                <el-table-column min-width="80" label="Price" header-align="right">
                  <template slot-scope="{row}">
                    <div class="text-right" style="font-size: 22px">
                      <small>&euro;</small> {{row.priceInEur}}
                    </div>
                  </template>
                </el-table-column>

              </el-table>
            </div>

          </div>
        </div>


      </div>
    </div>

    <router-view></router-view>
  </div>
</template>

<script>
  import axios from 'axios';
  import {Table, TableColumn} from 'element-ui'
  import PButton from "src/components/UIComponents/Button";


  export default {
    name: "Orders",
    components: {
      PButton,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
    },
    data() {
      return {
        loading: true,
        error: null,
        saving: false,
        inProgressTable: [],
        completedTable: [],
      }
    },
    beforeMount() {
      this.loading = true;

      axios.get(process.env.VUE_APP_SERVER_BASE_URL + "checkouts?status[]=onGoing",
          {headers: {'X-LOCALE': this.$store.getters.getLang}})
      .then(response => {
        for(let i = 0; i< response.data['hydra:member'].length; i++) {
          let c = response.data['hydra:member'][i];

          let revisionNumber = 0;
          let lastActivityStatus = "new";
          for (let j = 0; j< c.activities.length; j++)
              if (c.activities[j].revisionNumber > revisionNumber)
              {
                  revisionNumber = c.activities[j].revisionNumber;
                  lastActivityStatus = c.activities[j].status;
              }

          let item = {
            id: c.id,
            date: (new Date(c.createdAt)).toLocaleString(),
            image: c.checkoutListingSummary.coverImage.contentUrl,
            title: c.checkoutListingSummary.listingTitle,
            subTitle: c.checkoutListingSummary.packageName,
            price: c.checkoutListingSummary.packagePrice,
            currency: c.checkoutListingSummary.currency,
            priceInEur: this.priceInEur(c.checkoutListingSummary.packagePrice, c.checkoutListingSummary.currency),
            auditorsCost: c.auditorsTotalCost > 0 ? c.auditorsTotalCost - (c.auditorsTotalCost * c.checkoutListingSummary.auditorsDuePercentage / 100) : 0,
            servicesCost: c.servicesTotalCost,
            status: c.status,
            activityNumber: revisionNumber,
            lastActivityStatus: lastActivityStatus
          };

          this.inProgressTable.push(item);
        }
      }).catch(error => {
        this.loading = false;
        if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
          this.$store.commit('logout');
          this.$router.push('/login');
        } else if (error.response && error.response.status === 404) {
          console.log("404 received. instance not found.");
          this.$router.push('/customer/dashboard');
        } else {
          this.error = 'err_data_not_received';
          console.log(error);
        }
      });


      axios.get(process.env.VUE_APP_SERVER_BASE_URL + "checkouts?status[]=completed",
          {headers: {'X-LOCALE': this.$store.getters.getLang}})
      .then(response => {
        for(let i = 0; i< response.data['hydra:member'].length; i++) {
          let c = response.data['hydra:member'][i];
          let item = {
            id: c.id,
            packageId: c.checkoutListingSummary.listingPackage.id,
            date: (new Date(c.createdAt)).toLocaleString(),
            image: c.checkoutListingSummary.coverImage.contentUrl,
            title: c.checkoutListingSummary.listingTitle,
            subTitle: c.checkoutListingSummary.packageName,
            price: c.checkoutListingSummary.packagePrice,
            currency: c.checkoutListingSummary.currency,
            priceInEur: this.priceInEur(c.checkoutListingSummary.packagePrice, c.checkoutListingSummary.currency),
            auditorsCost: c.auditorsTotalCost > 0 ? c.auditorsTotalCost - (c.auditorsTotalCost * c.checkoutListingSummary.auditorsDuePercentage / 100) : 0,
            servicesCost: c.servicesTotalCost,
            status: c.status
          };

          this.completedTable.push(item);
        }
        this.loading = false;
      }).catch(error => {
        this.loading = false;
        if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
          this.$store.commit('logout');
          this.$router.push('/login');
        }
        else if (error.response && error.response.status === 404)
        {
          console.log("404 received. instance not found.");
          this.$router.push('/customer/dashboard');
        }
        else {
          this.error = 'err_data_not_received';
          console.log(error);
        }
      });
    },
    methods: {
      priceInEur (v, from) {
          let price = this.$store.getters.transformCurrency(v, from, '€');
          return parseFloat(price.toFixed(2));
      },
    }
  }
</script>

<style scoped>

</style>
