<template>
  <div class="row">
    <div class="col-lg-4 col-md-5">
      <p class="text-center">This is a preview of your <b>card profile</b> that will be visible publicly if you decide to become a professional!</p>
      <user-card v-bind:user_id="user_id" ref="userPublicCard">

      </user-card>


      <newsletter-card v-bind:isSubscribed="user_profile.newsletter"></newsletter-card>
<!--              <members-card>-->

<!--              </members-card>-->
    </div>
    <div class="col-lg-8 col-md-7">
      <edit-profile-form v-bind:user_profile_info="user_profile" @user-info-updated="updateUserCard">
      </edit-profile-form>
    </div>
  </div>
</template>
<script>

  import EditProfileForm from './UserProfile/EditProfileForm.vue'
  import UserCard from './UserCard.vue'
  import NewsletterCard from './UserProfile/NewsletterCard.vue'
  // import MembersCard from './UserProfile/MembersCard.vue'
  import axios from 'axios';

  export default {
    components: {
      EditProfileForm,
      UserCard,
      NewsletterCard
      // MembersCard
    },
    data () {
      return {
        user_id: this.$store.getters.getUserId,
        user_profile: {
          error: null,
          firstName: '',
          lastName: '',
          company: '',
          username: this.$store.getters.getUsername,
          address: '',
          city: '',
          country: '',
          postalCode: '',
          aboutMe: '',
          language: '',
          contactPhone: '',
          contactFacebook: '',
          contactLinkedin: '',
          contactTelegram: '',
          contactTwitter: '',
          newsletter: false,
          avatarIri: ''
        },
      }
    },
    methods: {
      updateUserCard() {
        this.$refs.userPublicCard.loadUserData(this.$store.getters.getUserId);
      }
    },
    beforeMount() {
      this.loading = true;
      axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'users/' + this.$store.getters.getUserId)
      .then(response => {
        this.user_profile.firstName = response.data.firstName;
        this.user_profile.lastName = response.data.lastName;
        this.user_profile.username = response.data.username;
        this.user_profile.aboutMe = response.data.aboutMe;
        this.user_profile.language = response.data.language;
        this.user_profile.contactPhone = response.data.contactPhone;
        this.user_profile.contactFacebook = response.data.contactFacebook;
        this.user_profile.contactLinkedin = response.data.contactLinkedin;
        this.user_profile.contactTelegram = response.data.contactTelegram;
        this.user_profile.contactTwitter = response.data.contactTwitter;
        this.user_profile.address = response.data.address;
        this.user_profile.city = response.data.city;
        this.user_profile.country = response.data.country;
        this.user_profile.zipCode = response.data.zipCode;
        this.user_profile.newsletter = response.data.newsletterSubscribed;
        this.user_profile.avatarIri = response.data.avatar;

        this.loading = false;
      }).catch(error => {
        this.loading = false;
        if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
          if (error.response.data.message) {
            console.log(error.response.data.message);
            this.$store.commit('logout');
            this.$router.push('/login');
          } else {
            this.user_profile.error = 'err_user_data_not_received';
          }
        } else if (error.response && error.response.data) {
          this.user_profile.error = 'err_user_data_not_received';
          console.log(error.response.data.error);
        } else {
          this.user_profile.error = 'err_user_data_not_received';
          console.log(error);
        }
      });
    }
  }

</script>
<style>

</style>
