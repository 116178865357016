<template>
  <div class="wrapper">
<!--    <side-bar type="sidebar" :sidebar-links="$sidebar.sidebarLinks" :title="company_name">-->
<!--      <user-menu></user-menu>-->
<!--    </side-bar>-->
    <top-navbar></top-navbar>
    <div class="main-panel no-sidebar">
<!--      <top-navbar></top-navbar>-->

      <div class="content" >
        <transition name="fade" mode="out-in">
          <!-- your content here -->



          <router-view></router-view>
        </transition>
      </div>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">

</style>
<script>
  import TopNavbar from '../TopNavbar.vue'
  import ContentFooter from '../ContentFooter.vue'
  // import UserMenu from 'src/components/UIComponents/SidebarPlugin/UserMenu.vue'

  export default {
    components: {
      TopNavbar,
      ContentFooter,
      // UserMenu,
    },
    methods: {
      // toggleSidebar () {
      //   if (this.$sidebar.showSidebar) {
      //     this.$sidebar.displaySidebar(false)
      //   }
      // }
    },
    mounted: function () {
    },
    data() {
      return {
        company_name: process.env.VUE_APP_COMPANY_NAME
      }
    }
  }

</script>
