import Web3 from 'web3';
import  { clientBaseSCABI } from "./clientBaseABI";

let web3;


var blockchainSendAudit = async function (scAddress, wallet, score) {
  return new Promise((resolve, reject) => {
    if (typeof window.ethereum === 'undefined')
      return reject('Please install a wallet provider like MetaMask!');

    web3 = new Web3(window.ethereum);

    score = score * 10;
    if (score < 0 || score > 100)
      return reject("invalid score: " + score);

    if (!web3.utils.isAddress(wallet))
      return reject("invalid address: " + wallet);

    if (!web3.utils.isAddress(scAddress))
      return reject("invalid sc address: " + scAddress);

    window.ethereum.request({ method: 'eth_chainId' }).then(chainId => {
      if(chainId !== process.env.VUE_APP_BSC_CHAIN_ID_HEX)
        return reject("Please switch to the BSC network and try again " + chainId + " " + process.env.VUE_APP_BSC_CHAIN_ID);

      window.ethereum.request({ method: 'eth_requestAccounts' }).then(userAddress => {
        console.log("userAddress: " + userAddress);
        if(userAddress.toString().toLowerCase() !== wallet.toString().toLowerCase())
          return reject("You have to use this wallet: " + wallet);

        var contract = new web3.eth.Contract(clientBaseSCABI, scAddress);
        contract.methods.vote([0], [score]).send({from: userAddress.toString()}, function (error, transactionHash) {
          if (error)
            return reject(error);
          else
            return resolve(transactionHash);
        });


      }).catch(addressError => {
        console.log("no account found. " + addressError);
        return reject(addressError);
      });

    }).catch(chainIdError => {
      console.log("error while retrieving chain ID");
      return reject("chainID not found. " + chainIdError);
    });

  });
};


export {blockchainSendAudit};
