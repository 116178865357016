<template>
  <div class="content">

    <transition name="fade" mode="out-in">

      <div class="row">
        <div class="col-12">


          <modal :show.sync="modals.ratingPro.active" headerClasses="justify-content-center">
            <h4 slot="header" class="title title-up">Rating as Professional</h4>
            <div v-if="modals.ratingPro.loading" class="loading text-center"><i class="fa fa-spin fa-spinner"></i> Checking Smart Contract... </div>

            <div v-if="modals.ratingPro.error"><p>{{ modals.ratingPro.error }}</p></div>

            <div v-if="modals.ratingPro.rating !== null">
              <div v-if="modals.ratingPro.rating === 0">
                <p class="text-center">No rating received yet.</p>
              </div>

              <el-rate v-else
                       :allow-half="true"
                       :disabled="true"
                       :show-text="true"
                       :show-score="true"
                       :max="10"
                       :value="modals.ratingPro.rating"></el-rate>
            </div>
            <template slot="footer">
              <p-button type="info" v-on:click="callSCPro(true)">Scan again</p-button>
              <p-button type="default" outline @click.native="modals.ratingPro.active = false">Close</p-button>
            </template>
          </modal>




          <div class="row">
            <div v-if="loading" class="loading col-12 text-center"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.loading') }}</div>
          </div>

          <div class="row">
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 m-auto">
              <stats-card type="danger"
                          icon="nc-icon nc-badge"
                          small-title="KYC"
                          title="Unverified">
                <div class="stats" slot="footer">
                  <el-tooltip content="This service is not available at the moment">
                    <p class="m-0 p-0"><i class="nc-icon nc-refresh-69"></i> Verify your identity</p>
                  </el-tooltip>
                </div>
              </stats-card>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 m-auto">
              <stats-card type="info"
                          icon="nc-icon nc-box"
                          small-title="Subscription"
                          title="Free">
                <div class="stats" slot="footer">
                  <el-tooltip content="No other subscriptions available at the moment">
                    <p class="m-0 p-0"><i class="nc-icon nc-refresh-69"></i> Upgrade</p>
                  </el-tooltip>
                </div>
              </stats-card>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 m-auto">
              <stats-card type="success"
                          icon="nc-icon nc-check-2"
                          small-title="Activities"
                          :title="user_data ? user_data.totalSales.toString() : '-'"
                          tooltipInfo="Total activities listed on our catalogue">
                <div class="stats" slot="footer">
                  <router-link to="/professional/my_listings" style="color: #66615B">
                    <i class="nc-icon nc-glasses-2"></i> Show
                  </router-link>
                </div>
              </stats-card>
            </div>

<!--            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 m-auto">-->
<!--              <stats-card type="warning"-->
<!--                          icon="fa fa-hourglass-half"-->
<!--                          small-title="In progress"-->
<!--                          title="2">-->
<!--                <div class="stats" slot="footer">-->
<!--                  <i class="nc-icon nc-glasses-2"></i>-->
<!--                  Show-->
<!--                </div>-->
<!--              </stats-card>-->
<!--            </div>-->

            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 m-auto">
              <stats-card type="warning"
                          icon="nc-icon nc-satisfied"
                          small-title="Rating"
                          :title="statsCards.ratingPro.title">
                <div class="stats" slot="footer">
                  <button v-on:click="callSCPro(false)" class="btn btn-neutral p-0">
                    <i class="nc-icon nc-app"></i> Check in blockchain
                  </button>
                </div>
              </stats-card>
            </div>


<!--            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 m-auto">-->
<!--              <stats-card type="warning"-->
<!--                          icon="nc-icon nc-satisfied"-->
<!--                          small-title="Rating as auditor"-->
<!--                          :title="statsCards.ratingAuditor.title">-->
<!--                <div class="stats" slot="footer">-->
<!--                  <el-tooltip content="Not available at the moment">-->
<!--&lt;!&ndash;                    <button v-on:click="" class="btn btn-neutral p-0" disabled>&ndash;&gt;-->
<!--                    <p class="m-0 p-0"><i class="nc-icon nc-app"></i> Check in blockchain</p>-->
<!--&lt;!&ndash;                    </button>&ndash;&gt;-->
<!--                  </el-tooltip>-->
<!--                </div>-->
<!--              </stats-card>-->
<!--            </div>-->


<!--            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 m-auto">-->
<!--              <stats-card type="warning"-->
<!--                          icon="nc-icon nc-satisfied"-->
<!--                          small-title="Rating as Customer"-->
<!--                          :title="statsCards.ratingCustomer.title">-->
<!--                <div class="stats" slot="footer">-->
<!--                  <el-tooltip content="Not available at the moment">-->
<!--&lt;!&ndash;                    <button v-on:click="" class="btn btn-neutral p-0" disabled>&ndash;&gt;-->
<!--                    <p class="m-0 p-0"><i class="nc-icon nc-app"></i> Check in blockchain</p>-->
<!--&lt;!&ndash;                    </button>&ndash;&gt;-->
<!--                  </el-tooltip>-->
<!--                </div>-->
<!--              </stats-card>-->
<!--            </div>-->


          </div>




          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                  <div class="row">
                    <div class="col-10">
                      <h5 class="title"><i class="nc-icon nc-bullet-list-67"></i> Last activities in progress</h5>
                    </div>
                    <div class="col-2 text-right">
                      <p-button type="primary" class="btn btn-sm"
                                @click.native="$router.push({path: '/professional/activities'})">
                        See more
                      </p-button>
                    </div>
                  </div>
                </div>
                <div class="card-body table-full-width">
                  <el-table :data="lastActivities">
                    <el-table-column label="Order made on" property="date" min-width="50px"></el-table-column>
                    <el-table-column label="Started on" property="startedAt"></el-table-column>
                    <el-table-column label="Revision n." property="revisionNumber"></el-table-column>
                    <el-table-column label="status">
                      <template slot-scope="props">
                        <!-- TODO: define strings and icons for each status -->
<!--                        <i :class="props.row.status === 'completed' ? 'fa fa-check text-success' :-->
<!--                                'fa fa-hourglass-half text-warning'"></i>-->
                        {{ props.row.status }}
                      </template>
                    </el-table-column>
                  </el-table>

                </div>
              </div>
            </div>
          </div>



<!--          <div class="row">-->
<!--            <div class="col-sm-6">-->
<!--              <chart-card :chart-data="revenue.data"-->
<!--                          chart-id="activity-chart"-->
<!--                          chart-title="TOTAL REVENUE IN LAST YEAR">-->
<!--                <span slot="title"><i class="nc-icon nc-money-coins text-success"></i> $34,657</span>-->
<!--                <badge slot="title-label" type="success">+18%</badge>-->

<!--                <template slot="footer-title">Financial Statistics</template>-->

<!--                <p-button slot="footer-right" aria-label="add button" type="success" round icon size="sm">-->
<!--                  <i class="nc-icon nc-simple-add"></i>-->
<!--                </p-button>-->
<!--              </chart-card>-->
<!--            </div>-->

<!--            <div class="col-sm-6">-->
<!--              <chart-card :chart-data="rating.data"-->
<!--                          chart-id="activity-chart" color="#fbc658"-->
<!--                          chart-title="AVERAGE FEEDBACK IN LAST YEAR">-->
<!--                <span slot="title"><i class="fa fa-star text-warning"></i> 4.9</span>-->
<!--                <badge slot="title-label" type="warning">+18%</badge>-->

<!--                <template slot="footer-title">Feedback Statistics</template>-->

<!--                <p-button slot="footer-right" aria-label="add button" type="warning" round icon size="sm">-->
<!--                  <i class="nc-icon nc-simple-add"></i>-->
<!--                </p-button>-->
<!--              </chart-card>-->
<!--            </div>-->
<!--          </div>-->

        </div>
      </div>

      <router-view></router-view>
    </transition>
  </div>
</template>


<script>
  import axios from 'axios';
  import StatsCard from 'src/components/UIComponents/Cards/StatsCard';
  import ChartCard from 'src/components/UIComponents/Cards/ChartCard';
  import { Badge, Modal } from 'src/components/UIComponents';
  import {Table, TableColumn, Tooltip, Message, Rate } from 'element-ui';
  import {getReputation} from "src/util/sc/reputation";
  import PButton from "src/components/UIComponents/Button";

  export default {
    components: {
        PButton,
      StatsCard,
      ChartCard,
      [Badge.name]: Badge,
      [Modal.name]: Modal,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Tooltip.name]: Tooltip,
      [Rate.name]: Rate
    },
    data() {
      return {
        loading: true,
        user_data: null,
        images_base_url: process.env.VUE_APP_IMAGES_BASE_URL,
        statsCards: {
          ratingPro: {
              title: "-"
          },
          ratingAuditor: {
              title: "-"
          },
          ratingCustomer: {
              title: "-"
          }
        },
        modals: {
            ratingPro: {
                active: false,
                loading: true,
                rating: null,
                error: null
            }
        },
        revenue: {
          data: {
            labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            series: [542, 480, 430, 550, 530, 453, 380, 434, 568, 610, 548, 657]
          }
        },
        rating: {
          data: {
            labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            series: [4.7, 4.8, 4.3, 5.0, 5.0, 4.5, 3.8, 4.3, 5.0, 5.0, 5.0, 5.0]
          }
        },
        lastActivities: []
      }
    },
    methods: {
      getPendingActivities() {
        axios.get(process.env.VUE_APP_SERVER_BASE_URL + "checkouts/professional?status[]=onGoing",
          {headers: {'X-LOCALE': this.$store.getters.getLang}})
        .then(response => {
          for(let i = 0; i< response.data['hydra:member'].length; i++) {
            let c = response.data['hydra:member'][i];
            let creationDate = new Date(c.createdAt);
            let startDate;
              if (c.activities[0].startedAt)
                startDate = (new Date(c.activities[0].startedAt)).toLocaleString('en-GB');
              else
                startDate = "-";
              let item = {
                  date: creationDate.toLocaleString('en-GB'),
                  status: c.activities[0].status,
                  revisionNumber: c.activities.length,
                  startedAt: startDate
              };

              this.lastActivities.push(item);
          }
        }).catch(error => {
            if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                this.$store.commit('logout');
                this.$router.push('/login');
            }
            else if (error.response && error.response.status === 404)
            {
                console.log("activities not found");
            }
            else {
                this.error = 'err_data_not_received';
                console.log(error);
            }
        }).finally(() => {this.loading = false;});
      },
      callSCPro(reset) {
        this.modals.ratingPro.error = null;
        if(reset)
          this.modals.ratingPro.rating = null;

        this.modals.ratingPro.active = true;
        this.modals.ratingPro.loading = true;
        if(this.modals.ratingPro.rating === null) {
          getReputation(this.user_data.id).then(response => {
              console.log("response received: " + response);
              this.modals.ratingPro.rating = parseFloat(response);
          }).catch(error => {
              console.log("error received: " + error);
              this.modals.ratingPro.error = "An error occurred while retrieving data from Smart Contract. Please try again.";
          }).finally(() => {this.modals.ratingPro.loading = false;});
        }
        else
            this.modals.ratingPro.loading = false;
      }
    },
    beforeMount() {
        axios.get(process.env.VUE_APP_SERVER_BASE_URL + "users/professional/public/" + this.$store.getters.getUserId)
            .then(response => {
                this.user_data = response.data;
                if (this.user_data.numFeedbackAsProfessional === 0)
                    this.statsCards.ratingPro.title = "No review";
                else
                    this.statsCards.ratingPro.title = this.user_data.avgFeedbackAsProfessional + " (" + this.user_data.numFeedbackAsProfessional + ")";

            }).catch(error => {
            this.loading = false;
            if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                this.$store.commit('logout');
                this.$router.push('/login');
            }
            else {
                Message.error('err_data_not_received');
                console.log(error);
            }
        });

      this.getPendingActivities();
    }
  }
</script>
<style>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .15s
  }

  .fade-enter,
  .fade-leave-to
    /* .fade-leave-active in <2.1.8 */

  {
    opacity: 0
  }
</style>
