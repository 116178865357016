var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('h5',{staticClass:"info-text"},[_vm._v(" "+_vm._s(_vm.$t('listing.start_with_basic_info')))]),(_vm.form_error)?_c('div',{staticClass:"alert text-danger text-center"},[_c('strong',[_vm._v(_vm._s(_vm.$t(_vm.form_error)))])]):_vm._e(),_c('div',{staticClass:"row justify-content-center"},[(_vm.loading)?_c('div',{staticClass:"loading col-12"},[_c('i',{staticClass:"fa fa-spin fa-spinner"}),_vm._v(" "+_vm._s(_vm.$t('common.loading'))+"...")]):_vm._e()]),_vm._l((_vm.listingTranslations),function(tr,index){return _c('div',{staticClass:"row justify-content-center mt-5"},[(index > 0 || _vm.listingTranslations.length > 1)?_c('div',{staticClass:"col-2 col-md-1"},[_c('el-button',{staticClass:"btn-danger mt-4",on:{"click":function($event){return _vm.deleteTranslation(index)}}},[_c('i',{staticClass:"nc-icon nc-simple-remove"})])],1):_vm._e(),_c('div',{staticClass:"col-4 col-md-4"},[_c('fg-input',[_c('label',[_vm._v(_vm._s(_vm.$t('listing.select_lang'))+":")]),_c('el-select',{staticClass:"select-primary",attrs:{"size":"small"},on:{"change":_vm.handleChanges},model:{value:(tr.lang),callback:function ($$v) {_vm.$set(tr, "lang", $$v)},expression:"tr.lang"}},_vm._l((_vm.languages),function(option){return _c('el-option',{key:option,staticClass:"select-primary",attrs:{"value":option,"label":option.toUpperCase()}})}),1)],1)],1),_c('div',{staticClass:"col-6 col-md-5"},[_c('ValidationProvider',{attrs:{"name":"title","rules":'required|min:' + _vm.titleLength.min + '|max:' + _vm.titleLength.max},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('label',[_vm._v(_vm._s(_vm.$t('listing.title'))+" *")]),_c('br'),_c('fg-input',{attrs:{"name":"title","addon-left-icon":"nc-icon nc-caps-small","placeholder":_vm.$t('listing.title'),"error":failed ? _vm.$t('listing.required_and_in_range_char', {min: _vm.titleLength.min, max: _vm.titleLength.max}) : null,"hasSuccess":passed},on:{"change":_vm.handleChanges},model:{value:(tr.title),callback:function ($$v) {_vm.$set(tr, "title", $$v)},expression:"tr.title"}}),_c('span',{class:tr.title.length >= _vm.titleLength.min && tr.title.length <= _vm.titleLength.max  ? 'text-success' : 'text-danger'},[_vm._v(" "+_vm._s(tr.title.length)+" / "+_vm._s(_vm.titleLength.max)+" characters (min "+_vm._s(_vm.titleLength.min)+") ")])]}}],null,true)})],1),_c('div',{staticClass:"col-12 col-sm-10 mt-3"},[_c('ValidationProvider',{attrs:{"name":"shortDescription","rules":'required|min:' + _vm.shortDescLength.min + '|max:' + _vm.shortDescLength.max},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('label',[_vm._v(_vm._s(_vm.$t('listing.short_desc'))+" *")]),_c('br'),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(tr.shortDescription),expression:"tr.shortDescription"}],staticClass:"form-control",attrs:{"rows":"4","name":"shortDescription","placeholder":_vm.$t('listing.short_desc')},domProps:{"value":(tr.shortDescription)},on:{"change":_vm.handleChanges,"input":function($event){if($event.target.composing){ return; }_vm.$set(tr, "shortDescription", $event.target.value)}}}),_c('span',{class:tr.shortDescription.length >= _vm.shortDescLength.min && tr.shortDescription.length <= _vm.shortDescLength.max  ? 'text-success' : 'text-danger'},[_vm._v(" "+_vm._s(tr.shortDescription.length)+" / "+_vm._s(_vm.shortDescLength.max)+" characters (min "+_vm._s(_vm.shortDescLength.min)+") ")]),_c('fg-input',{attrs:{"type":"hidden","name":"shortDescription","error":failed ? _vm.$t('listing.required_and_in_range_char', {min: _vm.shortDescLength.min, max: _vm.shortDescLength.max}) : null,"hasSuccess":passed},model:{value:(tr.shortDescription),callback:function ($$v) {_vm.$set(tr, "shortDescription", $$v)},expression:"tr.shortDescription"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"description","rules":'required|min:' + _vm.descLength.min + '|max:' + _vm.descLength.max},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('label',[_vm._v(_vm._s(_vm.$t('listing.desc'))+" *")]),_c('br'),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(tr.description),expression:"tr.description"}],staticClass:"form-control",staticStyle:{"max-height":"400px","resize":"vertical","min-height":"100px"},attrs:{"rows":"6","name":"description","placeholder":_vm.$t('listing.desc')},domProps:{"value":(tr.description)},on:{"change":_vm.handleChanges,"input":function($event){if($event.target.composing){ return; }_vm.$set(tr, "description", $event.target.value)}}}),_c('span',{class:tr.description.length >= _vm.descLength.min && tr.description.length <= _vm.descLength.max  ? 'text-success' : 'text-danger'},[_vm._v(" "+_vm._s(tr.description.length)+" / "+_vm._s(_vm.descLength.max)+" characters (min "+_vm._s(_vm.descLength.min)+") ")]),_c('fg-input',{attrs:{"type":"hidden","name":"description","error":failed ? _vm.$t('listing.required_and_in_range_char', {min: _vm.descLength.min, max: _vm.descLength.max}) : null,"hasSuccess":passed},model:{value:(tr.description),callback:function ($$v) {_vm.$set(tr, "description", $$v)},expression:"tr.description"}})]}}],null,true)})],1)])}),_c('div',{staticClass:"row justify-content-center mt-5"},[_c('div',{staticClass:"col-12 col-sm-10"},[_c('el-button',{staticClass:"mt-5",on:{"click":_vm.addDomain}},[_vm._v(_vm._s(_vm.$t('listing.add_language')))]),(_vm.saving)?_c('div',{staticClass:"text-danger text-center"},[_c('i',{staticClass:"fa fa-spin fa-spinner"}),_vm._v(" "+_vm._s(_vm.$t('common.saving'))+"...")]):_vm._e(),(_vm.form_error)?_c('div',{staticClass:"alert text-danger text-center"},[_c('strong',[_vm._v(_vm._s(_vm.$t(_vm.form_error)))])]):_vm._e()],1)])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }