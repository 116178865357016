<template>
  <div class="content">

    <transition name="fade" mode="out-in">

      <div class="row">
        <div v-if="loading" class="loading col-12"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.loading') }}</div>
        <div v-if="saving" class="loading col-12"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.saving') }}</div>


        <div class="col-12 text-center my-5" v-if="!loading && listings.length === 0">
          <h5 class="m-3 text-center">You have no listings yet. Start now!</h5>
          <p-button v-on:click="$router.push({path: '/professional/new'})"
                    type="primary" round slot="footer">Create New</p-button>
        </div>


        <div class="col-4" v-for="(listing, index) in listings">
          <card type="pricing">
            <div slot="header">

              <p class="text-right">
                {{ $t('listing.status.' + listing.status) }}
                <p-switch
                           :value="(listing.status === 'online' || listing.status === 'to approve')"
                           :type="listing.status === 'online' ? 'success' : listing.status === 'to approve' ? 'warning' : 'danger'"
                           :disabled="saving || listing.status === 'not approved'"
                           v-on:input="changeStatus(listing.id, listing.status, index)"
                           >
                </p-switch>
              </p>
              <h6 class="card-category">{{ listing.title }}</h6>
            </div>
            <badge type="success" v-if="listing.category">{{ listing.category.name }}</badge>

            <div class="card-icon icon-primary" style="height: 100px">

              <img v-if="listing.coverImage" :src="listing.coverImage.contentUrl" style="max-width: 100%; max-height: 100%"/>
              <i v-else-if="listing.category" :class="listing.category.icon"></i>
              <i v-else class="nc-icon nc-spaceship"></i>
            </div>
            <h3 class="card-title">€ {{ listing.price }}</h3>
            <p style="height: 100px">{{ listing.shortDescription }}</p>
            <p-button v-on:click="$router.push({path: '/professional/new', query: { id: listing['@id'] }})"
                      :disabled="saving" type="primary" round slot="footer">{{ $t('common.edit') }}</p-button>
            <p-button v-on:click="deleteListing(listing['@id'], listing.title, index)"
                      :disabled="saving" type="danger" round slot="footer">{{ $t('common.delete') }}</p-button>
          </card>
        </div>
      </div>

      <router-view></router-view>
    </transition>
  </div>
</template>
<script>
  import { Badge } from 'src/components/UIComponents';
  import axios from 'axios';
  import Swal from 'sweetalert2';
  import { Message } from 'element-ui';
  import PSwitch from "src/components/UIComponents/Switch";

  export default {
    components: {
      [PSwitch.name]: PSwitch,
      [Badge.name]: Badge
    },
    data() {
      return {
        loading: true,
        saving: false,
        listings: [],
        images_base_url: process.env.VUE_APP_IMAGES_BASE_URL
      }
    },
    methods: {
      deleteListing(id, title, index) {
        if(id !== undefined) {
          let split = id.split("/");
          id = split[split.length-1];
          Swal.fire({
            title: "Are you sure?",
            text: "Do you confirm the deletion of the listing: \"" + title + "\"?",
            // confirmButtonClass: "btn btn-warning",
            // icon: "warning",
            buttonsStyling: false,
            showCancelButton: true,
            customClass: {
              cancelButton: "btn btn-default btn-round mx-1",
              confirmButton: "btn btn-warning btn-round mx-1",
            }
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.saving = true;
              axios.delete(process.env.VUE_APP_SERVER_BASE_URL + 'listings/' + id)
                  .then(response => {
                    console.log('deleted');
                    this.listings.splice(index, 1);
                  }).catch(error => {
                if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                  if (error.response.data.message) {
                    console.log(error.response.data.message);
                    this.$store.commit('logout');
                    this.$router.push('/login');
                  } else {
                    // this.form_error = 'Error. Try again';
                  }
                } else if (error.response && error.response.data) {
                  // this.form_error = error.response.data.error;
                } else {
                  // this.form_error = 'Unknown error';
                  console.log(error);
                }
              }).finally(() => {this.saving=false;});
            }
          });

        }
      },
      changeStatus(id, status, index) {
        this.saving = true;
        let newStatus = 'offline';
        if (status === 'new' || status === 'offline')
            newStatus = 'to approve';
        else if (status === 'online' || status === 'to approve')
            newStatus = 'offline';
        else
        {
            console.log("cannot change right now. Status:" + status);
            return;
        }

        axios.patch(process.env.VUE_APP_SERVER_BASE_URL + 'listings/' + id + "/status",
            {status: newStatus},
            {headers: {'Content-Type': 'application/merge-patch+json'}})
        .then(response => {
            this.listings[index].status = response.data.status;
        }).catch(error => {
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
              this.$store.commit('logout');
              this.$router.push('/login');
          }
          else if(error.response && error.response.data && error.response.data.message)
              Message.error(this.$i18n.t(error.response.data.message));
          else if (error.response && error.response.data && error.response.data['hydra:description'])
              Message.error(this.$i18n.t(error.response.data['hydra:description']));
          else {
              Message.error(this.$i18n.t('err_try_again'));
              console.log(error);
          }
        }).finally(() => {this.saving=false;});
      }
    },
    beforeMount() {
      this.loading = true;
      axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'listings/own',
          {headers: {'X-LOCALE': this.$store.getters.getLang}})
      .then(response => {
        this.listings = response.data['hydra:member'];
        this.loading = false;
      }).catch(error => {
        this.loading = false;
        if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
          if (error.response.data.message) {
            console.log(error.response.data.message);
            this.$store.commit('logout');
            this.$router.push('/login');
          } else {
            // this.form_error = 'Error. Try again';
          }
        } else if (error.response && error.response.data) {
          // this.form_error = error.response.data.error;
        } else {
          // this.form_error = 'Unknown error';
          console.log(error);
        }
      });
    }
  }
</script>
<style>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .15s
  }

  .fade-enter,
  .fade-leave-to
    /* .fade-leave-active in <2.1.8 */

  {
    opacity: 0
  }
</style>
