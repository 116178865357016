import i18n from "./i18n";

export default [
  {
    name: 'Dashboard',
    icon: 'nc-icon nc-bank',
    path: '/dashboard'
  },
  {
    name:  i18n.t('listing.hire'),
    icon: 'nc-icon nc-bank',
    children: [
      {
        name: 'Catalogue',
        path: '/hireProfessional/catalogue'
      },
      {
        name: 'Add New',
        path: '/hireProfessional/new'
      },
      {
        name: 'My Offers',
        path: '/hireProfessional/myOffers'
      }
    ]
  }
]
