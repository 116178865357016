<template>
  <div class="wrapper">
<!--    <side-bar type="sidebar" :sidebar-links="$sidebar.sidebarLinks" :title="company_name">-->
<!--      <user-menu></user-menu>-->
<!--    </side-bar>-->
    <top-navbar></top-navbar>
    <div class="main-panel no-sidebar">
<!--      <top-navbar></top-navbar>-->

      <div class="content" >
        <transition name="fade" mode="out-in">
          <!-- your content here -->

          <div class="row">
            <div class="col-12 col-md-8">
              <wizard @complete="wizardComplete">
                <template slot="header">
                  <h3 class="card-title"></h3>
                  <h3 class="description"></h3>
                </template>

                <wizard-tab :before-change="() => validateStep('order_details', 'payment_method')">
                  <template slot="label">
                    <i class="nc-icon nc-alert-circle-i"></i>
                    Order Details
                  </template>
                  <order-details ref="order_details"
                                 v-bind:package="package"
                                 v-bind:checkoutID="checkout !== null ? checkout.id : null"
                                 v-bind:totalServices="summary.totalServices"
                                 v-bind:totalAuditors="summary.auditors.total"
                                 v-bind:listingID="listingID"
                                 @on-validated="onStepValidated"
                                 v-on:checkout-service="handleServices($event)"
                                 v-on:checkout="checkoutData($event)"
                  ></order-details>
                </wizard-tab>

                <wizard-tab :before-change="() => validateStep('payment_method', 'confirm_order')">
                  <template slot="label">
                    <i class="nc-icon nc-alert-circle-i"></i>
                    Payment method
                  </template>
                  <payment-method ref="payment_method"
                                  v-bind:listingIRI="package.listing"
                                  v-bind:checkoutID="checkout !== null ? checkout.id : null"
                                  @on-validated="onStepValidated"
                                  v-on:payment_method="handlePayment($event)"
                  ></payment-method>
                </wizard-tab>

                <wizard-tab :before-change="() => validateStep('confirm_order', '')">
                  <template slot="label">
                    <i class="nc-icon nc-alert-circle-i"></i>
                    Confirm Order
                  </template>
                  <confirm-order ref="confirm_order"
                                 v-bind:checkoutID="checkout !== null ? checkout.id : null"
                                 @on-validated="onStepValidated"
                  ></confirm-order>
                </wizard-tab>

              </wizard>

            </div>
            <div class="col-12 col-md-4">

              <div class="px-3 mt-3" style="border: 1px solid #b9b9b9; border-radius: 20px">
                <h4>Summary</h4>
                <div class="row">
                  <div class="col-6">
                    <p>Package price:</p>
                  </div>
                  <div class="col-6 text-right">
                    <b>
                      <span v-if="summary.packageCurrencyPrice !== '€'">({{ summary.packagePrice }} {{summary.packageCurrencyPrice}}) </span>
                      € {{ summary.packagePriceInEur }}</b>
                  </div>
                </div>
<!--                <div class="row">-->
<!--                  <div class="col-6">-->
<!--                    <p>Other service from the professional:</p>-->
<!--                  </div>-->
<!--                  <div class="col-6 text-right">-->
<!--                    <b>€ 0</b>-->
<!--                  </div>-->
<!--                </div>-->

                <hr/>
                <div class="row mb-3">
                  <div class="col-6">
                    <p>Subtotal:</p>
                  </div>
                  <div class="col-6 text-right">
                    <b>€ {{ totalProInEur }}</b>
                  </div>
                </div>


                <div class="row">
                  <div class="col-8">
                    <p>Auditors ({{summary.auditors.number}}):</p>
                  </div>
                  <div class="col-4 text-right">
                    <b>€
                      <span v-if="summary.auditors.total !== summary.auditors.totalToPay" class="text-danger"
                        style="text-decoration: line-through;">{{ summary.auditors.totalInEur }}</span>
                      {{ summary.auditors.totalToPay }}
                    </b>
                  </div>
                </div>


                <div class="row" v-for="service in summary.platformServices" v-if="service.active">
                  <div class="col-8">
                    <p>{{ service.label }}:</p>
                  </div>
                  <div class="col-4 text-right">
                    <b><span v-if="summary.paymentMethod.platformCurrency">({{ service.price }} SETS) </span>€ {{ service.price }}</b>
                  </div>
                </div>

                <div class="row">
                  <div class="col-8">
                    <p>Fees:</p>
                  </div>
                  <div class="col-4 text-right">
                    <b><span v-if="summary.paymentMethod.platformCurrency">({{ summary.fee}} SETS)</span> € {{ summary.fee }}</b>
                  </div>
                </div>

                <hr/>
                <div class="row">
                  <div class="col-8">
                    <p>Total:</p>
                  </div>
                  <div class="col-4 text-right">
                    <b>€ {{ totalInEur }}</b>
                  </div>

                  <div class="col-12"><h6 class="text-center description">You won't be charged yet</h6></div>
                </div>


                <div class="row" v-if="summary.paymentMethod.proCurrency">

                  <div class="col-8">
                    <p>Payment for the professional:</p>
                  </div>
                  <div class="col-4 text-right">
                    <b>{{ summary.paymentMethod.proCurrency }}</b>
                  </div>

                  <div class="col-8">
                    <p>Payment for the platform services:</p>
                  </div>
                  <div class="col-4 text-right">
                    <b v-if="summary.paymentMethod.platformCurrency">SETS</b>
                    <b v-else>{{ summary.paymentMethod.proCurrency }}</b>
                  </div>

                  <div class="col-12 text-center p-3">
                    <p v-if="summary.paymentMethod.platformCurrency">
                      <b>{{ totalPlatformInEur }} SETS and </b>
                      <b>{{ totalInPaymentMethodChosen }} {{ summary.paymentMethod.proCurrency }}</b>
                    </p>
                    <p v-else>
                      <b>{{ totalInPaymentMethodChosenAndPlatform }} {{ summary.paymentMethod.proCurrency }}</b>
                    </p>
                  </div>


                  <div class="col-12 text-center text-danger" v-if="summary.paymentMethod.platformCurrency && userCreditSETS < summary.fee">
                    <p>Please note that your credit in SETS is not enough! Remember to recharge your credit before proceeding</p>
                  </div>

                </div>


              </div>
            </div>
          </div>

          <router-view></router-view>
        </transition>
      </div>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">

</style>
<script>
  import TopNavbar from '../../TopNavbar.vue'
  import ContentFooter from '../../ContentFooter.vue'
  // import UserMenu from 'src/components/UIComponents/SidebarPlugin/UserMenu.vue'
  import {Wizard, WizardTab} from 'src/components/UIComponents';
  import OrderDetails from "./OrderDetails";
  import PaymentMethod from "./PaymentMethod";
  import ConfirmOrder from "./ConfirmOrder";
  import axios from 'axios';
  import Swal from 'sweetalert2';

  export default {
    components: {
      TopNavbar,
      ContentFooter,
      // UserMenu,
      Wizard,
      WizardTab,
      OrderDetails,
      PaymentMethod,
      ConfirmOrder
    },
    methods: {
      // toggleSidebar () {
      //   if (this.$sidebar.showSidebar) {
      //     this.$sidebar.displaySidebar(false)
      //   }
      // },
      checkoutData (data) {
        this.checkout = data;
      },
      handleServices (service) {
        if(service.name === 'auditors')
        {
          this.summary.auditors.number = service.value;
          this.summary.auditors.total = this.summary.packagePrice * this.summary.auditors.percentage / 100 * service.value;
          this.summary.auditors.totalToPay = this.summary.auditors.total - this.summary.auditors.total * this.package.auditorsDuePercentage / 100;
        }
        else {
          this.summary.platformServices[service.name].active = service.value;
        }
      },
      handlePayment (payment) {
        this.summary.paymentMethod[payment.name] = payment.value;
      },
      validateStep(ref, refSucc) {
        let res = this.$refs[ref].validate();
        if(res && refSucc !== '')
          this.$refs[refSucc].initializeStep();
        return res;
      },
      onStepValidated(validated, model) {

      },
      wizardComplete() {
        Swal.fire({
          title: 'Order completed',
          // text: this.$i18n.t('listing.listing_ready'),
          text: 'The order has been created. Please go into your cart to proceed to the payment',
          confirmButtonClass: "btn btn-success",
          type: "success",
          buttonsStyling: false
        })
        .then((result) => {
          this.$store.commit('setListing', null);
          this.$router.push('/cart');
        });
      }
    },
    computed: {
      totalProInEur: function () {
        let t = this.summary.packagePriceInEur;
        for(let index in this.summary.proServices)
        {
          if(this.summary.proServices[index].active)
            t += this.summary.proServices[index].price;
        }
        return t;
      },
      totalPlatformInEur: function () {
        let t = this.summary.fee;
        for(let index in this.summary.platformServices)
        {
          if(this.summary.platformServices[index].active)
            t += this.summary.platformServices[index].price;
        }
        // t += this.summary.auditors.totalToPay;
        this.summary.totalServices = t;
        return t;
      },
      totalInEur: function () {
        return this.totalProInEur + this.totalPlatformInEur + this.summary.auditors.totalToPay;
      },
      totalInPaymentMethodChosen: function () {
          let v = this.totalProInEur + this.summary.auditors.totalToPay;
          let price = this.$store.getters.transformCurrency(v, '€', this.summary.paymentMethod.proCurrency);
          if (this.summary.paymentMethod.proCurrency === 'paypal' || this.summary.paymentMethod.proCurrency === 'bank')
              price = price.toFixed(2);
          else
              price = price.toFixed(4);
          return price;
      },
      totalInPaymentMethodChosenAndPlatform: function () {
          let v = this.totalProInEur + this.summary.auditors.totalToPay + this.totalPlatformInEur;
          let price = this.$store.getters.transformCurrency(v, '€', this.summary.paymentMethod.proCurrency);
          return price.toFixed(4)
      },
    },
    mounted: function () {
    },
    data() {
      return {
        loading: true,
        error: null,
        package: {},
        summary: {
          packageCurrencyPrice: '€',
          packagePrice: 0,
          packagePriceInEur: 0,
          auditors: {number: 0, percentage: 2, total: 0, totalToPay: 0, priceForSC: 5, scActive: false},
          proServices: {
          },
          platformServices: {
            auditors_sc: {label: 'Auditors Smart Contract', price: 5, active: false},
            expertsReview: {label: 'Service 1', price: 1, active: false},
            other: {label: 'Other Service', price: 5, active: false}},
          // services: [
          //     {label: 'Auditors Smart Contract', price: 5, active: false},
          //     {label: 'Service 1', price: 1, active: false},
          //     {label: 'Other Service', price: 5, active: false}
          // ],
          totalServices: 0,
          fee: 1,
          paymentMethod: { proCurrency: null, platformCurrency: null}
        },
        checkout: null,
        listingID: null,
        userCreditSETS: this.$store.getters.getCreditSETS
      }
    },
    beforeMount() {
      let fetchedPackageId = this.$route.params.id;
      if(!fetchedPackageId)
        this.$router.push("/customer/dashboard");

      axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'listing_packages/' + fetchedPackageId)
      .then(response => {
        this.package = response.data;
        this.summary.packagePrice = this.package.price;
        this.summary.packagePriceInEur = this.package.price;
        this.summary.packageCurrencyPrice = this.package.currency.symbol;
        if (this.summary.packageCurrencyPrice !== '€')
            this.summary.packagePriceInEur = this.$store.getters.transformCurrency(this.package.price, this.package.currency.symbol, '€');

        this.listingID = this.package.listing.split("/")[3];
        this.$refs['order_details'].initializeStep(this.listingID);
      }).catch(error => {
        this.loading = false;
        if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
          this.$store.commit('logout');
          this.$router.push('/login');
        }
        else if (error.response && error.response.status === 404)
        {
          console.log("package not found");
          this.$router.push('/customer/dashboard');
        }
        else {
          this.error = 'err_data_not_received';
          console.log(error);
        }
      });


      let fetchedCheckoutId = this.$route.params.checkout_id;
      if(fetchedCheckoutId)
      {
        axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'checkouts/' + fetchedCheckoutId)
        .then(response => {
          this.checkout = response.data;

          // summary: { packagePrice: 0,
          //     auditors: {number: 0, percentage: 2, total: 0, totalToPay: 0},
          //   proServices: {
          //   },
          //   platformServices: {
          //     auditors_sc: {label: 'Auditors Smart Contract', price: 5, active: false},
          //     expertsReview: {label: 'Service 1', price: 1, active: false},
          //     other: {label: 'Other Service', price: 5, active: false}},
          //   totalServices: 0,
          //       fee: 0,
          //       paymentMethod: { proCurrency: null, platformCurrency: null}
          // },


        }).catch(error => {
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.$store.commit('logout');
            this.$router.push('/login');
          }
          else if (error.response && error.response.status === 404)
          {
            console.log("checkout not found");
          }
          else {
            this.error = 'err_data_not_received';
            console.log(error);
          }
        });
      }


    }
  }

</script>
