<template>
  <navbar navbar-menu-classes="navbar-right" v-model="showMenu">
    <div class="navbar-wrapper">
      <a class="navbar-brand" href="#">
        <img src="/static/img/logo/logo_small.png" alt="Logo" height="35px" width="auto">
        Sensitrust Marketplace <span class="text-danger">DEMO</span></a>
    </div>
    <template slot="navbar-menu">

      <router-link to="/register" class="nav-item" tag="li">
        <a class="nav-link">
          <i class="nc-icon nc-book-bookmark"></i> {{ $t('registration.register') }}
        </a>
      </router-link>
      <router-link to="/login" class="nav-item" tag="li">
        <a class="nav-link">
          <i class="nc-icon nc-tap-01"></i> {{ $t('login.login') }}
        </a>
      </router-link>

      <router-link to="/contact_us/" class="nav-item" tag="li">
        <a class="nav-link">
          <i class="nc-icon nc-chat-33"></i> {{ $t('contact_us') }}
        </a>
      </router-link>


      <drop-down icon="nc-icon nc-globe" tag="li" position="right" direction="none" class="nav-item dropdown">
        <a slot="title" slot-scope="{isOpen}" class="nav-link dropdown-toggle" data-toggle="dropdown"
           aria-haspopup="true" :aria-expanded="isOpen">
          <i class="nc-icon nc-globe"></i>
          <p> <span class="d-block" v-model="$i18n.locale" style="padding-right: 4px">{{ $i18n.locale.toUpperCase() }}</span> </p>
        </a>
        <a class="dropdown-item" v-for="lang in langs" @click="changeLocale(lang)"
           :set="langImg = '/static/img/flags/' + lang.toUpperCase() + '.png'">
          <img v-bind:src="langImg" style="padding-right: 4px"> {{ lang.toUpperCase() }}
        </a>
      </drop-down>

<!--      <drop-down icon="nc-icon nc-globe" tag="li" position="right" direction="none" class="nav-item btn-rotate dropdown">-->
<!--        <a slot="title" slot-scope="{isOpen}" class="nav-link dropdown-toggle" data-toggle="dropdown"-->
<!--           aria-haspopup="true" :aria-expanded="isOpen">-->
<!--          <p> <span class="d-block" v-model="currency" style="padding-right: 3px">{{ currency.toUpperCase() }}</span> </p>-->
<!--        </a>-->
<!--        <a class="dropdown-item" v-for="curr in currencies" @click="changeCurrency(curr)"-->
<!--           :set="langImg = '/static/img/currencies/' + curr.toUpperCase() + '.png'">-->
<!--          <img v-bind:src="langImg" style="padding-right: 4px" height="20px" width="auto"> {{ curr.toUpperCase() }}-->
<!--        </a>-->
<!--      </drop-down>-->

    </template>
  </navbar>
</template>

<script>
  import {Navbar} from 'src/components/UIComponents'
  export default {
    name: 'main-navbar',
    components: {
      Navbar
    },
    data() {
      return {
        showMenu: false,
        langs: ['en'],
        currencies: ['€','$','£','ETH','BTC','BNB','LTC'],
        currency: this.$store.getters.getCurrency,
      }
    },
    methods: {
      changeLocale(lang) {
        this.$i18n.locale = lang;
        this.$store.commit('setLang', lang);
      },
      changeCurrency(currency) {
        this.currency = currency;
        this.$store.commit('setCurrency', currency);
      }
    }
  }
</script>

<style scoped>

</style>
