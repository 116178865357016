<template>
  <form @submit.prevent="validate">

    <div class="row justify-content-center">

      <div class="col-12 mb-4">
        <h5 class="text-center">Upload some images that could help people understand what do you do</h5>
      </div>
      <div class="col-sm-4 mt-auto mb-auto">
        <div class="picture-container">

          <el-upload
            class="upload-demo" action="" accept="image/*"
            :on-change="handlePreview"
            :on-remove="handleRemove"
            :file-list="images"
            :auto-upload="false"
            ref="file"
            list-type="picture-card">
            <el-button size="small" type="primary">Click to upload</el-button>
            <div slot="tip" class="el-upload__tip">jpg/png files with a size less than 500kb</div>


            <div slot="file" slot-scope="{file}">
              <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" :style="coverImageId === file.id ? 'filter: drop-shadow(2px 4px 6px black);' : ''">
              <span class="el-upload-list__item-actions">
                <span class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview(file)">
                  <i class="el-icon-zoom-in"></i>
                </span>

                <span
                  v-if="!disabled"
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file)">
                  <i class="el-icon-delete"></i>
                </span>

                <span class="el-upload-list__item-delete"
                      @click="handleCoverImage(file)">
                  <el-tooltip content="Use this image for previews">
                    <i class="el-icon-medal-1"></i>
                  </el-tooltip>
                </span>
              </span>
            </div>


          </el-upload>

          <div v-if="error" class="alert text-danger text-center col-12"><strong>{{ $t(error) }}</strong></div>

          <div v-if="loading" class="loading col-12"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.loading') }} ...</div>

          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
          </el-dialog>

        </div>
      </div>
    </div>


    <div class="row mt-5">
      <div class="col-12">
        <div v-if="saving" class="text-danger text-center"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.saving') }}..</div>
      </div>
    </div>
  </form>
</template>
<script>
  import { Upload, Button, Dialog, Radio, Tooltip } from 'element-ui';
  import axios from 'axios';



  export default {
    components: {
      [Upload.name]: Upload,
      [Button.name]: Button,
      [Dialog.name]: Dialog,
      [Radio.name]: Radio,
      [Tooltip.name]: Tooltip,
    },
    data() {
      return {
        loading: true,
        saving: false,
        error: null,
        images: [],
        dialogImageUrl: '',
        dialogVisible: false,
        disabled: false,
        coverImageId: null,
        coverChanged: false
      }
    },
    methods: {
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      handleCoverImage(file) {
        this.coverImageId = file.id;
        this.coverChanged = true;
      },
      handleRemove(file) {
        this.error = null;
        this.saving = true;
        axios.delete(process.env.VUE_APP_SERVER_BASE_URL + 'listing_images/' + file.id)
        .then(response => {
          this.saving = false;
          for(let index=0; index < this.images.length; index++)
          {
            if(this.images[index].id === file.id)
              this.images.splice(index, 1);
          }
        }).catch(error => {
          this.saving = false;
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.$store.commit('logout');
            this.$router.push('/login');
          } else {
            this.error = 'err_try_again';
            console.log(error);
          }
        });
      },
      handlePreview(file) {
        this.error = null;
        this.saving = true;
        let data = new FormData();
        let image = new Blob([file.raw], {type: file.type});
        data.append('file', image, file.name);
        data.append('listing', this.listingID);
        axios.post(process.env.VUE_APP_SERVER_BASE_URL + 'listing_images', data)
        .then(response => {
          let id = response.data['@id'].split("/");
          id = id[id.length -1];
          this.images.push({
            image: image,
            name: file.name,
            url: URL.createObjectURL(file.raw),
            id: id
          });
          console.log("image saved");
          this.saving = false;
        }).catch(error => {
          this.saving = false;
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.$store.commit('logout');
            this.$router.push('/login');
          } else {
            this.error = 'err_try_again';
            console.log(error);
          }
        });
      },
      validate() {
        if(this.images.length === 0)
        {
          this.error = 'at least one image';
          return false;
        }

        if (this.coverImageId === null) {
          this.coverImageId = this.images[0].id;
          this.coverChanged = true;
        }

        if(this.coverChanged) {
          this.saving = true;

          return axios.patch(process.env.VUE_APP_SERVER_BASE_URL + 'listings/' + this.listingID,
              {"coverImage": "/api/listing_images/" + this.coverImageId},
              {headers: {'Content-Type': 'application/merge-patch+json'}})
          .then(response => {
            console.log("cover saved");
            this.$emit('on-validated', true);
            this.saving = false;
            return true;
          }).catch(error => {
            this.saving = false;
            if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
              this.$store.commit('logout');
              this.$router.push('/login');
            } else {
              this.error = 'err_try_again';
              console.log(error);
            }
            return false;
          });
        }
        else
        {
          this.$emit('on-validated', true);
          return true;
        }
      },
      initializeStep() {
          this.coverImageId = this.coverIRI;
      },
      downloadDocument(docIri) {
        let id = docIri.split("/");
        id = id[id.length-1];
        axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'download-document/' + id)
          .then(response => {
              const blob = new Blob([response.data], { type: 'image/*' });
              // const link = document.createElement('a');
              // link.href = URL.createObjectURL(blob);
              // link.download = "nome_file";
              // link.click();
              // URL.revokeObjectURL(link.href);
          }).catch(error => {this.showError(error);}).finally(() => {this.saving = false;});
      },
    },
    beforeMount() {
      this.loading = true;
      if (this.listingID !== null)
      {
        axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'listing_images?listing.id=' + this.listingID)
            .then(response => {
              let imgs = response.data['hydra:member'];
              for (let index = 0; index < imgs.length; index++ ) {
                let url = imgs[index].contentUrl;
                let name = url.split("/");
                name = name[name.length - 1];
                let id = imgs[index]['@id'].split("/");
                id = id[id.length - 1];

                this.images.push({
                  url: url,
                  name: name,
                  id: id
                });
              }

              this.loading = false;
            }).catch(error => {
          this.loading = false;
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.$store.commit('logout');
            this.$router.push('/login');
          } else {
            this.error = 'Error while retrieving images. Please try again.';
            console.log(error);
          }
        });
      }
      else
        this.loading = false;
    },
    props: {
      listingID: String,
      coverIRI: String
    }
  }
</script>
<style lang="scss" scoped>
  .card-wizard .picture-container .picture {
    input[type="file"] {
      display: none;
    }
  }
</style>
