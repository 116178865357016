<template>
  <div class="login-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="col-lg-4 col-md-6 ml-auto mr-auto">

              <card type="login">
                <ValidationObserver v-slot="{ handleSubmit }">
                  <form @submit.prevent="handleSubmit(login)">
                    <h3 slot="header" class="header text-center">{{ $t('login.login') }}</h3>
                    <div v-if="form.error" class="alert text-danger"><strong>{{ $t(form.error) }}</strong></div>
                    <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed }">
                      <fg-input type="email" v-model="form.email" addon-left-icon="nc-icon nc-single-02"
                              :error="failed ? $t('common.field_required') : null" :hasSuccess="passed"
                              name="email" placeholder="E-mail" autocomplete="email"></fg-input>
                    </ValidationProvider>

                    <ValidationProvider name="password" rules="required|min:1" v-slot="{ passed, failed }">
                      <fg-input type="password" v-model="form.password" addon-left-icon="nc-icon nc-key-25"
                                placeholder="Password"
                                :error="failed ? $t('common.field_required') : null" :hasSuccess="passed"
                                autocomplete="current-password" name="password"></fg-input>
                    </ValidationProvider>

<!--                    <ValidationProvider name="twoFA" rules="numeric" v-slot="{ passed, failed }">-->
<!--                    <fg-input type="text" v-model="form.twoFA" addon-left-icon="nc-icon nc-lock-circle-open"-->
<!--                              :error="failed ? $t('common.numeric') : null" :hasSuccess="passed"-->
<!--                              placeholder="2FA (if enabled)" name="twoFA"></fg-input>-->
<!--                    </ValidationProvider>-->

                    <p-button native-type="submit" slot="footer" type="warning" round block class="mb-3"
                              :disabled="isDisabled">{{ $t('login.login') }}</p-button>
                  </form>
                </ValidationObserver>


                <br>
<!--                <vue-hcaptcha :sitekey="captchaKey" @verify="captchaVerified"></vue-hcaptcha>-->

                <p-button type="link" class="btn-link btn-sm m-auto" v-on:click="resetPassword" style="font-size: 12px;">Reset Password</p-button>

              </card>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
        <div class="full-page-background" style="background-image: url(/static/img/background/background-2.jpg)"></div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Card } from 'src/components/UIComponents';
  import AppNavbar from './AppNavbar'
  import AppFooter from './AppFooter'
  import { extend } from "vee-validate";
  import { required, email, min, numeric } from "vee-validate/dist/rules";
  import axios from 'axios';
  import Swal from 'sweetalert2';
  import VueHcaptcha from '@hcaptcha/vue-hcaptcha';

  extend("email", email);
  extend("required", required);
  extend("min", min);
  extend("numeric", numeric);

  export default {
    components: {
      Card,
      AppNavbar,
      AppFooter,
      VueHcaptcha
    },
    methods: {
      toggleNavbar() {
        document.body.classList.toggle('nav-open');
      },
      closeMenu() {
        document.body.classList.remove('nav-open');
        document.body.classList.remove('off-canvas-sidebar');
      },
      captchaVerified  (token, eKey) {
          this.captchaToken = token;
      },
      login() {
        this.error = '';
        this.form.buttonDisabled = true;

        axios.post(process.env.VUE_APP_SERVER_BASE_URL + 'login_check', {
          email: this.form.email,
          password: this.form.password,
          // twoFA: this.form.twoFA
        })
          .then(response => {
            if(response.data.login === 'success' && response.data['2fa_complete'] === false)
                this.$router.push({path: '/login/2fa', query: { redirect: this.$route.query.redirect || '/customer/dashboard' }});
            else {
                this.$store.commit('auth_success', response.data.user);
                this.$store.commit('setCurrencies', response.data.currencies);
                this.$router.push(this.$route.query.redirect || '/customer/dashboard');
            }
          }).catch(error => {
          console.log(error);
          if(error.response && error.response.data && error.response.data.code === 401 &&
              error.response.data.message === 'Invalid credentials.')
            this.form.error = "login.invalid_credential";
          else if(error.response && error.response.data && error.response.data.code === 401 &&
              error.response.data.message === 'An authentication exception occurred.')
            this.form.error = "login.inactive_account";
          else
            this.form.error = "err_try_again";
        }).finally(() => {this.form.buttonDisabled = false;});
      },
      resetPassword() {
          Swal.fire({
              title: 'Reset Password',
              input: 'email',
              inputValue: this.form.email,
              inputPlaceholder: "E-mail",
              showCancelButton: true,
              confirmButtonText: 'Reset',
              showLoaderOnConfirm: true,
              preConfirm: (email) => {
                  return axios.post(process.env.VUE_APP_SERVER_BASE_URL + 'reset_password_request/', {email: email})
                      .then(response => {
                          return undefined;
                      }).catch(error => {
                          Swal.showValidationMessage(this.$i18n.t(error.response.data.message));
                      });
              },
              allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
              if (result.isConfirmed) {
                  Swal.fire({
                      icon: 'success',
                      title: 'Reset Password request sent'
                  });
              }
          });
      }
    },
    computed: {
      isDisabled: function(){
        return this.form.buttonDisabled;
      }
    },
    data() {
      return {
        form: {
          email: '',
          password: '',
          error: null,
          buttonDisabled: false,
          twoFA: ""
        },
        captchaKey: process.env.VUE_APP_HCAPTCHA_SITE_KEY,
        captchaToken: null
      }
    },
    beforeDestroy() {
      this.closeMenu()
    }
  }
</script>
<style>
  .loginBackground {
    /*background: url('./public/static/img/background.jpg');*/
  }
</style>

