<template>
  <div class="content">

    <div class="row">
      <div class="col-12">

        <div class="row" v-if="!canWrite">
          <div class="col-12">
            <h6 class="text-center text-danger m-3"><b>You disabled your subscription for auditors! You will not receive other audits to review.</b></h6>
          </div>
        </div>

        <div class="card">
          <div class="card-header">

            <h3>Audits to complete</h3>

            <div class="row">
              <div v-if="loading" class="loading col-12"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.loading') }}</div>
            </div>

            <div class="table-responsive">
              <el-table class="table-shopping"
                        style="width: 100%"
                        :data="inProgressTable">

                <el-table-column min-width="50">
                  <template slot-scope="props">
                    <p-button type="primary" size="sm" icon
                              v-on:click="$router.push({path: '/auditor/manage/' + props.row.id})">
                      <i class="nc-icon nc-glasses-2"></i>
                    </p-button>
                  </template>
                </el-table-column>

                <el-table-column min-width="100" label="Date" prop="date">
                </el-table-column>

                <el-table-column min-width="100" label="Status" prop="status">
                  <template slot-scope="props">
                    <span v-if="props.row.status === 'new'" class="text-warning">
                      Waiting for acceptance
                    </span>
                    <span v-else class="text-success">
                     Work In Progress
                    </span>
                  </template>
                </el-table-column>

                <!--                -->
                <!--                <el-table-column min-width="80" label="Price" header-align="right">-->
                <!--                  <template slot-scope="{row}">-->
                <!--                    <div class="text-right" style="font-size: 22px">-->
                <!--                      <small>&euro;</small> {{row.price}}-->
                <!--                    </div>-->
                <!--                  </template>-->
                <!--                </el-table-column>-->

              </el-table>
            </div>

          </div>
        </div>


      </div>
    </div>



    <div class="row">
      <div class="col-12">

        <div class="card">
          <div class="card-header">

            <h3>Past audits</h3>

            <div class="table-responsive">
              <el-table class="table-shopping"
                        style="width: 100%"
                        :data="completedTable">

                <el-table-column min-width="50">
                  <template slot-scope="props">
                    <p-button type="primary" size="sm" icon
                              v-on:click="$router.push({path: '/auditor/manage/' + props.row.id})">
                      <i class="nc-icon nc-glasses-2"></i>
                    </p-button>
                  </template>
                </el-table-column>

                <el-table-column min-width="100" label="Date" prop="date">
                </el-table-column>

                <!--                -->
                <!--                <el-table-column min-width="80" label="Price" header-align="right">-->
                <!--                  <template slot-scope="{row}">-->
                <!--                    <div class="text-right" style="font-size: 22px">-->
                <!--                      <small>&euro;</small> {{row.price}}-->
                <!--                    </div>-->
                <!--                  </template>-->
                <!--                </el-table-column>-->

              </el-table>
            </div>

          </div>
        </div>


      </div>
    </div>

    <router-view></router-view>
  </div>
</template>

<script>
  import axios from 'axios';
  import {Table, TableColumn} from 'element-ui'
  import PButton from "src/components/UIComponents/Button";
  import {Role} from "src/role";


  export default {
    name: "MyAudits",
    components: {
      PButton,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
    },
    data() {
      return {
        loading: true,
        error: null,
        saving: false,
        inProgressTable: [],
        completedTable: [],
        canWrite: this.$store.getters.hasRole(Role.AuditorWrite)
      }
    },
    beforeMount() {
      this.loading = true;

      axios.get(process.env.VUE_APP_SERVER_BASE_URL + "audits?status=completed",
          {headers: {'X-LOCALE': this.$store.getters.getLang}})
          .then(response => {
            for(let i = 0; i< response.data['hydra:member'].length; i++) {
              let c = response.data['hydra:member'][i];
              let item = {
                id: c.id,
                date: (new Date(c.createdAt)).toLocaleString(),
                status: c.status
              };

              this.completedTable.push(item);

            }
            this.loading = false;
          }).catch(error => {
        this.loading = false;
        if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
          this.$store.commit('logout');
          this.$router.push('/login');
        }
        else if (error.response && error.response.status === 404)
        {
          console.log("listing not found");
          this.$router.push('/auditor/dashboard');
        }
        else {
          this.error = 'err_data_not_received';
          console.log(error);
        }
      });



      axios.get(process.env.VUE_APP_SERVER_BASE_URL + "audits?status[]=new&status[]=accepted",
          {headers: {'X-LOCALE': this.$store.getters.getLang}})
          .then(response => {
            for(let i = 0; i< response.data['hydra:member'].length; i++) {
              let c = response.data['hydra:member'][i];
              let item = {
                id: c.id,
                date: (new Date(c.createdAt)).toLocaleString(),
                status: c.status
              };

              this.inProgressTable.push(item);
            }
            this.loading = false;
          }).catch(error => {
        this.loading = false;
        if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
          this.$store.commit('logout');
          this.$router.push('/login');
        }
        else if (error.response && error.response.status === 404)
        {
          console.log("listing not found");
          this.$router.push('/auditor/dashboard');
        }
        else {
          this.error = 'err_data_not_received';
          console.log(error);
        }
      });
    }
  }
</script>

<style scoped>

</style>
