<template>
  <div class="row">
    <div class="col-12 my-5">

      <div class="row">
        <div class="col-12">
<!--        <h3 class="text-center">Subscriptions</h3>-->

        <div v-if="loading" class="loading col-12"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.loading') }}</div>
        </div>
      </div>

      <div class="row m-2">
        <div class="col-12 text-center">
            <p>This is a demo platform. You will be able to explore all the functionalities available on the platform.</p>
            <p>Please note that in this stage, you will not be paid for your services.</p>
        </div>

        <div class="col-12 col-md-6">
          <card type="pricing" style="height: 100%">
            <h6 slot="header" class="card-category mb-0"> Be a professional!
              <badge type="success" v-if="isProWrite">Active</badge>
              <badge type="danger" v-else>Not active</badge>
            </h6>

            <div class="card-icon icon-primary">
              <i class="nc-icon nc-briefcase-24"></i>
            </div>

            <h3 class="card-title">Free</h3>

            <ul style="max-width: none;">
              <li> <i class="fa fa-check text-success"></i> You will have access to Basic functionalities to post your job activities</li>
            </ul>
            <br>

            <p class="m-0">Your username and your card profile will be visible publicly.</p>
            <p class="m-0">In the future, to become a professional, we may ask you to complete a KYC procedure.</p>

            <br>

            <p-checkbox v-if="!isProWrite" class="text-center has-error" v-model="acceptProTerms">
              <p class="m-0 p-0"><b class="text-danger">*</b> {{ $t('registration.accept_check') }}
                <router-link to="/terms_and_conditions/" tag="a" target="_blank">{{$t('common.terms_conditions')}} {{ $t('common.for_pro') }}</router-link>.
              </p>
            </p-checkbox>
            <div v-else>
              <p class="m-0 p-0">
                <router-link to="/terms_and_conditions/" tag="a" target="_blank">{{$t('common.terms_conditions')}} {{ $t('common.for_pro') }}</router-link>
              </p>
            </div>

            <p-button v-if="!isProWrite" type="primary" round slot="footer" v-on:click="newProfessionalSub('/api/subscriptions_professionals/1')"
                      :disabled="saving || !acceptProTerms">
              {{ $t('subscriptions.activate') }}
            </p-button>

            <p-button v-else type="warning" round slot="footer" v-on:click="cancelProSub()" :disabled="saving">
              {{ $t('subscriptions.deactivate') }}
            </p-button>

          </card>

        </div>


        <div class="col-12 col-md-6">
          <card type="pricing" style="height: 100%">
            <h6 slot="header" class="card-category mb-0"> Be an auditor!
              <badge type="success" v-if="isAuditorWrite">Active</badge>
              <badge type="danger" v-else>Not active</badge>
            </h6>

            <div class="card-icon icon-primary">
              <i class="nc-icon nc-zoom-split"></i>
            </div>

            <h3 class="card-title">Free</h3>

            <ul style="max-width: none;">
              <li> <i class="fa fa-check text-success"></i> Apply to be an auditor for other activities! </li>
              <li> We will give you the opportunity to be a reviewer for an activity made by another professional, evaluate it and be rewarded! </li>
            </ul>
            <br>

            <p class="m-0">In the future, to become an auditor, we will profile you in order to assign you activities that match your expertise only.</p>

            <br>

            <p-checkbox v-if="!isAuditorWrite" class="text-center has-error" v-model="acceptAuditorTerms">
              <p class="m-0 p-0"><b class="text-danger">*</b> {{ $t('registration.accept_check') }}
                <router-link to="/terms_and_conditions/" tag="a" target="_blank">{{$t('common.terms_conditions')}} {{ $t('common.for_auditors') }}</router-link>.
              </p>
            </p-checkbox>
            <div v-else>
              <p class="mb-0 p-0">
                <router-link to="/terms_and_conditions/" tag="a" target="_blank">{{$t('common.terms_conditions')}} {{ $t('common.for_auditors') }}</router-link>
              </p>
            </div>

            <p-button v-if="!isAuditorWrite" type="primary" round slot="footer" v-on:click="newAuditorSub('/api/subscriptions_auditors/1')"
                      :disabled="saving || !acceptAuditorTerms">
              {{ $t('subscriptions.activate') }}
            </p-button>

            <p-button v-else type="warning" round slot="footer" v-on:click="cancelAuditorSub()" :disabled="saving">
              {{ $t('subscriptions.deactivate') }}
            </p-button>

          </card>

        </div>
      </div>


      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 d-block my-3" style="width: 100%" v-for="plan in plans">
          <subscription :subscription="plan" :userPlan="userPlan"></subscription>
        </div>
      </div>



      <div class="row">
        <div class="col-12 m-auto">
          <!--            <div class="col-12 text-center">-->
          <!--              <p-button v-if="userPlan != null" type="primary" round :disabled="loading" v-on:click="handleSubscription()">-->
          <!--                {{ $t('subscriptions.manage_subscription') }}-->
          <!--              </p-button>-->
          <!--            </div>-->

          <div v-if="saving" class="loading"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.saving') }}</div>
        </div>
      </div>

    </div>
  </div>
</template>

<style lang="scss">
</style>

<script>
  import axios from 'axios';
  import Subscription from "./Subscription";
  import { Message } from "element-ui";
  import Swal from 'sweetalert2';
  import {Role } from "src/role";
  import { Badge } from 'src/components/UIComponents';

  export default {
    name: 'Subscriptions',
    components: {
        Subscription,
        [Badge.name]: Badge
    },
    methods: {
        newProfessionalSub(id) {
            this.saving = true;
            axios.post(process.env.VUE_APP_SERVER_BASE_URL + 'subscriptions_professional_histories/new', {subscription: id})
            .then(response => {
              this.$store.commit('addRoles', [Role.ProfessionalWrite]);
              this.isProWrite = true;
              this.professionalPlan = response.data.id;

              Swal.fire({
                  title: 'Congrats!',
                  text: 'Subscription successfully updated!',
                  confirmButtonClass: "btn btn-success",
                  type: "success",
                  buttonsStyling: false
              });
            }).catch(error => {
                if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                  this.$store.commit('logout');
                  this.$router.push('/login');
                }
                else if(error.response && error.response.data && error.response.data.message)
                    Message.error(this.$i18n.t('subscription.' + error.response.data.message));
                else if (error.response && error.response.data && error.response.data['hydra:description'])
                    Message.error(this.$i18n.t('subscription.' + error.response.data['hydra:description']));
                else {
                    Message.error(this.$i18n.t('err_try_again'));
                    console.log(error);
                }
            }).finally(() => {this.saving = false;});
        },
        newAuditorSub(id) {
            this.saving = true;
            axios.post(process.env.VUE_APP_SERVER_BASE_URL + 'subscriptions_auditor_histories/new', {subscription: id})
            .then(response => {
              this.$store.commit('addRoles', [Role.AuditorWrite]);
              this.isAuditorWrite = true;
              this.auditorPlan = response.data.id;

              Swal.fire({
                  title: 'Congrats!',
                  text: 'Subscription successfully updated!',
                  confirmButtonClass: "btn btn-success",
                  type: "success",
                  buttonsStyling: false
              });
            }).catch(error => {
              if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                  this.$store.commit('logout');
                  this.$router.push('/login');
              }
              else if(error.response && error.response.data && error.response.data.message)
                  Message.error(this.$i18n.t('subscription.' + error.response.data.message));
              else if (error.response && error.response.data && error.response.data['hydra:description'])
                  Message.error(this.$i18n.t('subscription.' + error.response.data['hydra:description']));
              else {
                  Message.error(this.$i18n.t('err_try_again'));
                  console.log(error);
              }
            }).finally(() => {this.saving = false;});
        },
        cancelProSub() {
            this.saving = true;
            axios.patch(process.env.VUE_APP_SERVER_BASE_URL + 'subscriptions_professional_histories/' + this.professionalPlan + '/cancel',
                {},
                {headers: {'Content-Type': 'application/merge-patch+json'}})
            .then(response => {
              this.isProWrite = false;
              this.$store.commit('removeRoles', [Role.ProfessionalWrite]);

              Swal.fire({
                  title: 'Congrats!',
                  text: 'Subscription successfully updated!',
                  confirmButtonClass: "btn btn-success",
                  type: "success",
                  buttonsStyling: false
              });
            }).catch(error => {
                if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                    this.$store.commit('logout');
                    this.$router.push('/login');
                }
                else if (error.response && error.response.data && error.response.data.message)
                    Message.error(this.$i18n.t(error.response.data.message));
                else if (error.response && error.response.data && error.response.data['hydra:description'])
                    Message.error(this.$i18n.t('subscriptions.' + error.response.data['hydra:description']));
                else {
                    Message.error(this.$i18n.t('err_try_again'));
                    console.log(error);
                }
            }).finally(() => {this.saving = false;});
        },
        cancelAuditorSub() {
            this.saving = true;
            axios.patch(process.env.VUE_APP_SERVER_BASE_URL + 'subscriptions_auditor_histories/' + this.auditorPlan + '/cancel',
                {},
                {headers: {'Content-Type': 'application/merge-patch+json'}})
                .then(response => {
                    this.isAuditorWrite = false;
                    this.$store.commit('removeRoles', [Role.AuditorWrite]);

                    Swal.fire({
                        title: 'Congrats!',
                        text: 'Subscription successfully updated!',
                        confirmButtonClass: "btn btn-success",
                        type: "success",
                        buttonsStyling: false
                    });
                }).catch(error => {
                if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                    this.$store.commit('logout');
                    this.$router.push('/login');
                }
                else if (error.response && error.response.data && error.response.data.message)
                    Message.error(this.$i18n.t(error.response.data.message));
                else if (error.response && error.response.data && error.response.data['hydra:description'])
                    Message.error(this.$i18n.t('subscriptions.' + error.response.data['hydra:description']));
                else {
                    Message.error(this.$i18n.t('err_try_again'));
                    console.log(error);
                }
            }).finally(() => {this.saving = false;});
        },
    },
    mounted: function () {
    },
    data() {
      return {
        userCreditSETS: this.$store.getters.getCreditSETS,
        loading: false,
        saving: false,
        error: null,
        plans: [],
        userPlan: null,
        professionalPlan: null,
        auditorPlan: null,
        isProWrite: this.$store.getters.hasRole(Role.ProfessionalWrite),
        isAuditorWrite: this.$store.getters.hasRole(Role.AuditorWrite),
        acceptProTerms: false,
        acceptAuditorTerms: false,
      }
    },
    beforeMount() {
        this.loading = true;
        // axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'subscriptions')
        // .then(response => {
        //   this.plans = response.data['hydra:member'];
        // }).catch(error => {
        //   if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
        //       if (error.response.data.message) {
        //           this.$store.commit('logout');
        //           this.$router.push('/login');
        //       }
        //   } else {
        //       Message.error(this.$i18n.t('err_loading_page'));
        //       console.log(error);
        //   }
        // }).finally(() => {this.loading = false;});


        axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'users/' + this.$store.getters.getUserId + '/active_subscription')
        .then(response => {
          if(response.data.lastSubscriptionAuditor)
            this.auditorPlan = response.data.lastSubscriptionAuditor.id;
            if(response.data.lastSubscriptionProfessional)
                this.professionalPlan = response.data.lastSubscriptionProfessional.id;
        }).catch(error => {
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
              if (error.response.data.message) {
                  this.$store.commit('logout');
                  this.$router.push('/login');
              }
          } else {
              Message.error(this.$i18n.t('subscriptions.error_loading_page'));
              console.log(error);
          }
        }).finally(() => {this.loading = false;});
    },
  }

</script>
