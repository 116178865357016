import { render, staticRenderFns } from "./PaymentMethod.vue?vue&type=template&id=504949e3&scoped=true&"
import script from "./PaymentMethod.vue?vue&type=script&lang=js&"
export * from "./PaymentMethod.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "504949e3",
  null
  
)

export default component.exports