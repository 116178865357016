<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div v-if="loading" class="loading"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.loading') }}</div>
      </div>

      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <h5>Add an optional note for the professional:</h5>
            <textarea class="form-control" rows="4" name="message"
                      v-model="message"
                      placeholder=" write a message..">
            </textarea>
          </div>

          <div class="col-12 mt-5">


          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import { Rate, Checkbox, Radio } from 'element-ui';
  // import { Checkbox, Radio } from 'src/components/UIComponents';

  export default {
    components: {
      [Rate.name]: Rate,
      [Checkbox.name]: Checkbox,
      [Radio.name]: Radio
    },
    data() {
      return {
        images_base_url: process.env.VUE_APP_IMAGES_BASE_URL,
        loading: true,
        error: null,
        message: null
      }
    },
    methods: {
      validate() {
        if (this.message !== '' && this.message !== null)
        {
          this.saving =  true;
          return axios.patch(process.env.VUE_APP_SERVER_BASE_URL + 'checkouts/' + this.checkoutID,
              {note: this.message},
              {headers: {'Content-Type': 'application/merge-patch+json'}})
              .then(response => {
                // TODO: check if status = changed to trigger an alert
                this.saving = false;
                this.$emit('on-validated', true);
                return true;
              }).catch(error => {
                this.saving = false;
                if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                  if (error.response.data.message) {
                    console.log(error.response.data.message);
                    this.$store.commit('logout');
                    this.$router.push('/login');
                  } else
                    this.form_error = 'Error. Try again';
                }

                // TODO: check if the code is 410 - package or listing deleted and redirect to listing if exist, to catalogue otherwise

                else if (error.response && error.response.data) {
                  this.form_error = error.response.data.error;
                } else {
                  this.form_error = 'Unknown error';
                  console.log(error);
                }
                return false;
              });
        }
        else
        return true;
      },
      initializeStep() {
      }
    },
    beforeMount() {
      this.loading = false;
    },
    props: {
      checkoutID: String
    }
  }
</script>

<style scoped>

</style>
