import Web3 from 'web3';
import  { reputationScABI } from "./reputationABI";

const web3 = new Web3(process.env.VUE_APP_BSC_NETWORK);


var getReputation = function (professionalUUid) {
  return new Promise((resolve, reject) => {
    var contract = new web3.eth.Contract(reputationScABI, process.env.VUE_APP_SC_REPUTATION);
    contract.methods.getReputation(professionalUUid).call({}, function (error, result) {
      if (error)
        return reject(error);
      else
        return resolve(result);
    });
  });
};


export {getReputation};
