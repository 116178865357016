var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h3',[_vm._v("Orders in progress")]),_c('div',{staticClass:"row"},[(_vm.loading)?_c('div',{staticClass:"loading col-12"},[_c('i',{staticClass:"fa fa-spin fa-spinner"}),_vm._v(" "+_vm._s(_vm.$t('common.loading')))]):_vm._e()]),_c('div',{staticClass:"table-responsive"},[_c('el-table',{staticClass:"table-shopping",staticStyle:{"width":"100%"},attrs:{"data":_vm.inProgressTable}},[_c('el-table-column',{attrs:{"min-width":"50"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('p-button',{attrs:{"type":"primary","size":"sm","icon":""},on:{"click":function($event){return _vm.$router.push({path: '/customer/order/' + props.row.id})}}},[_c('i',{staticClass:"nc-icon nc-glasses-2"})])]}}])}),_c('el-table-column',{attrs:{"min-width":"100","label":"Date","prop":"date"}}),_c('el-table-column',{attrs:{"min-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"img-container"},[_c('img',{attrs:{"src":props.row.image,"alt":""}})])]}}])}),_c('el-table-column',{attrs:{"min-width":"220","class-name":"td-name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('a',{staticStyle:{"font-size":"18px"}},[_vm._v(_vm._s(row.title))]),_c('br'),_c('small',[_vm._v(_vm._s(row.subTitle))])]}}])}),_c('el-table-column',{attrs:{"min-width":"60","label":"Revision n.","prop":"activityNumber"}}),_c('el-table-column',{attrs:{"min-width":"80","label":"Status","prop":"lastActivityStatus"}}),_c('el-table-column',{attrs:{"min-width":"80","label":"Price","header-align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-right",staticStyle:{"font-size":"22px"}},[_c('small',[_vm._v("€")]),_vm._v(" "+_vm._s(row.priceInEur)+" ")])]}}])})],1)],1)])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h3',[_vm._v("Past orders")]),_c('div',{staticClass:"table-responsive"},[_c('el-table',{staticClass:"table-shopping",staticStyle:{"width":"100%"},attrs:{"data":_vm.completedTable}},[_c('el-table-column',{attrs:{"min-width":"50"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('p-button',{attrs:{"type":"primary","size":"sm","icon":""},on:{"click":function($event){return _vm.$router.push({path: '/customer/order/' + props.row.id})}}},[_c('i',{staticClass:"nc-icon nc-glasses-2"})])]}}])}),_c('el-table-column',{attrs:{"min-width":"100","label":"Date","prop":"date"}}),_c('el-table-column',{attrs:{"min-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"img-container"},[_c('img',{attrs:{"src":props.row.image,"alt":""}})])]}}])}),_c('el-table-column',{attrs:{"min-width":"220","class-name":"td-name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('a',{staticStyle:{"font-size":"18px"}},[_vm._v(_vm._s(row.title))]),_c('br'),_c('small',[_vm._v(_vm._s(row.subTitle))])]}}])}),_c('el-table-column',{attrs:{"min-width":"80","label":"Price","header-align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-right",staticStyle:{"font-size":"22px"}},[_c('small',[_vm._v("€")]),_vm._v(" "+_vm._s(row.priceInEur)+" ")])]}}])})],1)],1)])])])]),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }