<template>
  <div class="content">

    <div class="row">
      <div class="col-12">

        <div class="card">
          <div class="card-header">

            <h3>Listings to approve</h3>

            <div class="row">
              <div v-if="loading" class="loading col-12"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.loading') }}</div>
            </div>

            <div class="table-responsive">
              <el-table class="table-shopping"
                        style="width: 100%"
                        :data="activitiesTable">

                <el-table-column min-width="100" label="Last Change At" prop="lastChangeOn"></el-table-column>
                <el-table-column min-width="100" label="Category" prop="category"></el-table-column>

                <el-table-column min-width="100">
                  <template slot-scope="props">
                    <div class="img-container">
                      <img :src="props.row.image" alt="">
                    </div>
                  </template>
                </el-table-column>

                <el-table-column min-width="220" class-name="td-name">
                  <template slot-scope="props">
                    <a style="font-size: 18px">{{props.row.title}}</a>
                    <br>
                    <small>{{props.row.subTitle}}</small>
                  </template>
                </el-table-column>

                <el-table-column min-width="50">
                  <template slot-scope="props">
                    <p-button type="primary" size="sm" icon
                              v-on:click="$router.push({path: '/listing/details/' + props.row.id })"
                              :disabled="saving">
                      <i class="nc-icon nc-glasses-2"></i>
                    </p-button>

                    <p-button type="success" size="sm" icon v-on:click="changeStatus(props.row.id, 'online', props.$index)"
                              :disabled="saving">
                      <i class="nc-icon nc-check-2"></i>
                    </p-button>

                    <p-button type="danger" size="sm" icon v-on:click="changeStatus(props.row.id, 'not approved', props.$index)"
                              :disabled="saving">
                      <i class="nc-icon nc-simple-remove"></i>
                    </p-button>
                  </template>
                </el-table-column>

              </el-table>
            </div>

            <div class="row">
              <div v-if="saving" class="loading col-12"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.saving') }}</div>
            </div>

          </div>
        </div>


      </div>
    </div>

    <router-view></router-view>
  </div>
</template>

<script>
  import axios from 'axios';
  import {Table, TableColumn, Message} from 'element-ui';
  import PButton from "src/components/UIComponents/Button";
  import PSwitch from "../../../UIComponents/Switch";


  export default {
    name: "ToApprove",
    components: {
        PSwitch,
      PButton,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
    },
    data() {
      return {
        loading: true,
        error: null,
        saving: false,
        activitiesTable: [],
        images_base_url: process.env.VUE_APP_IMAGES_BASE_URL,
      }
    },
    methods: {
      changeStatus(id, status, index) {
          this.saving = true;

        axios.patch(process.env.VUE_APP_SERVER_BASE_URL + 'listings/' + id + "/status",
              {status: status},
              {headers: {'Content-Type': 'application/merge-patch+json'}})
        .then(response => {
          this.activitiesTable.splice(index, 1);
        }).catch(error => {
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
              this.$store.commit('logout');
              this.$router.push('/login');
          }
          else if(error.response && error.response.data && error.response.data.message)
              Message.error(this.$i18n.t(error.response.data.message));
          else if (error.response && error.response.data && error.response.data['hydra:description'])
              Message.error(this.$i18n.t(error.response.data['hydra:description']));
          else {
              Message.error(this.$i18n.t('err_try_again'));
              console.log(error);
          }
        }).finally(() => {this.saving=false;});
      }
    },
    beforeMount() {
      this.loading = true;

      axios.get(process.env.VUE_APP_SERVER_BASE_URL + "listings/to_approve",
          {headers: {'X-LOCALE': this.$store.getters.getLang}})
      .then(response => {
        for(let i = 0; i< response.data['hydra:member'].length; i++) {
          let c = response.data['hydra:member'][i];
          let lastChangeOn = new Date(c.lastChangeOn);
          let item = {
            id: c.id,
            lastChangeOn: lastChangeOn.toLocaleString('en-GB'),
            category: c.category.name,
            image: c.coverImage.contentUrl,
            title: c.title,
            subTitle: c.shortDescription
          };
          this.activitiesTable.push(item);
        }
      }).catch(error => {
        if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
          this.$store.commit('logout');
          this.$router.push('/login');
        }
        else if (error.response && error.response.status === 404)
        {
          console.log("activities not found");
          this.$router.push('/professional/dashboard');
        }
        else {
          this.error = 'err_data_not_received';
          console.log(error);
        }
      }).finally(() => {this.loading = false;});
    }
  }
</script>

<style scoped>

</style>
