<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <div class="row justify-content-center">
        <div class="col-12">
          <h4 class="text-center">Select the payment method accepted for your service</h4>
          <div v-if="error" class="alert text-danger text-center col-12"><strong>{{ (error) }}</strong></div>
          <div class="row">
            <div class="col-4">

                <icon-checkbox v-model="crypto.active"
                               icon="fa fa-bitcoin"
                               title="Crypto currencies">
                </icon-checkbox>

            </div>

            <div class="col-8" v-if="crypto.active">
              <div class="row" v-for="c in crypto.list">
                <div class="col-2 mt-2">
                    <el-checkbox v-model="c.active" class="d-inline-block"> {{ c.name }} </el-checkbox>
                </div>
                <div class="col-10">
<!--                  <ValidationProvider rules="" :vid="c.name" v-slot="x">-->
<!--                    <fg-input type="hidden" v-model="c.active ? 'y': 'n'"></fg-input>-->
<!--                  </ValidationProvider>-->

                  <ValidationProvider :name="c.name" :rules="{required_if: {target: c.name, values: ['y']}}" v-slot="{ passed, failed }">
                    <fg-input :name="c.name" v-model="c.address" :disabled="!c.active" :placeholder="c.name + ' address'"
                              :error="failed ? $t('common.field_required') : null"
                              :hasSuccess="passed"></fg-input>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>

          <hr class="mb-5"/>

          <div class="row">
            <div class="col-4">
              <icon-checkbox v-model="paypal.active"
                             icon="fa fa-paypal"
                             title="PayPal">
              </icon-checkbox>
            </div>

            <div class="col-8" v-if="paypal.active">
              <ValidationProvider name="paypal_email" rules="email" v-slot="{ passed, failed }">
                <fg-input name="paypal_email" type="email" v-model="paypal.account"
                          placeholder="Insert the e-mail of your PayPal account"
                          :error="failed ? $t('common.invalid_email') : null"
                          :hasSuccess="passed"></fg-input>
              </ValidationProvider>
            </div>
          </div>

          <hr class="mb-5"/>

<!--          <div class="row">-->
<!--            <div class="col-4">-->
<!--              <icon-checkbox v-model="card.active"-->
<!--                             icon="nc-icon nc-credit-card"-->
<!--                             title="Credit Card">-->
<!--              </icon-checkbox>-->
<!--            </div>-->

<!--            <div class="col-8" v-if="card.active">-->
<!--              <fg-input v-model="card.account" placeholder="Insert qualcos.."></fg-input>-->
<!--            </div>-->
<!--          </div>-->
<!--          <hr class="mb-5"/>-->

          <div class="row">
            <div class="col-4">
              <icon-checkbox v-model="bank.active"
                             icon="nc-icon nc-bank"
                             title="Bank Transfer"
                              :disabled="true">
              </icon-checkbox>
            </div>

            <div class="col-8" v-if="bank.active">
              <fg-input v-model="bank.accountHolder" placeholder="Account Holder"></fg-input>
              <span v-if="bank.active && !bank.accountHolder" class="d-block text-danger mt-n2">Required</span>
              <fg-input v-model="bank.iban" placeholder="Insert your IBAN" class="mt-2"></fg-input>
              <span v-if="bank.active && !bank.iban" class="d-block text-danger mt-n2">Required</span>
              <fg-input v-model="bank.bic" placeholder="Insert your BIC/SWIFT" class="mt-2"></fg-input>
              <span v-if="bank.active && !bank.bic" class="d-block text-danger mt-n2">Required</span>
              <fg-input v-model="bank.note" placeholder="Insert some note for the client" class="mt-2"></fg-input>
            </div>
          </div>


          <div class="row">
            <div v-if="saving" class="col-12 text-danger text-center"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.saving') }}...</div>
          </div>

        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
  import {IconCheckbox} from 'src/components/UIComponents';
  import axios from 'axios';
  import {Select, Option, Checkbox, Message } from 'element-ui';
  import { extend } from "vee-validate";
  import { required, required_if, email } from "vee-validate/dist/rules";

  extend("required", required);
  extend("required_if", required_if);
  extend("email", email);

  export default {
    components: {
      IconCheckbox,
      [Select.name]: Select,
      [Option.name]: Option,
      [Checkbox.name]: Checkbox
    },
    data() {
      return {
        loading: true,
        error: null,
        saving: false,
        crypto: { active: false, list: [
            // {name: 'ETH', image: '', active: false, address: ''},
            // {name: 'BTC', image: '', active: false, address: ''},
            // {name: 'BNB', image: '', active: false, address: ''},
            // {name: 'LTC', image: '', active: false, address: ''}
            ]},
        paypal: {active: false, account: ''},
        card: {active: false, account: ''},
        bank: {active: false, iban: '', accountHolder: '', bic: '', note: ''}
      }
    },
    methods: {
      // handleChange(value) {
      //   console.log("changed: " + JSON.stringify(value));
      //   this.formChanged = true;
      //   if(value !== undefined) {
      //     this.categoryID = value[value.length - 1];
      //   }
      //   else
      //     this.categoryID = null;
      //   // value is an array
      // },
      validate() {
        return this.$refs.form.validate().then( res => {
          this.error = null;
          if (!res)
            return false;
          if (!this.crypto.active && !this.paypal.active && !this.card.active && !this.bank.active)
          {
            this.error = 'choose at least one payment method';
            return false;
          }
          else if (this.bank.active && (!this.bank.accountHolder || !this.bank.iban || !this.bank.bic))
          {
              this.error = 'missing bank transfer data';
              return false;
          }
          else
          {
            this.saving = true;

            let data = {
              PayPalEmail: this.paypal.active ? this.paypal.account : null,
              bankAccountHolder: this.bank.active ? this.bank.accountHolder : null,
              bankIBAN: this.bank.active ? this.bank.iban : null,
              bankBIC: this.bank.active ? this.bank.bic : null,
              bankNote: this.bank.active ? this.bank.note : null
            };

            for (let i=0; i<this.crypto.list.length; i++)
            {
              let c = this.crypto.list[i];
              data[c.name + 'Address'] = c.active ? c.address : null;
            }

            let config = {
                method: 'patch',
                url: process.env.VUE_APP_SERVER_BASE_URL + 'listings/' + this.listingID,
                headers: {'Content-Type': 'application/merge-patch+json'},
                data: data
              };

            return axios(config).then(response => {
              this.saving = false;
              return true;
            }).catch(error => {
              this.saving = false;
              if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                this.$store.commit('logout');
                this.$router.push('/login');
              } else {
                console.log(error);
              }
              Message.error("An error occurred while saving data. Please try again.");
              return false;
            });
          }
        });
      },

      initializeStep() {
        this.loading = true;
        let cryptoActive = false;
        this.crypto.list = [];
        if(this.listingData.ETHAddress)
        {
          cryptoActive = true;
          this.crypto.list.push({ name: 'ETH', image: '', active: true, address: this.listingData.ETHAddress });
        }
        else
          this.crypto.list.push({ name: 'ETH', image: '', active: false, address: '' });

        // if(this.listingData.BTCAddress)
        // {
        //   cryptoActive = true;
        //   this.crypto.list.push({ name: 'BTC', image: '', active: true, address: this.listingData.BTCAddress });
        // }
        // else
        //   this.crypto.list.push({ name: 'BTC', image: '', active: false, address: '' });

        // if(this.listingData.LTCAddress)
        // {
        //   cryptoActive = true;
        //   this.crypto.list.push({ name: 'LTC', image: '', active: true, address: this.listingData.LTCAddress });
        // }
        // else
        //   this.crypto.list.push({ name: 'LTC', image: '', active: false, address: '' });

        if(this.listingData.BNBAddress)
        {
          cryptoActive = true;
          this.crypto.list.push({ name: 'BNB', image: '', active: true, address: this.listingData.BNBAddress });
        }
        else
          this.crypto.list.push({ name: 'BNB', image: '', active: false, address: '' });

        this.crypto.active = cryptoActive;

        if(this.listingData.PayPalEmail)
        {
          this.paypal.active = true;
          this.paypal.account = this.listingData.PayPalEmail;
        }

        if(this.listingData.bankIBAN)
        {
          this.bank.active = true;
          this.bank.iban = this.listingData.bankIBAN;
          this.bank.accountHolder = this.listingData.bankAccountHolder;
          this.bank.bic = this.listingData.bankBIC;
          this.bank.note = this.listingData.bankNote;
        }

        this.loading = false;
      }
    },
    beforeMount() {
      // axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'categories/plainJson',
      //     {headers: {'X-LOCALE': this.$store.getters.getLang}})
      // .then(response => {
      //   this.categories = response.data;
      // }).catch(error => {
      //   if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
      //       this.$store.commit('logout');
      //       this.$router.push('/login');
      //   } else {
      //     console.log(error);
      //   }
      // });

    },
    props: {
      listingData: Object,
      listingID: String
    }
  }
</script>
<style>
</style>
