<template>
  <navbar v-model="showNavbar">
    <div class="navbar-wrapper">
      <router-link to="/customer/dashboard" class="navbar-brand">
        <img src="/static/img/logo/logo_small.png" alt="Logo" height="35px" width="auto">
        Sensitrust Marketplace <span class="text-danger">DEMO</span>
      </router-link>
    </div>

    <template slot="navbar-menu">
      <ul class="navbar-nav">

        <drop-down tag="li" position="right" direction="none" class="nav-item dropdown" v-if="isProfessionalRead">
          <a slot="title" slot-scope="{isOpen}" class="nav-link dropdown-toggle" data-toggle="dropdown"
             aria-haspopup="true" :aria-expanded="isOpen">
            <i class="nc-icon nc-briefcase-24 align-top"></i> <p>Professional</p>
          </a>

          <router-link to="/professional/dashboard" class="dropdown-item">
            <i class="nc-icon nc-bank"></i> Dashboard
          </router-link>


          <router-link to="/professional/new" class="dropdown-item" v-if="isProfessionalWrite">
            <i class="nc-icon nc-simple-add"></i> Add New
          </router-link>

          <span class="dropdown-item" v-else style="color: lightgray">
            <i class="nc-icon nc-simple-add"></i> Add New
          </span>


          <router-link to="/professional/my_listings" class="dropdown-item" v-if="isProfessionalWrite">
            <i class="nc-icon nc-single-02"></i> My Listings
          </router-link>

          <span class="dropdown-item" v-else style="color: lightgray">
            <i class="nc-icon nc-single-02"></i> My Listings
          </span>


          <router-link to="/professional/activities" class="dropdown-item">
            <i class="nc-icon nc-briefcase-24"></i> My Activities
          </router-link>
        </drop-down>


        <drop-down tag="li" position="right" direction="none" class="nav-item dropdown">
          <a slot="title" slot-scope="{isOpen}" class="nav-link dropdown-toggle" data-toggle="dropdown"
             aria-haspopup="true" :aria-expanded="isOpen">
            <i class="nc-icon nc-shop align-top"></i> <p>Customer</p>
          </a>

          <router-link to="/customer/dashboard" class="dropdown-item">
            <i class="nc-icon nc-bank"></i> Dashboard
          </router-link>

          <router-link to="/customer/catalogue" class="dropdown-item">
            <i class="nc-icon nc-compass-05"></i> Catalogue
          </router-link>

          <router-link to="/customer/orders" class="dropdown-item">
            <i class="nc-icon nc-book-bookmark"></i> My Orders
          </router-link>
        </drop-down>


        <drop-down tag="li" position="right" direction="none" class="nav-item dropdown" v-if="isAuditorRead">
          <a slot="title" slot-scope="{isOpen}" class="nav-link dropdown-toggle" data-toggle="dropdown"
             aria-haspopup="true" :aria-expanded="isOpen">
            <i class="nc-icon nc-zoom-split align-top"></i> <p>Auditor</p>
          </a>

          <router-link to="/auditor/dashboard" class="dropdown-item">
            <i class="nc-icon nc-bank"></i> Dashboard
          </router-link>

          <router-link to="/auditor/list" class="dropdown-item">
            <i class="nc-icon nc-paper"></i> My Audits
          </router-link>
        </drop-down>

<!--        <drop-down tag="li" position="right" direction="none" class="nav-item dropdown">-->
<!--          <a slot="title" slot-scope="{isOpen}" class="nav-link dropdown-toggle" data-toggle="dropdown"-->
<!--             aria-haspopup="true" :aria-expanded="isOpen">-->
<!--            <i class="nc-icon nc-bank align-top"></i> <p>Hire a Professional</p>-->
<!--          </a>-->

<!--          <a class="megamenu-content">-->
<!--            <div class="header">-->
<!--              <h3>Mega Menu</h3>-->
<!--            </div>-->
<!--            <div class="megamenu-row">-->
<!--              <div class="megamenu-column">-->
<!--                <h3>Category 1</h3>-->
<!--                <a href="#">Link 1</a>-->
<!--                <a href="#">Link 2</a>-->
<!--                <a href="#">Link 3</a>-->
<!--              </div>-->
<!--              <div class="megamenu-column">-->
<!--                <h3>Category 2</h3>-->
<!--                <a href="#">Link 1</a>-->
<!--                <a href="#">Link 2</a>-->
<!--                <a href="#">Link 3</a>-->
<!--              </div>-->
<!--              <div class="megamenu-column">-->
<!--                <h3>Category 3</h3>-->
<!--                <a href="#">Link 1</a>-->
<!--                <a href="#">Link 2</a>-->
<!--                <a href="#">Link 3</a>-->
<!--              </div>-->
<!--            </div>-->
<!--          </a>-->
<!--        </drop-down>-->

        <drop-down tag="li" position="right" direction="none" class="nav-item dropdown" v-if="isAgent">
          <a slot="title" slot-scope="{isOpen}" class="nav-link dropdown-toggle" data-toggle="dropdown"
             aria-haspopup="true" :aria-expanded="isOpen">
            <i class="nc-icon nc-zoom-split align-top"></i>
          </a>

          <router-link to="/agent/to_approve" class="dropdown-item">
            <i class="nc-icon nc-paper"></i> To Approve
          </router-link>
        </drop-down>


        <drop-down icon="nc-icon nc-globe" tag="li" position="right" direction="none" class="nav-item btn-rotate dropdown">
          <a slot="title" slot-scope="{isOpen}" class="nav-link dropdown-toggle" data-toggle="dropdown"
             aria-haspopup="true" :aria-expanded="isOpen">
            <i class="nc-icon nc-globe"></i>
            <p> <span class="d-block" v-model="$i18n.locale" style="padding-right: 3px">{{ $i18n.locale.toUpperCase() }}</span> </p>
          </a>
          <a class="dropdown-item" v-for="lang in langs" @click="changeLocale(lang)"
             :set="langImg = '/static/img/flags/' + lang.toUpperCase() + '.png'">
            <img v-bind:src="langImg" style="padding-right: 4px"> {{ lang.toUpperCase() }}
          </a>
        </drop-down>


        <drop-down icon="nc-icon nc-globe" tag="li" position="right" direction="none" class="nav-item btn-rotate dropdown">
          <a slot="title" slot-scope="{isOpen}" class="nav-link dropdown-toggle" data-toggle="dropdown"
             aria-haspopup="true" :aria-expanded="isOpen">
            <p> <span class="d-block" v-model="currency" style="padding-right: 3px">{{ currency.toUpperCase() }}</span> </p>
          </a>
          <a class="dropdown-item" v-for="curr in currencies" @click="changeCurrency(curr)"
             :set="langImg = '/static/img/currencies/' + curr.toUpperCase() + '.png'">
            <img v-bind:src="langImg" style="padding-right: 4px" height="20px" width="auto"> {{ curr.toUpperCase() }}
          </a>
        </drop-down>


        <li class="nav-item">
          <router-link to="/cart" class="nav-link btn-magnify">
            <i class="nc-icon nc-cart-simple"></i>
            <p><span class="d-lg-none d-md-block">Cart</span></p>
          </router-link>
        </li>


        <li class="nav-item">
          <router-link to="/user/sets_credit" class="nav-link">
            <p class="pt-1" style="font-size: 12px">Credits: {{ creditSETS }}</p>
          </router-link>
        </li>


        <drop-down tag="li" position="right" direction="none" class="nav-item dropdown">
          <a slot="title" slot-scope="{isOpen}" class="nav-link dropdown-toggle" data-toggle="dropdown"
             aria-haspopup="true" :aria-expanded="isOpen">
            <img :src="userPhoto" :alt="username" height="30px" width="auto" style="border-radius: 20%" class="pr-1"/>
          </a>

          <router-link to="/user/profile" class="dropdown-item">
            <i class="nc-icon nc-single-02"></i> Profile
          </router-link>

          <router-link to="/user/security" class="dropdown-item">
            <i class="nc-icon nc-lock-circle-open"></i> Security
          </router-link>

          <router-link to="/user/subscriptions" class="dropdown-item">
            <i class="fa fa-star"></i> Subscriptions
          </router-link>

          <a class="dropdown-item" @click="logout">
            <i class="nc-icon nc-button-power"></i> {{ $t('login.logout') }}
          </a>
        </drop-down>

      </ul>
    </template>


  </navbar>
</template>
<script>
  import { Navbar, NavbarToggleButton } from 'src/components/UIComponents'
  import axios from "axios";
  import { EventBus } from 'src/event-bus.js';
  import {Role} from "src/role";

  export default {
    components: {
      Navbar,
      NavbarToggleButton
    },
    data() {
      return {
        activeNotifications: false,
        showNavbar: false,
        langs: ['en'],
        currencies: ['€','$','£','ETH','BTC','BNB','LTC'],
        currency: this.$store.getters.getCurrency,
        userPhoto: this.$store.getters.getUserPhoto,
        username: this.$store.getters.getUsername,
        creditSETS: this.$store.getters.getCreditSETS,
        isProfessionalRead: this.$store.getters.hasRole(Role.ProfessionalRead),
        isProfessionalWrite: this.$store.getters.hasRole(Role.ProfessionalWrite),
        isAuditorRead: this.$store.getters.hasRole(Role.AuditorRead),
        isAuditorWrite: this.$store.getters.hasRole(Role.AuditorWrite),
        isAgent: this.$store.getters.hasRole(Role.Agent)
      }
    },
    methods: {
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      toggleNotificationDropDown() {
        this.activeNotifications = !this.activeNotifications
      },
      closeDropDown() {
        this.activeNotifications = false
      },
      toggleNavbar() {
        this.showNavbar = !this.showNavbar;
      },
      logout() {
        axios.post(process.env.VUE_APP_SERVER_BASE_URL + 'logout', '')
          .then(response => {
            this.$store.commit('logout');
            this.$router.push('/');
          }).catch(error => {
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.$store.commit('logout');
            this.$router.push('/login');
          }
          else
            console.log("error in logout: " + error);
        });
      },
      changeLocale(lang) {
        this.$i18n.locale = lang;
        this.$store.commit('setLang', lang);
      },
      changeCurrency(currency) {
        this.currency = currency;
        this.$store.commit('setCurrency', currency);
        //TODO: save on db
      }
    },
    created() {
      EventBus.$on('user-photo-profile-updated', photo => {
        this.userPhoto = photo;
      });

      EventBus.$on('credit-sets-updated', credits => {
          this.creditSETS = credits;
      });

      EventBus.$on('user-role-added', (role) => {
          switch (role) {
              case Role.ProfessionalWrite:
                  this.isProfessionalWrite = true;
                  this.isProfessionalRead = true;
                  break;
              case Role.ProfessionalRead:
                  this.isProfessionalRead = true;
                  break;
              case Role.AuditorWrite:
                  this.isAuditorWrite = true;
                  this.isAuditorRead = true;
                  break;
              case Role.AuditorRead:
                  this.isAuditorRead = true;
                  break;
          }
      });


      EventBus.$on('user-role-removed', (role) => {
          switch (role) {
              case Role.ProfessionalWrite:
                  this.isProfessionalWrite = false;
                  break;
              case Role.ProfessionalRead:
                  this.isProfessionalRead = false;
                  this.isProfessionalWrite = false;
                  break;
              case Role.AuditorWrite:
                  this.isAuditorWrite = false;
                  break;
              case Role.AuditorRead:
                  this.isAuditorRead = false;
                  this.isAuditorWrite = false;
                  break;
          }
      });
    }
  }

</script>
<style>
  .dropdown-item.active, .dropdown-item:active {
    background-color: #eaeaea;
  }

  /* Dropdown content (hidden by default) */
  .megamenu-content {

    position: absolute;
    background-color: #f9f9f9;
    width: 80vw;
    right: 0;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 10px;
  }

  /* Mega Menu header, if needed */
  .megamenu-content .header {
    background: #e4e4e4;
    border-radius: 10px 10px 0 0;
    padding: 16px;
  }

  .megamenu-content .header h3 {
    margin: 5px 10px;
  }

  /* Create three equal columns that floats next to each other */
  .megamenu-column {
    float: left;
    width: 33.33%;
    padding: 10px;
    height: 250px;
  }

  /* Style links inside the columns */
  .megamenu-column a {
    float: none;
    color: black;
    padding: 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }

  /* Add a background color on hover */
  .megamenu-column a:hover {
    background-color: #ddd;
  }

  /* Clear floats after the columns */
  .megamenu-row:after {
    content: "";
    display: table;
    clear: both;
  }
</style>
