<template>
  <div class="card card-user">
    <div class="image">
      <img :src="background_photo" alt="">
    </div>
    <div class="card-body">
      <div class="author">
        <img :src="photo_profile" class="picture-src avatar border-gray">
        <h5 class="title">{{ username }}</h5>
        <p class="description">
          {{ country }}
        </p>
      </div>
      <p class="description text-center">
        {{ aboutMe }}
      </p>

      <div class="row justify-content-center">
        <div class="col-3 m-auto text-center" style="padding: 10px" v-if="facebook">
          <a :href="facebook" target="_blank">
            <img src="/static/img/social-icons/facebook-icon.png" alt="FB" width="20px" height="auto">
          </a>
        </div>

        <div class="col-3 m-auto text-center" style="padding: 10px" v-if="telegram">
          <a :href="telegram" target="_blank">
            <img src="/static/img/social-icons/telegram-icon.png" alt="TG" width="20px" height="auto">
          </a>
        </div>

        <div class="col-3 m-auto text-center" style="padding: 10px" v-if="twitter">
          <a :href="twitter" target="_blank">
            <img src="/static/img/social-icons/twitter-icon.png" alt="TW" width="20px" height="auto">
          </a>
        </div>

        <div class="col-3 m-auto text-center" style="padding: 10px" v-if="linkedin">
          <a :href="linkedin" target="_blank">
            <img src="/static/img/social-icons/linkedin-icon.png" alt="IN" width="22px" height="auto">
          </a>
        </div>

      </div>

      <div class="text-danger text-center" v-if="form_error">{{ $t(form_error) }}</div>
    </div>
    <div class="card-footer">
      <hr>
      <div class="button-container">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-6 ml-auto">
            <h5><i class="fa fa-times text-danger"></i>
              <br>
              <small>KYC</small>
            </h5>
          </div>
          <div class="col-lg-4 col-md-6 col-6 ml-auto mr-auto">
            <h5>
              <span class="text-success"><b>{{ avgAsPro }}</b></span>
              <br>
              <small>Score</small>
            </h5>
          </div>
          <div class="col-lg-3 mr-auto">
            <h5>
              <b>{{ reviewsAsPro }}</b>
              <br>
              <small>Reviews</small>
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios';
  import { EventBus } from 'src/event-bus.js';

  export default {
    components: {
    },
    data() {
      return {
        background_photo: '/static/img/background/damir-bosnjak.jpg',
        photo_profile: '/static/img/default-avatar.jpg',
        username: null,
        country: null,
        aboutMe: null,
        facebook: null,
        twitter: null,
        telegram: null,
        linkedin: null,
        avgAsPro: null,
        reviewsAsPro: null,
        loading: false,
        form_error: null
      }
    },
    methods: {
      loadUserData(user_id) {
        this.loading = true;
        axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'users/' + user_id + "/public_info")
            .then(response => {
              if(response.data.avatar)
              {
                  let avatarId = response.data.avatar.split("/");
                  avatarId = avatarId[avatarId.length-1];
                  axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'avatars/' + avatarId)
                  .then(response => {
                      if(response.data.avatarUrl)
                        this.photo_profile = response.data.avatarUrl;
                  }).catch(error => {});
              }
              this.username = response.data.username;
              this.country = response.data.country;
              this.aboutMe = response.data.aboutMe;
              this.facebook = response.data.contactFacebook;
              this.twitter = response.data.contactTwitter;
              this.telegram = response.data.contactTelegram;
              this.linkedin = response.data.contactLinkedin;
              this.avgAsPro = response.data.avgFeedbackAsProfessional;
              this.reviewsAsPro = response.data.numFeedbackAsProfessional;

              this.loading = false;
            }).catch(error => {
          this.loading = false;
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            if (error.response.data.message) {
              console.log(error.response.data.message);
              this.$store.commit('logout');
              this.$router.push('/login');
            } else {
              this.form_error = 'err_user_data_not_received';
            }
          } else if (error.response && error.response.data) {
            this.form_error = 'err_user_data_not_received';
            console.log(error.response.data.error);
          } else {
            this.form_error = 'err_user_data_not_received';
            console.log(error);
          }
        });
      }
    },
    props: ['user_id'],
    beforeMount() {
      this.loadUserData(this.$props['user_id']);
    },
    created() {
      EventBus.$on('user-photo-profile-updated', photo => {
        this.photo_profile = photo;
      });
    }
  }
</script>
<style>

</style>
