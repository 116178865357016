<template>
  <div class="content">

    <div class="row">
      <div class="col-12">

        <div class="card">
          <div class="card-header">

            <h3>My Activities</h3>

            <div class="row">
              <div v-if="loading" class="loading col-12"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.loading') }}</div>
            </div>

            <div class="table-responsive">
              <el-table class="table-shopping"
                        style="width: 100%"
                        :data="activitiesTable">

                <el-table-column min-width="50">
                  <template slot-scope="props">
                    <p-button type="primary" size="sm" icon
                              v-on:click="$router.push({path: '/professional/activity/' + props.row.lastActivityId})">
                      <i class="nc-icon nc-glasses-2"></i>
                    </p-button>
                  </template>
                </el-table-column>

                <el-table-column label="Creation Date" prop="createdAt"></el-table-column>
                <el-table-column label="Status" prop="status"></el-table-column>
                <el-table-column label="Revision Number" prop="revisionNumber"></el-table-column>
                <el-table-column label="Start Date" prop="startedAt"></el-table-column>

              </el-table>
            </div>

          </div>
        </div>


      </div>
    </div>


    <div class="row">
      <div class="col-12">

        <div class="card">
          <div class="card-header">

            <h3>Past Activities</h3>

            <div class="table-responsive">
              <el-table class="table-shopping"
                        style="width: 100%"
                        :data="pastActivities">

                <el-table-column min-width="50">
                  <template slot-scope="props">
                    <p-button type="primary" size="sm" icon
                              v-on:click="$router.push({path: '/professional/activity/' + props.row.lastActivityId})">
                      <i class="nc-icon nc-glasses-2"></i>
                    </p-button>
                  </template>
                </el-table-column>

                <el-table-column label="Creation Date" prop="createdAt"></el-table-column>
                <el-table-column label="Status" prop="status"></el-table-column>
                <el-table-column label="Revision Number" prop="revisionNumber"></el-table-column>
                <el-table-column label="Start Date" prop="startedAt"></el-table-column>

              </el-table>
            </div>

          </div>
        </div>


      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
  import axios from 'axios';
  import {Table, TableColumn} from 'element-ui'
  import PButton from "../../../../UIComponents/Button";


  export default {
    name: "Activities",
    components: {
      PButton,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
    },
    data() {
      return {
        loading: true,
        error: null,
        saving: false,
        activitiesTable: [],
        pastActivities: []
      }
    },
    beforeMount() {
      this.loading = true;

      axios.get(process.env.VUE_APP_SERVER_BASE_URL + "checkouts/professional?status[]=onGoing",
          {headers: {'X-LOCALE': this.$store.getters.getLang}})
      .then(response => {
        for(let i = 0; i< response.data['hydra:member'].length; i++) {
          let c = response.data['hydra:member'][i];
          let creationDate = new Date(c.createdAt);
          let startDate;
          if (c.activities.length > 0 && c.activities[0].startedAt)
            startDate = (new Date(c.activities[0].startedAt)).toLocaleString('en-GB');
          else
            startDate = "-";

          if(c.activities.length > 0) {
              let item = {
                  id: c.id,
                  lastActivityId: c.activities[0].id,
                  createdAt: creationDate.toLocaleString('en-GB'),
                  status: c.activities[0].status,
                  revisionNumber: c.activities.length,
                  startedAt: startDate
              };

              this.activitiesTable.push(item);
          }
        }
      }).catch(error => {
        this.loading = false;
        if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
          this.$store.commit('logout');
          this.$router.push('/login');
        }
        else if (error.response && error.response.status === 404)
        {
          console.log("activities not found");
          this.$router.push('/professional/dashboard');
        }
        else {
          this.error = 'err_data_not_received';
          console.log(error);
        }
      });




      axios.get(process.env.VUE_APP_SERVER_BASE_URL + "checkouts/professional?status[]=completed",
          {headers: {'X-LOCALE': this.$store.getters.getLang}})
      .then(response => {
        for(let i = 0; i< response.data['hydra:member'].length; i++) {
          let c = response.data['hydra:member'][i];
          let creationDate = new Date(c.createdAt);
          let startDate;
          if (c.activities[0].startedAt)
            startDate = (new Date(c.activities[0].startedAt)).toLocaleString('en-GB');
          else
            startDate = "-";
          let item = {
            id: c.id,
            lastActivityId: c.activities[0].id,
            createdAt: creationDate.toLocaleString('en-GB'),
            status: c.activities[0].status,
            revisionNumber: c.activities.length,
            startedAt: startDate
          };

          this.pastActivities.push(item);
        }
        this.loading = false;
      }).catch(error => {
        this.loading = false;
        if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
          this.$store.commit('logout');
          this.$router.push('/login');
        }
        else if (error.response && error.response.status === 404)
        {
          console.log("activities not found");
          this.$router.push('/professional/dashboard');
        }
        else {
          this.error = 'err_data_not_received';
          console.log(error);
        }
      });
    }
  }
</script>

<style scoped>

</style>
