import Vue from 'vue'
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import * as Cookies from "js-cookie";
Vue.use(Vuex);
import { EventBus } from 'src/event-bus.js';
import {Role} from "./role";
import axios from "axios";

const store = new Vuex.Store({
  state: {
    isLoggedIn: false,
    user: {
      id: '',
      username: '',
      email: '',
      iri: '',
      photo_profile: '/static/img/default-avatar.jpg',
      roles: [],
      creditSETS: 0
    },
    listing: {
      id: null,
      category: {
        id: null,
        name: null,
        path: null,
        pathAsString: null,
      },
      price: null,
      status: null,
      firstImageUrl: null,
      prices: [],
      images: []
    },
    currencies: {'€': {name: 'Eur', rate: '1'}},
    webPreferences: {
      lang: 'en',
      currency: '€'
    }
  },
  mutations: {
    auth_request(state){
      state.isLoggedIn = false;
    },
    auth_success(state, user) {
      state.isLoggedIn = true;
      state.user.id = user.id;
      state.user.email = user.email;
      state.user.username = user.username;
      state.user.iri = user.iri;
      state.user.roles = user.roles;
      state.user.creditSETS = user.creditSETS;

      state.user.photo_profile = '/static/img/default-avatar.jpg';
      axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'avatars?userRef.id=' + user.id)
        .then(response => {
         if(response.data['hydra:member'][0] && response.data['hydra:member'][0].avatarUrl)
         {
           state.user.photo_profile = response.data['hydra:member'][0].avatarUrl;
           EventBus.$emit('user-photo-profile-updated', state.user.photo_profile);
         }
        }).catch(error => {});
    },
    auth_error(state){
      state.isLoggedIn = false
    },
    logout(state) {
      state.isLoggedIn = false;
      state.user.id = '';
      state.user.email = '';
      state.user.username = '';
      state.user.iri = '';
      state.user.roles = [];
      state.user.creditSETS = 0;
    },
    setCurrencies(state, currencies)
    {
      state.currencies = currencies;
    },
    setListing(state, id) {
      if(id !== null) {
        let split = id.split("/");
        state.listing.id = split[split.length - 1];
      }
      else
        state.listing.id = null;
    },
    setListingImages(state, images) {
      state.listing.images = [];
      for (let index = 0; index < images.length; index++ )
      {
        let url;
        if(images[index].contentUrl)
          url = images[index].contentUrl;
        else
          url = images[index].url;

        let name = url.split("/");
        name = name[name.length -1];

        let id;
        if(images[index]['@id'])
          id = images[index]['@id'].split("/");
        else
          id = images[index].id.split("/");
        id = id[id.length-1];

        state.listing.images.push({
          url: url,
          name: name,
          id: id
        });
      }
    },
    setListingPrices(state, prices) {
      state.listing.prices = [];
      for (let index=0; index < prices.length; index++)
      {
        let pack = prices[index];
        let item = {};
        let split = pack['@id'].split("/");
        item.id = split[split.length - 1];
        item.iri = pack['@id'];
        item.name = pack.name;
        item.description = pack.description;
        item.price = pack.price;
        item.currency = pack.currency;
        state.listing.prices.push(item);
      }
    },
    updateProfilePhoto(state, fileUrl) {
      if(fileUrl !== null && fileUrl !== undefined && fileUrl !== '')
        state.user.photo_profile = fileUrl;
      else
        state.user.photo_profile = '/static/img/default-avatar.jpg';
      EventBus.$emit('user-photo-profile-updated', state.user.photo_profile);
    },
    setLang(state, lang) {
      state.webPreferences.lang = lang;
    },
    setCurrency(state, currency) {
      state.webPreferences.currency = currency;
    },
    setUserCreditSETS(state, credit) {
      state.user.creditSETS = credit;
      EventBus.$emit('credit-sets-updated', credit);
    },
    addUserCreditSETS(state, credit) {
      state.user.creditSETS = parseFloat(state.user.creditSETS) + parseFloat(credit);
      EventBus.$emit('credit-sets-updated', state.user.creditSETS);
    },
    setRoles(state, roles) {
      state.user.roles = roles;
      for (const role of roles)
        EventBus.$emit('user-role-added', role);
    },
    addRoles(state, roles) {
      state.user.roles.push(roles);
      for (const role of roles)
        EventBus.$emit('user-role-added', role);
    },
    removeRoles(state, roles) {
      for(const role of roles)
      {
        const index = state.user.roles.indexOf(role);
        if (index > -1) {
          state.user.roles.splice(index, 1);
          EventBus.$emit('user-role-removed', role);
        }
      }
    },
  },
  getters : {
    isLoggedIn: state => state.isLoggedIn,
    getUsername: state => state.user.username,
    getEmail: state => state.user.email,
    getUserId: state => state.user.id,
    getUserIri: state => state.user.iri,
    getUserPhoto: state => state.user.photo_profile,
    getUserRoles: state => state.user.roles,
    getListingId: state => state.listing.id,
    getListingData: state => state.listing,
    getLang: state => state.webPreferences.lang,
    getCurrency: state => state.webPreferences.currency,
    getCreditSETS: state => state.user.creditSETS,
    hasRole: (state) => (role) => { return state.user.roles.includes(role); },
    transformCurrency: (state) => (value, from, to) => {return value * state.currencies[from].rate / state.currencies[to].rate },
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) =>
          Cookies.set(key, value, { expires: 365, secure: true, sameSite: "strict" }),
        removeItem: (key) => Cookies.remove(key),
      },
      paths: ['webPreferences']
    })
  ]
});

export default store
