<template>
  <div class="register-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page ">
      <div class="full-page register-page section-image" filter-color="black">
        <div class="content">
          <div class="container">
            <div class="row">

              <div class="col-xl-6 col-md-8 col-12 m-auto">
                <card type="signup" class="text-center">
                  <template slot="header">
                    <h4 class="card-title">{{ $t("contact_us") }}</h4>
                    <div class="social">
                      <button class="btn btn-icon btn-round btn-twitter">
                        <i class="fa fa-twitter"></i>
                      </button>
                      <button class="btn btn-icon btn-round btn-dribbble">
                        <i class="fa fa-dribbble"></i>
                      </button>
                      <button class="btn btn-icon btn-round btn-facebook">
                        <i class="fa fa-facebook-f"></i>
                      </button>
                      <p class="card-description"> or be classical </p>
                    </div>
                  </template>

                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(contactUs)">
                      <div v-if="error" class="alert text-danger"><strong>{{ error }}</strong></div>

                      <ValidationProvider name="subject" rules="required" v-slot="{ passed, failed }">
                        <fg-input name="subject" :hasSuccess="passed"
                                  :error="failed ? $t('common.field_required') : null">
                          <label>{{ $t('contacts.subject') }}:</label>
                          <el-select class="select-primary" size="small" v-model="form.subject" value="">
                            <el-option v-for="option in subject_options"
                                       class="select-primary" :value="option"
                                       :label="$t('contacts.' + option)" :key="option">
                            </el-option>
                          </el-select>
                        </fg-input>
                      </ValidationProvider>

                      <ValidationProvider name="name" rules="required" v-slot="{ passed, failed }">
                        <fg-input v-model="form.name" addon-left-icon="nc-icon nc-single-02"
                                  :placeholder="$t('contacts.name')"
                                  type="text" name="name"
                                  :error="failed ? $t('common.field_required') : null"
                                  :hasSuccess="passed"></fg-input>
                      </ValidationProvider>

                      <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed }">
                        <fg-input v-model="form.email" addon-left-icon="nc-icon nc-email-85" placeholder="E-mail"
                                type="email" name="email" autocomplete="email"
                                :error="failed ? $t('common.field_required') : null"
                                :hasSuccess="passed" ></fg-input>
                      </ValidationProvider>

                      <ValidationProvider name="message" rules="required|min:20|max:200" v-slot="{ passed, failed }">
                        <div class="form-group">
                          <label style="width: 100%">
                            <textarea rows="5" class="form-control border-input" v-model="form.message" :placeholder="$t('contacts.message')"></textarea>
                          </label>
                        </div>

                        <fg-input  type="hidden" name="message_hidden" v-model="form.message"
                                   :error="failed ? $t('common.required_and_in_range_value', {min: 20, max: 200}) : null"
                                   :hasSuccess="passed">
                        </fg-input>
                      </ValidationProvider>

                      <ValidationProvider :rules="{required: { allowFalse: false }}" v-slot="{ passed, failed }">
                        <p-checkbox class="text-left has-error" v-model="form.acceptPrivacy"
                                    :error="failed">
                          <p class="m-0 p-0"><b class="text-danger">*</b> {{ $t('registration.accept_check') }}
                            <router-link to="/privacy_policy/" tag="a" target="_blank">Privacy Policy</router-link>.</p>
                        </p-checkbox>
                      </ValidationProvider>

                      <vue-hcaptcha :sitekey="captchaKey" @verify="captchaVerified"></vue-hcaptcha>

                      <p-button native-type="submit" slot="footer" type="primary" round block class="my-4"
                                :disabled="buttonDisabled">{{ $t('contact_us') }}</p-button>
                    </form>
                  </ValidationObserver>
                </card>
              </div>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
        <div class="full-page-background" style="background-image: url(/static/img/background/jan-sendereks.jpg)"></div>
      </div>
    </div>
  </div>
</template>
<script>
  import AppNavbar from './AppNavbar'
  import AppFooter from './AppFooter'
  import { Card, Checkbox, Button, InfoSection } from 'src/components/UIComponents';
  import { extend } from "vee-validate";
  import { required, email } from "vee-validate/dist/rules";
  import axios from 'axios';
  import Swal from 'sweetalert2';
  import { Select, Option } from 'element-ui';
  import VueHcaptcha from '@hcaptcha/vue-hcaptcha';


  extend("email", email);
  extend("required", required);

  export default {
    components: {
      Card,
      AppNavbar,
      AppFooter,
      InfoSection,
      [Checkbox.name]: Checkbox,
      [Button.name]: Button,
      [Select.name]: Select,
      [Option.name]: Option,
      VueHcaptcha
    },
    data() {
      return {
        form: {
          name: '',
          subject: '',
          email: '',
          message: '',
          acceptPrivacy: false,
          captchaToken: null
        },
        error: '',
        buttonDisabled: false,
        subject_options: ["general_info", "bug_report", "marketing", "help", "other"],
        captchaKey: process.env.VUE_APP_HCAPTCHA_SITE_KEY,

      }
    },
    methods: {
      captchaVerified  (token, eKey) {
          this.form.captchaToken = token;
      },
      contactUs() {
        this.error = '';
        if (this.form.captchaToken === null)
        {
            this.error = "error.missing_captcha";
            return;
        }
        this.buttonDisabled = true;

        axios.post(process.env.VUE_APP_SERVER_BASE_URL + 'contact_us/', this.form)
        .then(response => {
            Swal.fire({
                icon: 'success',
                title: this.$i18n.t("registration.email_sent"),
                text: this.$i18n.t("contacts.request_received"),
                confirmButtonClass: "btn btn-info",
                buttonsStyling: false
            });
            this.form.subject = "";
            this.form.name = "";
            this.form.email = "";
            this.form.message = "";
            this.form.acceptPrivacy = false;
        }).catch(error => {
            if (error.response && error.response.data && error.response.data.violations &&
                error.response.data.violations.length > 0) {
                for (let i = 0; i < error.response.data.violations.length; i++)
                {
                    let stringErr = error.response.data.violations[i].message;
                    if(stringErr.endsWith("."))
                        stringErr = stringErr.slice(0, -1);
                    if(stringErr.startsWith("error."))
                        this.error += this.$i18n.t(stringErr) + ". ";
                    else
                      this.error += this.$i18n.t("error." + stringErr) + ". ";
                }
            } else if(error.response && error.response.data && error.response.data.message)
            {
                let stringErr = error.response.data.message;
                if(stringErr.endsWith("."))
                    stringErr = stringErr.slice(0, -1);
                if(stringErr.startsWith("error."))
                    this.error += this.$i18n.t(stringErr) + ". ";
                else
                  this.error += this.$i18n.t("error." + stringErr);
            }
            else
                this.error = this.$i18n.t("error.try_again");
        }).finally(() => {this.buttonDisabled = false});

      },
      toggleNavbar() {
        document.body.classList.toggle('nav-open');
      },
      closeMenu() {
        document.body.classList.remove('nav-open');
        document.body.classList.remove('off-canvas-sidebar');
      }
    },
    beforeDestroy() {
      this.closeMenu()
    }
  }
</script>
<style>
</style>
