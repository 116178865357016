<template>
  <div class="content">

    <div class="row">
      <div class="col-12">

        <div class="card">
          <div class="card-header">
            <div class="col-12 text-center">
              <p class="text-danger"><b>Please note that this is a demo. You will not be paid for your job in this moment.</b></p>
            </div>

            <h3 v-if="listingData">{{ listingData.listingTitle }}</h3>

            <div class="row">
              <div v-if="loading" class="loading col-12"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.loading') }}</div>
            </div>
          </div>


          <div class="card-body" v-if="listingData">
            <div class="row">
              <div class="col-6 col-md-4 col-xl-3">
                <img :src="listingData.coverImageUrl"
                     v-if="listingData.coverImageUrl"
                     width="200px" height="auto" class="m-auto d-block"/>
              </div>

              <div class="col-6 col-md-8 col-xl-9">
                <p class="m-2">{{ listingData.packageName }} - {{ listingData.currency }} {{ listingData.packagePrice }}</p>
                <p class="m-2"><i class="fa fa-star text-warning"></i> 4.5 (130 reviews)</p>
              </div>

            </div>
            <div class="row mt-4">
              <div class="col-12">
                <p style="white-space: pre-line">{{ listingData.listingDescription }}</p>
              </div>
            </div>


            <div class="row m-4" v-if="checkout && checkout.smartContractAddress && checkout.smartContractAddress !== 'none'">
              <div class="col-12 text-center" style="font-weight: bold">
                <a :href="'https://testnet.bscscan.com/address/' + checkout.smartContractAddress" target="_blank">Smart Contract</a>
              </div>
            </div>

          </div>

        </div>


      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
          </div>
          <div class="card-body">

            <div class="row" v-if="activity">
              <div class="col-12 mb-2">
                <div v-if="activity.status === 'new'">
                  <p class="mt-2 text-center">A new activity is waiting for you!</p>
                </div>

                <div v-else-if="activity.status === 'workInProgress'">
                  <p class="mt-2 text-center">Work in progress...</p>
                </div>

                <div v-else-if="activity.status === 'waitingAuditors'">
                  <p class="mt-2 text-center">Good job! Let's wait for the auditors review!</p>
                </div>

                <div v-else-if="activity.status === 'waitingApproval'">
                  <p class="mt-2 text-center">Everything is ready! Waiting for the decision by the customer...</p>
                </div>

                <div v-else-if="activity.status === 'waitingFeedback'">
                  <p class="mt-2 text-center">Please leave your feedback!</p>
                </div>

                <div v-else>
                  <p class="mt-2 text-center">The transaction is completed!</p>
                </div>
              </div>
            </div>



            <div class="row">
              <div class="col-6 text-center">
                <i class="nc-icon nc-delivery-fast align-middle" style="font-size: 28px"></i>  Delivery Time:
                <b>
                  <span v-if="delivered_in.minutes !== null">
                    <span v-if="delivered_in.days > 0">{{ delivered_in.days }} days, </span>
                    <span v-if="delivered_in.days > 0 || delivered_in.hours > 0">{{ delivered_in.hours }} hours, </span>
                    <span v-if="delivered_in.days > 0 || delivered_in.hours > 0 || delivered_in.minutes > 0">{{ delivered_in.minutes }} minutes.</span>
                    <span v-else> < 1 minute</span>
                  </span>

                  <countdown v-else-if="countdownTime"
                                 :time="countdownTime"
                                 v-slot="{ days, hours, minutes, seconds }">
                    {{ days }} days, {{ hours }} hours, {{ minutes }} minutes, {{ seconds }} seconds.
                  </countdown>

                  <span v-else> - </span>
                </b>

                <span v-if="listingData">
                  ({{ listingData.deliveryTime }} {{listingData.deliveryTimeUnit}})
                </span>
              </div>
              <div class="col-6 text-center">
                <i class="nc-icon nc-refresh-69 align-middle" style="font-size: 28px"></i> Revision n.
                <span v-if="listingData">
                  <b><span v-if="activity"> {{ activity.revisionNumber }}</span> <span v-else> 1</span></b>
                  (Max {{ listingData.revisionsMax }})
                </span>
                <span v-else> - </span>
              </div>
            </div>



            <div class="mt-3">
              <ValidationObserver v-slot="{ handleSubmit }" v-if="activity && activity.status === 'new'">
                <form @submit.prevent="handleSubmit(startActivity)">
                  <div class="row">
                    <div class="col-12">
                      <p-button native-type="submit" class="btn-success m-3">
                        <i class="nc-icon nc-check-2" :disabled="saving || loading"></i> Start Working!</p-button>
                    </div>
                  </div>
                </form>
              </ValidationObserver>
            </div>



            <div class="mt-3">
              <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(deliverResults)">

                  <div class="row">
                    <div class="col-md-6">
                      <fg-input type="text" label="Link to material"
                                v-if="activity"
                                v-model="activity.linkToData"
                                :disabled="saving || activity.status !== 'workInProgress'">
                      </fg-input>
                      <fg-input type="text" label="Link to material"
                                v-else
                                :disabled="true">
                      </fg-input>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Note</label>
                        <textarea rows="5" class="form-control border-input"
                                  placeholder="Add some instruction to help the customer"
                                  v-if="activity"
                                  v-model="activity.note"
                                  :disabled="saving || activity.status !== 'workInProgress'">
                        </textarea>

                        <textarea rows="5" class="form-control border-input"
                                  placeholder="Add some instruction to help the customer"
                                  v-else
                                  :disabled="true">
                        </textarea>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <p-button native-type="submit" type="success" class="btn-success m-3"
                                v-if="activity"
                                :disabled="saving || activity.status !== 'workInProgress'">
                        <i class="nc-icon nc-check-2"></i> Deliver </p-button>

                      <p-button type="success" class="btn-success m-3"
                                v-else
                                :disabled="true">
                        <i class="nc-icon nc-check-2"></i> Deliver </p-button>
                    </div>
                  </div>
                </form>
              </ValidationObserver>
            </div>



            <hr/>



            <div class="row">
              <div class="col-12">
                <h4>Auditors comments</h4>
                <div v-if="checkout">
                  <div v-if="checkout.auditors === 0">
                    No Auditors involved.
                  </div>
                  <div v-else-if="checkout.auditors > 0 && audits.length < checkout.auditors">
                    <p>{{ checkout.auditors }} auditors involved. Waiting for their feedback..</p>
                  </div>
                  <div v-else>
                    <collapse>
                      <collapse-item v-for="audit in audits" :key="audit.id"
                                     :title="'Auditor ' + audit.auditorAlias"
                                     :name="audit.auditorAlias">
                        <div v-if="audit.status === 'completed'" class="row">
                          <div class="col-2 text-center" style="border-right: 1px solid lightgrey;">
                            <p style="font-size: 28px"><i class="fa fa-star text-warning"></i> {{ audit.overallScore }}</p>
                          </div>
                          <div class="col-7">
                            <p>{{ audit.description }}</p>
                          </div>

                          <feedback-card v-if="feedback[audit.auditorAlias]"  class="col-3"
                                         :score="feedback[audit.auditorAlias].score"
                                         :review="feedback[audit.auditorAlias].review"
                                         :role="feedback[audit.auditorAlias].createdByRole"
                                         :created-on="feedback[audit.auditorAlias].createdOn.split('T')[0]">
                          </feedback-card>


                          <div v-else-if="activity.status === 'waitingFeedback'" class="col-3">
                            <h6>Please leave your feedback about this auditor:</h6>
                            <ValidationObserver v-slot="{ handleSubmit }">
                              <form @submit.prevent="handleSubmit(sendAuditorFeedback(audit.auditorAlias))">
                                <div class="row">
                                  <div class="col-12">
<!--                                    <ValidationProvider name="score" rules="required|min_value:1|max_value:5" v-slot="{ passed, failed }">-->
<!--                                      <div class="form-group">-->
<!--                                        <fg-input v-model="newFeedback[audit.auditorAlias].score"-->
<!--                                                  :error="failed ? $t('common.field_required') : null" :hasSuccess="passed"-->
<!--                                                  name="score"-->
<!--                                                  placeholder="Score" type="number">-->
<!--                                          <template slot="addonLeft"><i class="fa fa-star text-warning"></i></template>-->
<!--                                        </fg-input>-->
<!--                                      </div>-->
<!--                                    </ValidationProvider>-->

                                    <el-slider name="score" class="slider-info" label="Score"
                                               v-model="newFeedback[audit.auditorAlias].score"
                                               :max="10" :min="1" :step="0.5">
                                    </el-slider>

                                  </div>
                                  <div class="col-12">
                                    <ValidationProvider name="review" rules="required" v-slot="{ passed, failed }">
                                      <textarea v-model="newFeedback[audit.auditorAlias].review"
                                                class="form-control" rows="8" name="review"
                                                placeholder="Please, write your review here">
                                      </textarea>

                                      <fg-input type="hidden" v-model="newFeedback[audit.auditorAlias].review"
                                                :error="failed ? $t('common.field_required') : null" :hasSuccess="passed"
                                                name="review">
                                      </fg-input>
                                    </ValidationProvider>
                                  </div>

                                  <div class="col-12">
                                    <p-button native-type="submit" class="btn-primary my-3"
                                              :disabled="saving">
                                      <i class="nc-icon nc-send"></i> Send</p-button>
                                  </div>
                                </div>
                              </form>
                            </ValidationObserver>
                          </div>

                        </div>
                        <p v-else> Waiting for Review by this Auditor.</p>
                      </collapse-item>
                    </collapse>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>







    <div class="row" v-if="previousActivityVersions.length > 0">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h4>Past Revisions</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <collapse>
                  <collapse-item v-for="a in previousActivityVersions"
                                 :key="a.id"
                                 :title="'Revision #' + a.revisionNumber"
                                 :name="a.revisionNumber">
                    <div class="row">
                      <div class="col-12">
                        <p>
                          <i class="nc-icon nc-delivery-fast align-middle" style="font-size: 28px"></i> Delivered in
                          <b v-if="a.delivered_in && a.delivered_in.minutes">
                            <span v-if="a.delivered_in.days > 0">{{ a.delivered_in.days }} days, </span>
                            <span v-if="a.delivered_in.hours > 0">{{ a.delivered_in.hours }} hours, </span>
                            {{ a.delivered_in.minutes }} minutes.
                          </b>
                        </p>
                      </div>

                      <div class="col-6" style="border-right: 1px solid lightgrey;">
                        <a :href="a.linkToData">
                          <i class="nc-icon nc-cloud-download-93 align-middle" style="font-size: 28px"></i> Download
                        </a>
                        <p v-if="a.note" class="mt-2">{{ a.note }}</p>
                      </div>

                      <div class="col-6">
                        <p>Reject reason: </p>
                        <p>{{ a.reviewCustomer }}</p>
                      </div>

                      <div class="col-12 px-5">
                        <collapse>
                          <collapse-item v-for="audit in a.auditsData"
                                         :key="audit.id"
                                         :title="'Auditor ' + audit.auditorAlias"
                                         :name="audit.auditorAlias">
                            <span class="d-none">{{ audit.id }}</span>
                            <div class="row">
                              <div class="col-3 text-center" style="border-right: 1px solid lightgrey;">
                                <p style="font-size: 28px"><i class="fa fa-star text-warning"></i> {{ audit.overallScore }}</p>
                              </div>
                              <div class="col-9">
                                <p>{{ audit.description }}</p>
                              </div>
                            </div>
                          </collapse-item>
                        </collapse>
                      </div>

                    </div>
                  </collapse-item>
                </collapse>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>






    <div class="row" v-if="newFeedback['customer'] && !newFeedback['customer'].score">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h4>Leave your reviews!</h4>
          </div>
          <div class="card-body" v-if="activity">

            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(sendCustomerFeedback())">
                <div class="row">
                  <div class="col-12">
<!--                    <ValidationProvider name="score" rules="required|min_value:1|max_value:5" v-slot="{ passed, failed }">-->
<!--                      <div class="form-group">-->
<!--                        <fg-input :disabled="saving || activity.status !== 'waitingFeedback'"-->
<!--                                  v-model="newFeedback['customer'].score"-->
<!--                                  placeholder="Score" type="number" class="col-12 col-md-6 col-lg-4 col-xl-3"-->
<!--                                  :error="failed ? $t('common.field_required') : null" :hasSuccess="passed">-->
<!--                          <template slot="addonLeft"><i class="fa fa-star text-warning"></i></template>-->
<!--                        </fg-input>-->
<!--                      </div>-->
<!--                    </ValidationProvider>-->

                    <el-slider name="score" class="slider-info col-12 col-md-6 col-lg-4 col-xl-3" label="Score"
                               v-model="newFeedback['customer'].score"
                               :disabled="saving || activity.status !== 'waitingFeedback'"
                               :max="10" :min="1" :step="0.5">
                    </el-slider>
                  </div>

                  <div class="col-12">
                    <ValidationProvider name="review" rules="required" v-slot="{ passed, failed }">
                      <textarea v-model="newFeedback['customer'].review"
                                :disabled="saving || activity.status !== 'waitingFeedback'"
                                class="form-control" rows="8" name="review"
                                placeholder="Please, write your review here">
                      </textarea>

                      <fg-input type="hidden" v-model="newFeedback['customer'].review"
                                :error="failed ? $t('common.field_required') : null" :hasSuccess="passed"
                                name="review">
                      </fg-input>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <p-button type="primary" native-type="submit"
                              :disabled="saving || activity.status !== 'waitingFeedback'">
                      <i class="nc-icon nc-send"></i> Save
                    </p-button>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>


    <div class="row">

      <feedback-card v-if="feedback['customer']" class="col-4"
                     :score="feedback['customer'].score"
                     :review="feedback['customer'].review"
                     :role="feedback['customer'].createdByRole"
                     :created-on="feedback['customer'].createdOn.split('T')[0]">
      </feedback-card>

      <feedback-card v-if="feedback['professional']" class="col-4"
                     :score="feedback['professional'].score"
                     :review="feedback['professional'].review"
                     :role="feedback['professional'].createdByRole"
                     :created-on="feedback['professional'].createdOn.split('T')[0]">
      </feedback-card>

    </div>

    <router-view></router-view>
  </div>
</template>

<script>
  import axios from 'axios';
  import {Table, TableColumn, Message, Slider } from 'element-ui'
  import PButton from "src/components/UIComponents/Button";
  import VueCountdown from '@chenfengyuan/vue-countdown';
  import { Collapse, CollapseItem } from 'src/components/UIComponents';
  import feedbackCard from "src/components/GeneralViews/FeedbackCard"
  import { extend } from "vee-validate";
  import { required } from "vee-validate/dist/rules";
  extend("required", required);

  export default {
    name: 'ManageActivity',
    components: {
      PButton,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [VueCountdown.name]: VueCountdown,
      [Slider.name]: Slider,
      Collapse,
      CollapseItem,
      feedbackCard
    },
    data() {
      return {
        images_base_url: process.env.VUE_APP_IMAGES_BASE_URL,
        loading: true,
        error: null,
        saving: false,
        activity: null,
        checkout: null,
        listingData: null,
        countdownTime: null,
        delivered_in: {
          days: null,
          hours: null,
          minutes: null},
        audits: [],
        previousActivityVersions: {},
        feedback: {}, // key: auditorAlias, values: feedback instance
        newFeedback: {}
      }
    },
    methods: {
      getCheckout(checkoutId) {
        axios.get(process.env.VUE_APP_SERVER_BASE_URL + "checkouts/" + checkoutId,
            {headers: {'X-LOCALE': this.$store.getters.getLang}})
        .then(response => {
          this.checkout = response.data;
          this.getListingData(response.data.checkoutListingSummary.id);
          for(let i = 1; i<this.checkout.activities.length; i++)
          {
            this.getActivityData(this.checkout.activities[i].split("/")[3]);
          }

          this.getFeedback();

        }).catch(error => {
          this.loading = false;
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.$store.commit('logout');
            this.$router.push('/login');
          }
          else if (error.response && error.response.status === 404) {
            console.log("order not found");
            this.$router.push('/professional/dashboard');
          }
          else {
            this.error = 'err_data_not_received';
            console.log(error);
          }
        });
      },
      getCoverImage(iri) {
        let id = iri.split("/")[3];
        axios.get(process.env.VUE_APP_SERVER_BASE_URL + "listing_images/" + id,
            {headers: {'X-LOCALE': this.$store.getters.getLang}})
        .then(res => {
          this.listingData.coverImageUrl = res.data.contentUrl;

          this.loading = false;
        }).catch(error => {
          this.loading = false;
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.$store.commit('logout');
            this.$router.push('/login');
          }
          else if (error.response && error.response.status === 404) {
            console.log("order not found");
            this.$router.push('/professional/dashboard');
          }
          else {
            this.error = 'err_data_not_received';
            console.log(error);
          }
        });
      },
      getListingData(id) {
        axios.get(process.env.VUE_APP_SERVER_BASE_URL + "checkout_listing_summaries/" + id,
            {headers: {'X-LOCALE': this.$store.getters.getLang}})
        .then(res => {
          this.listingData = res.data;
          this.getCoverImage(res.data.coverImage);

          if(this.activity.status === 'workInProgress') {
            let startDate = new Date(this.activity.startedAt);
            let now = new Date();

            if (this.listingData.deliveryTimeUnit === 'hour')
              startDate.setHours(startDate.getHours() + this.listingData.deliveryTime);
            else if (this.listingData.deliveryTimeUnit === 'day')
              startDate.setDate(startDate.getDate() + this.listingData.deliveryTime);
            else if (this.listingData.deliveryTimeUnit === 'month')
              startDate.setDate(startDate.getMonth() + this.listingData.deliveryTime);
            else if (this.listingData.deliveryTimeUnit === 'year')
              startDate.setDate(startDate.getFullYear() + this.listingData.deliveryTime);

            let diffMills = startDate.getTime() - now.getTime();
            //TODO: dice 50 min invece di 5..
            this.countdownTime = diffMills > 0 ? diffMills : 0;
          }
        }).catch(error => {
          this.loading = false;
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.$store.commit('logout');
            this.$router.push('/login');
          }
          else if (error.response && error.response.status === 404) {
            console.log("order not found");
            this.$router.push('/professional/dashboard');
          }
          else {
            this.error = 'err_data_not_received';
            console.log(error);
          }
        });
      },
      getActivityData(activityId) {
        axios.get(process.env.VUE_APP_SERVER_BASE_URL + "activities/" + activityId,
            {headers: {'X-LOCALE': this.$store.getters.getLang}})
        .then(response => {
          let act = response.data;

          if (act.audits) {
            act.auditsData = {};
            for (let i = 0; i < act.audits.length; i++) {
              let auditId = act.audits[i].split("/")[3];
              act.auditsData[auditId] = {};
              this.getAudit(auditId, false);
            }
          }

          if (act.status !== 'new' && act.lastChangeOn && act.startedAt) {

            act.delivered_in = this.updateDeliveryTime(act.startedAt, act.lastChangeOn);
          }

          this.previousActivityVersions[activityId] = act;
        }).catch(error => {
          this.loading = false;
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.$store.commit('logout');
            this.$router.push('/login');
          }
          else if (error.response && error.response.status === 404) {
            console.log("activity not found");
            this.$router.push('/professional/dashboard');
          }
          else {
            this.error = 'err_data_not_received';
            console.log(error);
          }
        });
      },
      getAudit(auditId, isCurrent) {
        axios.get(process.env.VUE_APP_SERVER_BASE_URL + "audits/" + auditId,
            {headers: {'X-LOCALE': this.$store.getters.getLang}})
        .then(response => {
          if (response.data.status !== 'rejected') {
              if (isCurrent) {
                  this.audits.push(response.data);
                  if (this.feedback[response.data.auditorAlias] === undefined)
                      this.newFeedback[response.data.auditorAlias] = {score: null, review: null};
              } else
                  this.previousActivityVersions[response.data.activity.split("/")[3]].auditsData[auditId] = response.data;
          }
        }).catch(error => {
          this.loading = false;
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.$store.commit('logout');
            this.$router.push('/login');
          }
          else if (error.response && error.response.status === 404) {
            console.log("audit not found");
            this.$router.push('/professional/dashboard');
          }
          else {
            this.error = 'err_data_not_received';
            console.log(error);
          }
        });
      },
      getFeedback() {
        axios.get(process.env.VUE_APP_SERVER_BASE_URL + "feedback/get_user_feedback/" + this.checkout.id,
            {headers: {'X-LOCALE': this.$store.getters.getLang}})
            .then(response => {
              for(let i = 0; i< response.data['hydra:totalItems']; i++)
                this.feedback[response.data['hydra:member'][i].toAlias] = response.data['hydra:member'][i];

              if (!this.feedback.hasOwnProperty('customer'))
                this.newFeedback.customer = {score: null, review: null};
            }).catch(error => {
          this.loading = false;
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.$store.commit('logout');
            this.$router.push('/login');
          }
          else {
            this.error = 'err_data_not_received';
            console.log(error);
          }
        });
      },
      updateDeliveryTime(startDate, endDate) {
        let lastChangeOn = new Date(endDate);
        let startedAt = new Date(startDate);

        var diffInTime = lastChangeOn.getTime() - startedAt.getTime();
        let d_in = {
          days: null,
          hours: null,
          minutes: null};

        d_in.days = parseInt(diffInTime / (1000 * 3600 * 24));
        diffInTime -= d_in.days * 1000 * 3600 * 24;
        d_in.hours = parseInt(diffInTime / (1000 * 3600));
        diffInTime -= d_in.hours * 1000 * 3600;
        d_in.minutes = parseInt(diffInTime / (1000 * 60));

        return d_in;
      },
      startActivity() {
        this.saving = true;

        let config = {
          method: 'patch',
          url: process.env.VUE_APP_SERVER_BASE_URL + 'activities/' + this.activity.id + '/start',
          data: {},
          headers: {'Content-Type': 'application/merge-patch+json'}
        };

        axios(config).then(response => {
          this.saving = false;
          Message.success("Great! Let's start your amazing job!");
          this.activity = response.data;
        }).catch(error => {
          this.saving = false;
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.$store.commit('logout');
            this.$router.push('/login');
          }
          else {
            Message.error('An error occurred. Please try again.');
            console.log(error);
          }
        });
      },
      deliverResults() {
        this.saving = true;

        let config = {
          method: 'patch',
          url: process.env.VUE_APP_SERVER_BASE_URL + 'activities/' + this.activity.id + '/deliver',
          data: {
            linkToData: this.activity.linkToData,
            note: this.activity.note
          },
          headers: {'Content-Type': 'application/merge-patch+json'}
        };

        axios(config).then(response => {
          this.saving = false;
          this.activity = response.data;
          Message.success("Your work has been delivered!");

          this.delivered_in = this.updateDeliveryTime(this.activity.startedAt, this.activity.lastChangeOn);
          this.countdownTime = null;

        }).catch(error => {
          this.saving = false;
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.$store.commit('logout');
            this.$router.push('/login');
          }
          else {
            this.error = 'An error occurred. Please try again.';
            console.log(error);
          }
        });
      },
      sendAuditorFeedback (auditorAlias) {
        this.saving = true;
        axios.post(process.env.VUE_APP_SERVER_BASE_URL + "feedback", {
          score: parseFloat(this.newFeedback[auditorAlias].score),
          review: this.newFeedback[auditorAlias].review,
          toAlias: auditorAlias,
          checkout: this.checkout['@id']
        }).then(response => {
          this.saving = false;
          this.feedback[response.data.toAlias] = response.data;
          this.newFeedback[response.data.toAlias] = undefined;

        }).catch(error => {
          this.saving = false;
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.$store.commit('logout');
            this.$router.push('/login');
          }
          else if (error.response && error.response.status === 404) {
            console.log("activity not found");
            this.$router.push('/professional/dashboard');
          }
          else {
            this.error = 'err_data_not_received';
            console.log(error);
          }
        });
      },
      sendCustomerFeedback() {
        this.saving = true;
        axios.post(process.env.VUE_APP_SERVER_BASE_URL + "feedback", {
          score: parseFloat(this.newFeedback.customer.score),
          review: this.newFeedback.customer.review,
          toAlias: 'customer',
          checkout: this.checkout['@id']
        }).then(response => {
          this.saving = false;
          this.feedback.customer = response.data;
          this.newFeedback.customer = undefined;

        }).catch(error => {
          this.saving = false;
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.$store.commit('logout');
            this.$router.push('/login');
          }
          else if (error.response && error.response.status === 404) {
            console.log("activity not found");
            this.$router.push('/dashboard');
          }
          else {
            this.error = 'err_data_not_received';
            console.log(error);
          }
        });
      }
    },
    beforeMount() {
      this.loading = true;
      let activityId = this.$route.params.activity_id;
      if(!activityId)
        this.$router.push("/professional/dashboard");

      axios.get(process.env.VUE_APP_SERVER_BASE_URL + "activities/" + activityId,
          {headers: {'X-LOCALE': this.$store.getters.getLang}})
      .then(response => {
        this.activity = response.data;
        this.getCheckout(this.activity.checkout['@id'].split("/")[3]);

        if (this.activity.status !== 'new' && this.activity.status !== 'workInProgress' &&
            this.activity.lastChangeOn && this.activity.startedAt) {

          this.delivered_in = this.updateDeliveryTime(this.activity.startedAt, this.activity.lastChangeOn);
        }

        if (this.activity.audits)
        {
          for (let i = 0; i< this.activity.audits.length; i++)
            this.getAudit(this.activity.audits[i].split("/")[3], true);
        }

      }).catch(error => {
        this.loading = false;
        if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
          this.$store.commit('logout');
          this.$router.push('/login');
        }
        else if (error.response && error.response.status === 404) {
          console.log("order not found");
          this.$router.push('/professional/dashboard');
        }
        else {
          this.error = 'err_data_not_received';
          console.log(error);
        }
      });
    }
  }
</script>

<style scoped>

</style>
