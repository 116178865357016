<template>
  <div class="login-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page">
      <div class="full-page register-page section-image">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="row">
              <div class="col-12 ml-auto mr-auto">

                <card centered class="px-3">
                  <h4 class="card-title text-primary text-center"><b>Terms & Conditions</b></h4>

                  <div class="row">
                    <div class="col-12 my-5 text-center">
                      <a :href="tcLink" target="_blank" download="Term of Use.pdf">
                        <i class="nc-icon nc-cloud-download-93 align-middle" style="font-size: 18px"></i>
                        Download our Term of Use
                      </a>
                    </div>
                  </div>
                </card>
              </div>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
        <div class="full-page-background" style="background-image: url(/static/img/background/background-2.jpg)"></div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Card, Button } from 'src/components/UIComponents';
  import AppNavbar from './AppNavbar'
  import AppFooter from './AppFooter'
  import axios from 'axios';
  import PButton from "../../UIComponents/Button";


  export default {
    components: {
      PButton,
      Card,
      AppNavbar,
      AppFooter,
      [Button.name]: Button
    },
    methods: {
      toggleNavbar() {
        document.body.classList.toggle('nav-open')
      },
      closeMenu() {
        document.body.classList.remove('nav-open');
        document.body.classList.remove('off-canvas-sidebar');
      }
    },
    data() {
      return {
          tcLink: require("/src/assets/files/TermsOfUse.pdf"),
      }
    },
    beforeDestroy() {
      this.closeMenu()
    },
    beforeMount() {
    }
  }
</script>
<style>
  .loginBackground {
    /*background: url('./public/static/img/background.jpg');*/
  }
</style>

