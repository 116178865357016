<template>
  <div class="register-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page ">
      <div class="full-page register-page section-image" filter-color="black">
        <div class="content">
          <div class="container">
            <div class="row">
<!--              <div class="col-lg-5 col-md-5 ml-auto">-->
<!--                <info-section class="mt-5"-->
<!--                              type="primary"-->
<!--                              title="Marketing"-->
<!--                              description="We've created the marketing campaign of the website. It was a very interesting collaboration."-->
<!--                              icon="nc-icon nc-tv-2">-->

<!--                </info-section>-->
<!--                <info-section type="primary"-->
<!--                              title="Fully Coded in HTML5"-->
<!--                              description="We've developed the website with HTML5 and CSS3. The client has access to the code using GitHub."-->
<!--                              icon="nc-icon nc-html5">-->

<!--                </info-section>-->

<!--                <info-section type="primary"-->
<!--                              title="Built Audience"-->
<!--                              description="There is also a Fully Customizable CMS Admin Dashboard for this product."-->
<!--                              icon="nc-icon nc-atom">-->

<!--                </info-section>-->
<!--              </div>-->
              <div class="col-xl-5 col-md-6 m-auto">
                <card type="signup" class="text-center">
                  <template slot="header">
                    <h4 class="card-title">{{ $t("registration.register") }}</h4>
<!--                    <div class="social">-->
<!--                      <button class="btn btn-icon btn-round btn-twitter">-->
<!--                        <i class="fa fa-twitter"></i>-->
<!--                      </button>-->
<!--                      <button class="btn btn-icon btn-round btn-dribbble">-->
<!--                        <i class="fa fa-dribbble"></i>-->
<!--                      </button>-->
<!--                      <button class="btn btn-icon btn-round btn-facebook">-->
<!--                        <i class="fa fa-facebook-f"></i>-->
<!--                      </button>-->
<!--                      <p class="card-description"> or be classical </p>-->
<!--                    </div>-->
                  </template>

                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(register)">
                      <div v-if="form.error" class="alert text-danger"><strong>{{ $t(form.error) }}</strong></div>

                      <ValidationProvider name="firstName" rules="required" v-slot="{ passed, failed }">
                        <fg-input v-model="form.firstName" addon-left-icon="nc-icon nc-single-02"
                                  :placeholder="$t('registration.first_name')"
                                  type="text" name="firstName" autocomplete="first name"
                                  :error="failed ? $t('common.field_required') : null"
                                  :hasSuccess="passed"></fg-input>
                      </ValidationProvider>

                      <ValidationProvider name="lastName" rules="required" v-slot="{ passed, failed }">
                        <fg-input v-model="form.lastName" addon-left-icon="nc-icon nc-single-02"
                                  :placeholder="$t('registration.last_name')"
                                  type="text" name="lastName" autocomplete="last name"
                                  :error="failed ? $t('common.field_required') : null"
                                  :hasSuccess="passed"></fg-input>
                      </ValidationProvider>

                      <ValidationProvider name="username" rules="required" v-slot="{ passed, failed }">
                        <fg-input v-model="form.username" addon-left-icon="nc-icon nc-circle-10" placeholder="Username"
                                  type="text" name="username" autocomplete="username"
                                  :error="failed ? $t('common.field_required') : null"
                                  :hasSuccess="passed">
                        </fg-input>
                      </ValidationProvider>

                      <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed }">
                        <fg-input v-model="form.email" addon-left-icon="nc-icon nc-email-85" placeholder="E-mail"
                                type="email" name="email" autocomplete="email"
                                :error="failed ? $t('common.field_required') : null"
                                :hasSuccess="passed" ></fg-input>
                      </ValidationProvider>

                      <ValidationProvider vid="confirmation" rules="required|min:8" v-slot="{ passed, failed }">
                        <fg-input  type="password" name="password" v-model="form.password"
                                   addon-left-icon="nc-icon nc-key-25" placeholder="Password" autocomplete="password"
                                   :error="failed ? $t('registration.password_required') : null"
                                   :hasSuccess="passed">
                        </fg-input>
                      </ValidationProvider>

                      <ValidationProvider rules="required|confirmed:confirmation" v-slot="{ passed, failed }">
                        <fg-input  type="password" name="confirm" v-model="form.confirmPassword"
                                   addon-left-icon="nc-icon nc-key-25"
                                   :placeholder="$t('registration.confirm_pass')"
                                   :error="failed ? $t('common.field_required') : null"
                                   :hasSuccess="passed">
                        </fg-input>
                      </ValidationProvider>

                      <ValidationProvider :rules="{required: { allowFalse: false }}" v-slot="{ passed, failed }">
                        <p-checkbox class="text-left has-error" v-model="form.acceptPrivacy"
                                    :error="failed">
                          <p class="m-0 p-0"><b class="text-danger">*</b> {{ $t('registration.accept_check') }}
                            <router-link to="/privacy_policy/" tag="a" target="_blank">Privacy Policy</router-link>.</p>
                        </p-checkbox>
                      </ValidationProvider>

                      <ValidationProvider :rules="{required: { allowFalse: false }}" v-slot="{ passed, failed }">
                        <p-checkbox class="text-left has-error" v-model="form.acceptTerms"
                                    :error="failed">
                          <p class="m-0 p-0"><b class="text-danger">*</b> {{ $t('registration.accept_check') }}
                            <router-link to="/terms_and_conditions/" tag="a" target="_blank">{{$t('common.terms_conditions')}}</router-link>.</p>
                        </p-checkbox>
                      </ValidationProvider>

                      <ValidationProvider :rules="{required: { allowFalse: false }}" v-slot="{ passed, failed }">
                        <p-checkbox class="text-left has-error" v-model="form.adult"
                                    :error="failed">
                          <p class="m-0 p-0"><b class="text-danger">*</b> {{ $t('registration.adult_declaration') }}.</p>
                        </p-checkbox>
                      </ValidationProvider>

                      <p-checkbox class="text-left" v-model="form.newsletter">
                        <p class="m-0 p-0"> {{ $t('newsletter.subscribe_me') }}</p>
                      </p-checkbox>

                      <vue-hcaptcha :sitekey="captchaKey" @verify="captchaVerified"></vue-hcaptcha>

                      <p-button native-type="submit" slot="footer" type="warning" round block class="mb-3"
                                :disabled="isDisabled">{{ $t('registration.register') }}</p-button>


                    </form>
                  </ValidationObserver>
                </card>
              </div>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
        <div class="full-page-background" style="background-image: url(/static/img/background/jan-sendereks.jpg)"></div>
      </div>
    </div>
  </div>
</template>
<script>
  import AppNavbar from './AppNavbar'
  import AppFooter from './AppFooter'
  import { Card, Checkbox, Button, InfoSection } from 'src/components/UIComponents';
  import { extend } from "vee-validate";
  import { required, email, confirmed } from "vee-validate/dist/rules";
  import axios from 'axios';
  import Swal from 'sweetalert2';
  import VueHcaptcha from '@hcaptcha/vue-hcaptcha';

  extend("email", email);
  extend("required", required);
  extend("confirmed", confirmed);

  export default {
    components: {
      Card,
      AppNavbar,
      AppFooter,
      InfoSection,
      [Checkbox.name]: Checkbox,
      [Button.name]: Button,
      VueHcaptcha
    },
    data() {
      return {
        form: {
          firstName: '',
          lastName: '',
          username: '',
          email: '',
          password: '',
          confirmPassword: '',
          acceptPrivacy: false,
          acceptTerms: false,
          adult: false,
          newsletter: false,
          error: '',
          buttonDisabled: false
        },
        captchaKey: process.env.VUE_APP_HCAPTCHA_SITE_KEY,
        captchaToken: null
      }
    },
    methods: {
      captchaVerified  (token, eKey) {
        this.captchaToken = token;
      },
      register() {
        this.form.error = '';
        if (this.captchaToken === null)
        {
            this.form.error = "error.missing_captcha";
            return;
        }
        this.form.buttonDisabled = true;

        axios.post(process.env.VUE_APP_SERVER_BASE_URL + 'users', {
          email: this.form.email,
          password: this.form.password,
          username: this.form.username,
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          newsletterSubscribed: this.form.newsletter,
          captchaToken: this.captchaToken
        })
        .then(response => {
            this.form.password = "";
            this.form.confirmPassword = "";
            this.form.buttonDisabled = false;

            Swal.fire({
                icon: 'success',
                title: this.$i18n.t("registration.email_sent"),
                text: this.$i18n.t("registration.email_sent_message"),
                confirmButtonClass: "btn btn-info",
                buttonsStyling: false
            }).then((result) => {
                this.$router.push('/login');
            });
        }).catch(error => {
          this.form.buttonDisabled = false;
          this.form.error = "";
          if(error.response && error.response.data && error.response.data.violations && error.response.data.violations.length > 0) {
              for (let i = 0; i < error.response.data.violations.length; i++)
                  this.form.error += error.response.data.violations[i].message + ". ";
          }
          else
              this.form.error = "error.try_again";
          console.log(error);
        });

      },
      toggleNavbar() {
        document.body.classList.toggle('nav-open');
      },
      closeMenu() {
        document.body.classList.remove('nav-open');
        document.body.classList.remove('off-canvas-sidebar');
      }
    },
    computed: {
      isDisabled: function(){
        return this.form.buttonDisabled;
      }
    },
    beforeDestroy() {
      this.closeMenu()
    }
  }
</script>
<style>
</style>
