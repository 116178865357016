<template>
  <div>
    <h5 class="info-text"> {{ $t('listing.choose_category') }} </h5>
    <ValidationObserver ref="form">
      <form @submit.prevent="validate">
        <div class="row justify-content-center">
          <div class="col-lg-10">
            <div class="row">
              <div class="col-12 col-xl-6">
                <label>Category:</label>
                <ValidationProvider name="category" rules="required" v-slot="{ failed }">
                    <fg-input type="hidden" :error="failed ? $t('listing.field_required') : null" v-model="categoryID">
                    </fg-input>
                      <el-cascader class="cascader-primary d-block" v-model="category_path"
                        :placeholder="$t('common.search') + '..'"
                        :options="categories"
                        filterable
                        :props="{ checkStrictly: true }"
                        @change="handleChange">
                      </el-cascader>


                </ValidationProvider>
              </div>
            </div>
            <div v-if="saving" class="text-danger text-center"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.saving') }}..</div>
            <div v-if="form_error" class="alert text-danger text-center"><strong>{{ $t(form_error) }}</strong></div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
  import {IconCheckbox} from 'src/components/UIComponents';
  import axios from 'axios';
  import {Select, Option, Cascader } from 'element-ui';
  import { extend } from "vee-validate";
  import { required } from "vee-validate/dist/rules";

  extend("required", required);

  export default {
    components: {
      IconCheckbox,
      [Select.name]: Select,
      [Option.name]: Option,
      [Cascader.name]: Cascader
    },
    data() {
      return {
        categories: [],
        category_path: null,
        categoryID: null,
        formChanged: false,
        form_error: false,
        saving: false
      }
    },
    methods: {
      handleChange(value) {
        console.log("changed: " + JSON.stringify(value));
        this.formChanged = true;
        if(value !== undefined) {
          this.categoryID = value[value.length - 1];
        }
        else
          this.categoryID = null;
        // value is an array
      },
      validate() {
        return this.$refs.form.validate().then( res => {
          if (!res)
            return false;

          if (this.formChanged && this.categoryID !== null) {
            this.saving = true;
            return axios.patch(process.env.VUE_APP_SERVER_BASE_URL + 'listings/' + this.listingID,
                {"category": "/api/categories/" + this.categoryID},
                {headers: {'Content-Type': 'application/merge-patch+json'}})
            .then(response => {
              this.formChanged = false;
              console.log("category saved");
              this.saving = false;
              this.$emit('new-listing-saved', response.data);
              this.$emit('on-validated', true);
              return true;
            }).catch(error => {
              this.saving = false;
              if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                if (error.response.data.message) {
                  console.log(error.response.data.message);
                  this.$store.commit('logout');
                  this.$router.push('/login');
                } else
                  this.form_error = 'Error. Try again';
              } else if (error.response && error.response.data) {
                this.form_error = error.response.data.error;
              } else {
                this.form_error = 'Unknown error';
                console.log(error);
              }
              return false;
            });
          } else if (this.categoryID === null) {
            this.form_error = 'Please, select a category';
            return false;
          } else {
            this.$emit('on-validated', true);
            return true;
          }
        });
      },

      initializeStep() {
        if(this.category !== null) {
          let split = this.category["@id"].split("/");
          this.categoryID = parseInt(split[split.length - 1]);
          let pathAsString = this.category.path;
          pathAsString = pathAsString.substring(1, pathAsString.length -1);
          // +x is used to make a "cast" from string to number
          this.category_path = pathAsString.split(',').map(x=>+x);
        }
      }
    },
    beforeMount() {
      axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'categories/plainJson',
          {headers: {'X-LOCALE': this.$store.getters.getLang}})
      .then(response => {
        this.categories = response.data;
      }).catch(error => {
        if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.$store.commit('logout');
            this.$router.push('/login');
        } else {
          console.log(error);
        }
      });
    },
    props: {
      category: Object,
      listingID: String
    }
  }
</script>
<style>
</style>
