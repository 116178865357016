<template>
  <div class="row">
    <div class="col-12 my-5">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12 m-auto">
              <h4 class="text-center">Recharge your SETS credit!</h4>
              <p class="text-center">You have {{ userCreditSETS }} SETS</p>
              <p-button type="primary" class="m-auto d-block"
                        :disabled="saving || userCreditSETS >=50"
                        v-on:click="addCredits()">Please, give me more credits!</p-button>
              <br>
              <div v-if="saving" class="loading"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.saving') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">

</style>
<script>
  import axios from 'axios';

  export default {
    components: {
    },
    methods: {
      addCredits() {
        this.saving = true;
          axios.post(process.env.VUE_APP_SERVER_BASE_URL + 'tx_sets/add_user_credits', {})
            .then(response => {
              this.userCreditSETS = parseFloat(this.userCreditSETS) + parseFloat(response.data.amount);
              this.$store.commit('addUserCreditSETS', response.data.amount);
            }).catch(error => {
            if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                this.$store.commit('logout');
                this.$router.push('/login');
            }
            else {
                this.error = "error";
                console.log(error);
            }
          }).finally(() => {this.saving = false;});
      }
    },
    mounted: function () {
    },
    data() {
      return {
        userCreditSETS: this.$store.getters.getCreditSETS,
        loading: false,
        saving: false,
        error: null
      }
    }
  }

</script>
