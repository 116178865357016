<template>
  <div class="content">

    <transition name="fade" mode="out-in">

      <div class="row">
        <div v-if="loading" class="loading col-12"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.loading') }}</div>
        <div v-if="!loading && listings.length === 0" class="col-12 text-center">
          <h4>No listing available yet. Be the first! Become a pro and publish your listing!</h4>
        </div>

        <div class="col-12 col-md-6 col-lg-6 col-xl-4 m-auto" v-for="(listing, index) in listings">
          <card type="pricing">
            <h6 slot="header" class="card-category">{{ listing.title }}</h6>
            <badge type="success" v-if="listing.category">{{ listing.category.name }}</badge>

            <div class="card-icon icon-primary" style="height: 100px">
              <img v-if="listing.coverImage" :src="listing.coverImage.contentUrl" style="max-width: 100%; max-height: 100%"/>
              <i v-else-if="listing.category" :class="listing.category.icon"></i>
              <i v-else class="nc-icon nc-spaceship"></i>
            </div>
            <h3 class="card-title"><small style="font-size: 12px">Starting from</small> €{{ listing.price }}</h3>
            <p style="height: 100px">{{ listing.shortDescription }}</p>
            <p-button v-on:click="$router.push({path: '/listing/details/' + listing.id })"
                      type="primary" round slot="footer">{{ $t('common.see_more') }}</p-button>
          </card>
        </div>
      </div>

      <router-view></router-view>
    </transition>
  </div>
</template>


<script>
  import axios from 'axios';
  import { Badge } from 'src/components/UIComponents';

  export default {
    components: {
      [Badge.name]: Badge
    },
    data() {
      return {
        loading: true,
        listings: [],
        images_base_url: process.env.VUE_APP_IMAGES_BASE_URL,
      }
    },
    methods: {

    },
    beforeMount() {
      this.loading = true;
      axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'listings',
          {headers: {'X-LOCALE': this.$store.getters.getLang}})
      .then(response => {
        this.listings = response.data['hydra:member'];
        this.loading = false;
      }).catch(error => {
        this.loading = false;
        if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
          if (error.response.data.message) {
            console.log(error.response.data.message);
            this.$store.commit('logout');
            this.$router.push('/login');
          } else {
            // this.form_error = 'Error. Try again';
          }
        } else if (error.response && error.response.data) {
          // this.form_error = error.response.data.error;
        } else {
          // this.form_error = 'Unknown error';
          console.log(error);
        }
      });
    }
  }
</script>
<style>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .15s
  }

  .fade-enter,
  .fade-leave-to
    /* .fade-leave-active in <2.1.8 */

  {
    opacity: 0
  }
</style>
