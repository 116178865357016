<template>

  <div class="content">
    <transition name="fade" mode="out-in">
      <div class="content">

        <transition name="fade" mode="out-in">
          <div class="row justify-content-center">
            <div class="col-12 mr-auto ml-auto">
              <wizard @complete="wizardComplete">
                <template slot="header">
                  <h3 class="card-title"></h3>
                  <h3 class="description"></h3>
                </template>

                <wizard-tab :before-change="() => validateStep('step_1', 'step_image')">
                  <template slot="label">
                    <i class="nc-icon nc-alert-circle-i"></i>
                    <span class="wizard-label">{{ $t('listing.general_info') }}</span>
                  </template>
                  <first-step ref="step_1" v-bind:listingID="listingID" @on-validated="onStepValidated"
                              v-on:new-listing-saved="newListingData($event)"></first-step>
                </wizard-tab>

                <wizard-tab :before-change="() => validateStep('step_image', 'step_category')">
                  <template slot="label">
                    <i class="nc-icon nc-image"></i>
                    <span class="wizard-label">{{ $t('listing.images') }}</span>
                  </template>
                  <step-images ref="step_image"
                               v-bind:listingID="listingID"
                               v-bind:coverIRI="(listing !== null && listing.coverImage !== undefined) ? listing.coverImage.id : null"
                               @on-validated="onStepValidated"
                  ></step-images>
                </wizard-tab>

                <wizard-tab :before-change="() => validateStep('step_category', 'step_3')">
                  <template slot="label" >
                    <i class="nc-icon nc-book-bookmark"></i>
                    <span class="wizard-label">{{ $t('listing.category') }}</span>
                  </template>
                  <step-category ref="step_category"
                                 v-bind:listingID="listingID"
                                 v-bind:category="listing !== null && listing.category !== undefined ? listing.category : null"
                                 v-on:new-listing-saved="newListingData($event)"
                                 @on-validated="onStepValidated"></step-category>
                </wizard-tab>

                <wizard-tab :before-change="() => validateStep('step_3', 'step_payment')">
                  <template slot="label">
                    <i class="nc-icon nc-tag-content"></i>
                    <span class="wizard-label">{{ $t('listing.price') }}</span>
                  </template>
                  <third-step ref="step_3" v-bind:listingID="listingID"
                              v-bind:listingPackages="listing !== null ? listing.listingPackages : []"
                              v-on:new-listing-packages="newListingPackages($event)"
                              @on-validated="onStepValidated"></third-step>
                </wizard-tab>

                <wizard-tab :before-change="() => validateStep('step_payment', '')">
                  <template slot="label">
                    <i class="nc-icon nc-money-coins"></i>
                    <span class="wizard-label">Payment Methods</span>
                  </template>
                  <step-payment-methods ref="step_payment"
                                        v-bind:listingID="listingID"
                                        v-bind:listingData="listing"
                                        @on-validated="onStepValidated"></step-payment-methods>
                </wizard-tab>
              </wizard>
            </div>
          </div>

          <router-view></router-view>
        </transition>
      </div>

      <router-view></router-view>
    </transition>
  </div>

</template>
<script>

  import {Wizard, WizardTab} from 'src/components/UIComponents';
  import { extend } from "vee-validate";
  import { required } from "vee-validate/dist/rules";
  import FirstStep from './NewListingStep1';
  import StepImages from './NewListingStepImages';
  import StepCategory from './NewListingStepCategory';
  import ThirdStep from './NewListingStep3';
  import StepPaymentMethods from './NewListingStepPaymentMethods';
  import Swal from 'sweetalert2';
  import axios from 'axios';

  extend("required", required);

  export default {
    components: {
      Wizard,
      WizardTab,
      FirstStep,
      StepImages,
      StepCategory,
      ThirdStep,
      StepPaymentMethods
    },
    data() {
      return {
        listing: null,
        listingID: null
      }
    },
    methods: {
      // toggleSidebar () {
      //   if (this.$sidebar.showSidebar) {
      //     this.$sidebar.displaySidebar(false)
      //   }
      // },
      validateStep(ref, refSucc) {
        let validRes = this.$refs[ref].validate();
        if(validRes && refSucc !== '')
          this.$refs[refSucc].initializeStep();
        return validRes;
      },
      onStepValidated(validated, model) {

      },
      wizardComplete() {
        Swal.fire({
          title: this.$i18n.t('listing.good_job'),
          text: this.$i18n.t('listing.listing_ready'),
          confirmButtonClass: "btn btn-success",
          type: "success",
          buttonsStyling: false
        })
        .then((result) => {
          // this.$store.commit('setListing', null);
          this.$router.push('/professional/my_listings');
        })
      },
      newListingData(event)
      {
        this.listing = event;
        if (this.listingID === null)
        {
          let split = this.listing['@id'].split("/");
          this.listingID = split[split.length-1];
        }
      },
      newListingPackages(event)
      {
        this.listing.listingPackages = [];
        for(let i=0; i<event.length; i++)
        {
          this.listing.listingPackages.push(event[i].iri);
        }
      }
    },
    beforeMount() {
      var fetchedId = this.$route.query.id;
      console.log("Fetched ID: "+ fetchedId);
      if(fetchedId !== undefined)
      {
        let split = fetchedId.split("/");
        fetchedId = split[split.length-1];
        this.listingID = fetchedId;
        // Get The listing data
        axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'listings/' + fetchedId)
        .then(response => {
          this.listing = response.data;
        }).catch(error => {
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.$store.commit('logout');
            this.$router.push('/login');
          } else {
            // this.form_error = 'listing.err_listing_data_not_retrieved';
            console.log(error);
          }
        });
      }
    }
  }
</script>
<style>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .15s
  }

  .fade-enter,
  .fade-leave-to
    /* .fade-leave-active in <2.1.8 */

  {
    opacity: 0
  }

  .wizard-label {
    display: inline-block;
  }

  @media only screen and (max-width: 600px) {
    .wizard-label {
      display: none;
    }
  }
</style>
