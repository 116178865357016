<template>
  <div :class="{'nav-open': $sidebar.showSidebar}">
    <notifications transition-name="notification-list" transition-mode="out-in">

    </notifications>
    <router-view name="header"></router-view>
    <transition name="fade"
                mode="out-in">
      <router-view></router-view>
    </transition>
    <router-view name="footer"></router-view>
  </div>
</template>

<script>
  // Loading some plugin css asynchronously
  import 'sweetalert2/dist/sweetalert2.css'
  import 'vue-notifyjs/themes/default.css'
  export default {
    data() {
      return {

      }
    },
      mounted() {
          let cookieBotScript = document.createElement('script');
          cookieBotScript.setAttribute('src', "https://consent.cookiebot.com/uc.js");
          cookieBotScript.id = "Cookiebot";
          cookieBotScript.type = "text/javascript";
          cookieBotScript.setAttribute("data-cbid", "ecbd3140-549b-4f40-bc19-b83780ab0096");
          cookieBotScript.setAttribute("data-blockingmode", "auto");
          document.head.appendChild(cookieBotScript);
    }
  }
</script>
<style lang="scss">

</style>
