var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-page"},[_c('app-navbar'),_c('div',{staticClass:"wrapper wrapper-full-page"},[_c('div',{staticClass:"full-page login-page section-image"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"col-lg-4 col-md-6 ml-auto mr-auto"},[_c('card',{attrs:{"type":"login"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.login)}}},[_c('h3',{staticClass:"header text-center",attrs:{"slot":"header"},slot:"header"},[_vm._v(_vm._s(_vm.$t('login.login')))]),(_vm.form.error)?_c('div',{staticClass:"alert text-danger"},[_c('strong',[_vm._v(_vm._s(_vm.$t(_vm.form.error)))])]):_vm._e(),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"email","addon-left-icon":"nc-icon nc-single-02","error":failed ? _vm.$t('common.field_required') : null,"hasSuccess":passed,"name":"email","placeholder":"E-mail","autocomplete":"email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"password","addon-left-icon":"nc-icon nc-key-25","placeholder":"Password","error":failed ? _vm.$t('common.field_required') : null,"hasSuccess":passed,"autocomplete":"current-password","name":"password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_c('p-button',{staticClass:"mb-3",attrs:{"slot":"footer","native-type":"submit","type":"warning","round":"","block":"","disabled":_vm.isDisabled},slot:"footer"},[_vm._v(_vm._s(_vm.$t('login.login')))])],1)]}}])}),_c('br'),_c('p-button',{staticClass:"btn-link btn-sm m-auto",staticStyle:{"font-size":"12px"},attrs:{"type":"link"},on:{"click":_vm.resetPassword}},[_vm._v("Reset Password")])],1)],1)])]),_c('app-footer'),_c('div',{staticClass:"full-page-background",staticStyle:{"background-image":"url(/static/img/background/background-2.jpg)"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }