<template>
  <form @submit.prevent="validate">

    <div class="row">
      <div v-if="loading" class="loading col-12"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.loading') }}...</div>

      <div class="col-12 text-center mb-5" v-if="packages.length < 5">
        <el-button @click="addPackage" class="mt-5" type="primary">{{ $t('listing.add_package') }}</el-button>
      </div>

      <div class="col-12 text-center">
        <p class="text-danger"><b>Please note that this is a demo. You will not be paid for your service in this moment.</b></p>
      </div>

      <div class="col-12 col-md-10 col-xl-8 m-auto">
        <tabs centered v-model="activeTab">
          <div v-if="packages.length === 0" class="text-center" style="width: 100%">No packages</div>
          <tab-pane :title="pack.name" v-for="(pack, index) in packages" :key="pack.id">
            <ValidationObserver ref="validation_package">
              <div class="row my-4">
                <div class="col-4">
                  <ValidationProvider name="package_name" rules="required" v-slot="{ failed }">
                    <label>Package name:</label><br>
                    <el-select v-model="pack.name">
                      <el-option v-for="item in packageNames" :key="item" :label="item" :value="item">
                      </el-option>
                    </el-select>
                  </ValidationProvider>
                </div>

                <div class="col-4">
                  <ValidationProvider name="currency" rules="required" v-slot="{ failed }">
                    <fg-input :error="failed ? 'This field is required': null">
                      <label>Price currency:</label><br>
                      <el-select v-model="pack.currency" name="currency"
                                 :placeholder="$t('listing.select_currency') + '..'">
                        <el-option v-for="item in currencies" :key="item['@id']" :label="item.symbol" :value="item['@id']">
                        </el-option>
                      </el-select>
                    </fg-input>
                  </ValidationProvider>
                </div>
                <div class="col-4">
                  <ValidationProvider name="package_price" rules="required|price|max:10000" v-slot="{ passed, failed }">
                    <label>Price:</label>
                    <fg-input name="package_price" :placeholder="$t('listing.price')"
                              v-on:change="fixPrice(index)"
                              :error="failed ? $t('listing.required_and_in_range_value', {min: 0.01, max: 10000}) : null"
                              :hasSuccess="passed" v-model="pack.price">
                    </fg-input>

                  </ValidationProvider>
                </div>
              </div>

              <div class="row my-4" v-for="(desc, transIndex) in pack.jsonTextTranslations">
                <div class="col-2 col-sm-1" v-if="transIndex > 0 || pack.jsonTextTranslations.length > 1">
                  <el-button @click="deleteTranslation(index, transIndex)" class="btn-danger mt-1">
                    <i class="nc-icon nc-simple-remove"></i>
                  </el-button>
                </div>

                <div class="col-6 col-sm-4">
                  <fg-input>
                    <el-select class="select-info" size="small" v-model="desc.lang">
                      <el-option v-for="option in languages"
                                  :value="option"
                                 :label="option.toUpperCase()" :key="option">
                      </el-option>
                    </el-select>
                  </fg-input>
                </div>

                <div v-if="transIndex === pack.jsonTextTranslations.length -1" class="col-4 ml-auto text-right">
                  <el-button @click="addLanguage(index)">{{ $t('listing.add_language') }}</el-button>
                </div>

                <div class="col-12 m-auto">
                  <ValidationProvider name="package_desc" rules="required|max:255" v-slot="{ passed, failed }">
                    <textarea class="form-control" rows="6" name="package_desc"
                              style="max-height: 500px; resize: none;" v-model="desc.description"
                              :placeholder="$t('listing.desc')">
                    </textarea>
                    <span :class="desc.description.length > 0 && desc.description.length <= 255  ? 'text-success' : 'text-danger'">
                      {{ desc.description.length }} / 255 characters
                    </span>

                    <fg-input type="hidden" name="package_desc" v-model="desc.description"
                              :error="failed ? $t('listing.required_and_in_range_char', {min: 1, max: 255}) : null"
                              :hasSuccess="passed">
                    </fg-input>
                  </ValidationProvider>
                </div>
              </div>


              <div class="row my-4">
                <div class="col-12">
                  <div class="row">
                    <div class="col-12">
                      <el-checkbox class="" v-model="pack.delivery.active"> Delivery Time: </el-checkbox>
                    </div>

                    <div class="col-4 pl-5">
                      <el-select v-model="pack.delivery.unit" class="" :disabled="!pack.delivery.active" style="width: 100%">
                        <el-option value="hour">Hour</el-option>
                        <el-option value="day">Day</el-option>
                        <el-option value="month">Month</el-option>
                        <el-option value="year">Year</el-option>
                      </el-select>

                    </div>
                    <div class="col-4">
                      <ValidationProvider name="package_desc" rules="numeric" v-slot="{ passed, failed }">
                        <fg-input name="deliveryTime" v-model="pack.delivery.time" class=""
                                  :disabled="!pack.delivery.active"
                                  :error="failed ? 'This field must be a number' : null"
                                  :hasSuccess="passed">
                        </fg-input>
                      </ValidationProvider>
                    </div>
                    <div class="col-4">
                      <el-tooltip content="Use a Smart Contract to check and verify delivery Time">
                        <el-checkbox class="" v-model="pack.delivery.useSC"
                                     :disabled="!pack.delivery.active">
                          Smart Contracts *
                        </el-checkbox>
                      </el-tooltip>
                    </div>

                    <div class="col-12 mt-4">
                      <el-checkbox class="" v-model="pack.revision.active"> Max number of product revisions: </el-checkbox>
                    </div>
                    <div class="col-8 pl-5">
                      <ValidationProvider name="package_desc" rules="numeric" v-slot="{ passed, failed }">
                        <fg-input name="deliveryTime" v-model="pack.revision.number" class=""
                                  :disabled="!pack.revision.active"
                                  :error="failed ? 'This field must be a number' : null"
                                  :hasSuccess="passed">
                        </fg-input>
                      </ValidationProvider>
                    </div>
                    <div class="col-4">
                      <el-tooltip content="Use a Smart Contract to track Revisions">
                        <el-checkbox class="" v-model="pack.revision.useSC"
                                     :disabled="!pack.revision.active">
                          Smart Contracts *
                        </el-checkbox>
                      </el-tooltip>
                    </div>

                    <div class="col-12 mt-4">
                      <el-checkbox class="m-auto" v-model="pack.auditors.active"> Allow External Expert Auditors: </el-checkbox>
                    </div>

                    <div class="col-4 pl-5">
                      <ValidationProvider name="package_desc" rules="numeric" v-slot="{ passed, failed }">
                        <fg-input name="numAuditors" v-model="pack.auditors.number" class=""
                                  placeholder="Max Number of Auditors"
                                  :disabled="!pack.auditors.active"
                                  :error="failed ? 'This field must be a number' : null"
                                  :hasSuccess="passed">
                        </fg-input>
                      </ValidationProvider>
                    </div>

                    <div class="col-4">
                      <label>Percentage paid by you:</label>
                      <el-slider class="slider-info" :max="100"
                                 v-model="pack.auditors.percentage" :disabled="!pack.auditors.active">
                      </el-slider>
                    </div>

                    <div class="col-4 m-auto">
                      <el-tooltip content="Use a Smart Contract to track and certify reviews of Auditors">
                        <el-checkbox class="m-auto" v-model="pack.auditors.useSC"
                                     :disabled="!pack.auditors.active">
                          Smart Contracts *
                        </el-checkbox>
                      </el-tooltip>

                    </div>
                  </div>

                </div>
              </div>


              <div class="row">
                <div class="col-12 text-center">
                  <p class="description" style="font-size: 12px">(*) Please remember that all the transactions made on the blockchain cannot be reverted deleted.</p>
                </div>
              </div>


              <div class="row my-3">
                <div class="col-6 text-center">
                  <el-button type="primary" plain class="mt-5" @click="savePackage(index)" :loading="pack.saving" :disabled="saving">
                    {{ $t('common.save') }}
                  </el-button>
                </div>
                <div class="col-6 text-center">
                  <el-button type="danger" plain class="mt-5" @click="deletePackage(index)" :loading="pack.saving" :disabled="saving">
                    {{ $t('common.delete') }}
                  </el-button>
                </div>

                <div v-if="saving" class="col-12 text-danger text-center"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.saving') }}...</div>
                <div class="col-12 text-danger" v-if="pack.error">{{ $t('err_saving') }}</div>

              </div>
            </ValidationObserver>
          </tab-pane>
        </tabs>
      </div>


      <div v-if="error" class="col-12 alert text-danger text-center"><strong>{{ $t(error) }}</strong></div>
    </div>

  </form>

</template>
<script>

  import { IconCheckbox, Tabs, TabPane } from 'src/components/UIComponents';
  import { Select, Option, Button, Slider, Message, Checkbox, Tooltip } from 'element-ui';
  import { extend } from "vee-validate";
  import { required, required_if, min, max, numeric } from "vee-validate/dist/rules";
  import axios from 'axios';

  extend("required", required);
  extend("required_if", required_if);
  extend("min", min);
  extend("max", max);
  extend("numeric", numeric);
  extend('positive', value => {
    return value > 0;
  });
  extend('price', value => {
    return value >= 0.01;
  });

  export default {
    components: {
      [Slider.name]: Slider,
      IconCheckbox,
      [Select.name]: Select,
      [Option.name]: Option,
      [Button.name]: Button,
      [Checkbox.name]: Checkbox,
      [Tooltip.name]: Tooltip,
      Tabs,
      TabPane
    },
    data() {
      return {
        languages: JSON.parse(process.env.VUE_APP_LISTING_SUPPORTED_LANGUAGES),
        packageNames: ["Basic", "Standard", "Premium", "Superior", "Full"],
        // packageNamesUsed: [],
        currencies: [],
        saving: false,
        loading: true,
        error: null,
        price: 0.0,
        isPublished: true,
        packages: [],
          //   [{ id:null, name: null, currency: null, price: null, listing: null, saving: false, error: false,
          // jsonTextTranslations: [{lang: 'en', description: null}] }]
        activeTab: null
      }
    },

    methods: {
      fixPrice(index) {
        this.packages[index].price = parseFloat(this.packages[index].price).toFixed(2);
        this.error = null;
      },
      addPackage() {
        this.packages.push({id: null, iri: null, name: this.packageNames[0], currency: null, price: null,
          listing: '/api/listings/' + this.listingID,
          jsonTextTranslations: [{lang: 'en', description: ''}],
          delivery: {active: false, unit: 'hour', time: 0, useSC: false},
          revision: {active: false, number: 0, useSC: false},
          auditors: {active: false, number: 0, percentage: 50, useSC: false}
        });
        this.error = null;
      },
      addLanguage(index) {
        this.packages[index].jsonTextTranslations.push({lang: 'en', description: ''});
      },
      deleteTranslation(packIndex, transIndex) {
        if(this.packages[packIndex].jsonTextTranslations.length > 1)
          this.packages[packIndex].jsonTextTranslations.splice(transIndex, 1);

      },
      deletePackage(index) {
        this.saving = true;
        if(this.packages[index].id === null)
        {
          // package not already saved on db
          this.packages.splice(index, 1);
          this.saving = false;
          Message.success("Package successfully deleted");
        }
        else
        {
          axios.delete(process.env.VUE_APP_SERVER_BASE_URL + 'listing_packages/' + this.packages[index].id)
          .then(response => {
            console.log("packages deleted");
            this.packages.splice(index, 1);
            this.saving = false;
            Message.success("Package successfully deleted");
          }).catch(error => {
            if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
              this.$store.commit('logout');
              this.$router.push('/login');
            } else {
              console.log(error);
            }
            this.saving = false;
            Message.error("An error occurred while deleting package. Please try again.");
          });
        }
      },
      async savePackage(index) {
        let res = await this.$refs.validation_package[index].validate();
        if (!res)
            return;

        this.error = null;
        this.saving = true;

        let data = {
          name: this.packages[index].name,
          currency: this.packages[index].currency,
          price: parseFloat(this.packages[index].price),
          listing: '/api/listings/' + this.listingID,
          jsonTextTranslations: this.packages[index].jsonTextTranslations,
          deliveryTime: this.packages[index].delivery.active ? parseInt(this.packages[index].delivery.time) : 0,
          deliveryTimeUnit: this.packages[index].delivery.active ? this.packages[index].delivery.unit : null,
          deliveryTimeSC: this.packages[index].delivery.active ? this.packages[index].delivery.useSC : false,
          revisionsMax: this.packages[index].revision.active ? parseInt(this.packages[index].revision.number) : 0,
          revisionsSC: this.packages[index].revision.active ? this.packages[index].revision.useSC : false,
          auditorsMax: this.packages[index].auditors.active ? parseInt(this.packages[index].auditors.number) : 0,
          auditorsSC: this.packages[index].auditors.active ? this.packages[index].auditors.useSC : false,
          auditorsDuePercentage: this.packages[index].auditors.active ? this.packages[index].auditors.percentage : 0,
        };

        let config = {};
        if (this.packages[index].id !== null)
        {
          config = {
            method: 'patch',
            url: process.env.VUE_APP_SERVER_BASE_URL + 'listing_packages/' + this.packages[index].id,
            headers: {'Content-Type': 'application/merge-patch+json'},
            data: data
          };
        }
        else
        {
          config = {
            method: 'post',
            url: process.env.VUE_APP_SERVER_BASE_URL + 'listing_packages',
            data: data
          };
        }

        axios(config).then(response => {
          if(this.packages[index].id === null)
          {
            this.packages[index].iri = response.data['@id'];
            this.packages[index].id = response.data.id;
          }
          this.saving = false;
          Message.success("Package saved");
        }).catch(error => {
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.$store.commit('logout');
            this.$router.push('/login');
          } else {
            console.log(error);
          }
          this.saving = false;
          Message.error("An error occurred while saving package. Please try again.");
        });
      },
      async validate() {
        for (let i=0; i<this.packages.length; i++) {
            let res = await this.$refs.validation_package[i].validate();
            if (!res)
            {
                this.error = 'Missing required fields in package ' + this.packages[i].name;
                return false;
            }
        }

        if(this.packages.length === 0)
        {
          this.error = 'Add at least one package.';
          return false;
        }
        else {
          this.$emit('new-listing-packages', this.packages);
          this.$emit("on-validated", true);
          return true;
        }
      },
      initializeStep() {
        this.loading = true;
        if(this.listingID !== null && this.listingPackages.length > 0 && this.packages.length === 0)
        {
          // if(listing.price != null)
          // {
          //   this.price = listing.price;
          //   if(listing.status === 'online')
          //     this.isPublished = true;
          //
          // }

          this.packages = [];
          for (let i = 0; i< this.listingPackages.length; i++) {
            let split = this.listingPackages[i].split('/');
            let id = split[split.length -1];
            axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'listing_packages/' + id)
            .then(res => {
              let pack = res.data;
              pack.id = id;
              // Used to avoid package marked as "deleted"
              if(pack.status === 'new') {
                  axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'listing_packages/' + pack.id + '/all_lang')
                      .then(response => {
                          let item = {};
                          item.name = pack.name;
                          item.id = pack.id;
                          item.iri = pack.iri;
                          item.currency = pack.currency['@id'];
                          item.price = pack.price;
                          item.saving = false;
                          item.error = false;
                          if (pack.deliveryTime && pack.deliveryTime > 0)
                              item.delivery = {
                                  active: true,
                                  unit: pack.deliveryTimeUnit,
                                  time: pack.deliveryTime,
                                  useSC: pack.deliveryTimeSC
                              };
                          else
                              item.delivery = {active: false, unit: 'hour', time: 0, useSC: false};

                          if (pack.revisionsMax && pack.revisionsMax > 0)
                              item.revision = {active: true, number: pack.revisionsMax, useSC: pack.revisionsSC};
                          else
                              item.revision = {active: false, number: 0, useSC: false};

                          if (pack.auditorsMax && pack.auditorsMax > 0)
                              item.auditors = {
                                  active: true,
                                  number: pack.auditorsMax,
                                  percentage: pack.auditorsDuePercentage,
                                  useSC: pack.auditorsSC
                              };
                          else
                              item.auditors = {active: false, number: 0, percentage: 50, useSC: false};
                          let trans = [];
                          for (let lang in response.data)
                              trans.push({lang: lang, description: response.data[lang].description});
                          item.jsonTextTranslations = trans;
                          this.packages.push(item);
                          // this.handleEndOfLoading(this.listingPackages.length);
                      }).catch(error => {
                      console.log(error);
                  });
              }
            }).catch(error => {
              console.log(error);
            }).finally(() => {
                this.loading = false;
                if(this.packages.length > 0)
                  this.activeTab = this.packages[0].name;
            });
          }

          if(this.packages.length > 0)
            this.activeTab = this.packages[0].name;

        }
        else
          this.loading = false;
      },
      handleEndOfLoading(totalItems) {
          //TODO: non va più bene, perchè ci possono essere dei listing in status = deleted
        if(this.packages.length === totalItems)
        {
          this.loading = false;
          this.activeTab = this.packages[0].name;
        }
      }
    },
    beforeMount() {
      axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'currencies')
      .then(response => {
        this.currencies = response.data['hydra:member'];
      }).catch(error => {
        this.loading = false;
        if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
          this.$store.commit('logout');
          this.$router.push('/login');
        } else {
          console.log(error);
        }
      });
    },
    props: {
      listingPackages: Array,
      listingID: String
    }
  }
</script>
<style>
</style>
