import {isUndefined} from "element-ui/src/utils/types";

export const Role = {
  Admin: 'ROLE_ADMIN',
  Manager: 'ROLE_MANAGER',
  Agent: 'ROLE_AGENT',
  User: "ROLE_USER",
  ProfessionalWrite: 'ROLE_PRO_WRITE',
  ProfessionalRead: 'ROLE_PRO_READ',
  AuditorWrite: 'ROLE_AUDITOR_WRITE',
  AuditorRead: 'ROLE_AUDITOR_READ'
};


// export function mapRoles(serverRoles) {
//   console.log("serverRoles: " + serverRoles);
//   let roles = [];
//   for (const serverR of serverRoles)
//   {
//     let key = Object.keys(Role).find(key => Role[key] === serverR);
//
//     if (key !== undefined)
//       roles.push(Role[key]);
//     else
//       console.log("role not found: " + serverR);
//   }
//   return roles;
// }


// export function mapRoles(serverRoles) {
//   console.log("serverRoles: " + serverRoles);
//   let roles = [];
//   for (const serverR of serverRoles)
//   {
//     let keys = [];
//     Object.keys(Role).forEach(keyFirstLevel => {
//       let key = undefined;
//       let subkey = undefined;
//       if (typeof Role[keyFirstLevel] === 'object')
//       {
//         subkey = Object.keys(Role[keyFirstLevel]).find(key => Role[keyFirstLevel][key] === serverR);
//         console.log("subkey: " + subkey);
//         if (subkey !== undefined)
//           key = keyFirstLevel;
//       }
//       else
//         key = Object.keys(Role).find(key => Role[key] === serverR);
//
//       if (key !== undefined && !keys.includes(key))
//         keys.push(key);
//       if (subkey !== undefined)
//         keys.push(subkey);
//     });
//
//     console.log("keys: " + keys);
//     if (keys[1] !== undefined)
//       roles.push(Role[keys[0]][Role[keys[1]]]);
//     else if (keys[0] !== undefined)
//       roles.push(Role[keys[0]]);
//     else
//       console.log("role not found: " + serverR);
//   }
//   return roles;
// }
