<template>
  <div class="login-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 col-md-8 col-12 ml-auto mr-auto">

                <card centered class="text-center p-5">
                  <h4 class="card-title text-primary">{{ $t('registration.account_deletion') }}</h4>
                  <div v-if="loading">
                    <p class="title" style="color: black;"><i class="fa fa-spinner fa-spin"></i> {{ $t("registration.check_progress") }} ...</p>
                  </div>
                  <div v-else-if="declined">
                    <i class="fa fa-check text-success fa-3x"></i><br>
                    {{ $t('registration.account_deleted') }}
                    <br><br>
                    <router-link to="/">
                      <p-button type="success">{{ $t('common.home') }}</p-button>
                    </router-link>
                  </div>
                  <div v-else>{{ $t(error) }}</div>
                </card>
              </div>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
        <div class="full-page-background" style="background-image: url(/static/img/background/background-2.jpg)"></div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Card, Button } from 'src/components/UIComponents';
  import AppNavbar from './AppNavbar'
  import AppFooter from './AppFooter'
  import axios from 'axios';
  import PButton from "../../UIComponents/Button";


  export default {
    components: {
      PButton,
      Card,
      AppNavbar,
      AppFooter,
      [Button.name]: Button
    },
    methods: {
      toggleNavbar() {
        document.body.classList.toggle('nav-open')
      },
      closeMenu() {
        document.body.classList.remove('nav-open');
        document.body.classList.remove('off-canvas-sidebar');
      }
    },
    data() {
      return {
        loading: true,
        declined: false,
        error: ""
      }
    },
    beforeDestroy() {
      this.closeMenu()
    },
    beforeMount() {
      let fetchedId = this.$route.params.userId;
      let fetchedRegistrationString = this.$route.params.registrationString;

      let config = {
        method: 'get',
        url: process.env.VUE_APP_SERVER_BASE_URL + 'decline_registration/' + fetchedId + "/" + fetchedRegistrationString,
        withCredentials: false
      };

      axios(config).then(response => {
        this.loading = false;
        this.declined = true;

      }).catch(error => {
        this.loading = false;
        if(error.response.data.message)
          this.error = error.response.data.message;
        else
            this.error = "err_try_again";
      });

    }
  }
</script>
<style>
  .loginBackground {
    /*background: url('./public/static/img/background.jpg');*/
  }
</style>

