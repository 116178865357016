import Vue from 'vue'
import './pollyfills'
import VueRouter from 'vue-router'
import VueRouterPrefetch from 'vue-router-prefetch'
import VueNotify from 'vue-notifyjs'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import App from './App.vue'
import axiosPlugin from "./axiosPlugin"

// Plugins
import GlobalComponents from './globalComponents'
import GlobalDirectives from './globalDirectives'
import SideBar from './components/UIComponents/SidebarPlugin'
import initProgress from './progressbar';

// router setup
import routes from './routes/routes'

// library imports
import './assets/sass/paper-dashboard.scss'
import './assets/sass/demo.scss'

import sidebarLinks from './sidebarLinks'
// import './registerServiceWorker'

// plugin setup
Vue.use(axiosPlugin);
Vue.use(VueRouter);
Vue.use(VueRouterPrefetch);
Vue.use(GlobalDirectives);
Vue.use(GlobalComponents);
Vue.use(VueNotify);


Vue.use(SideBar, {sidebarLinks: sidebarLinks});
locale.use(lang);


// ------------------- Font icons -------------------
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faPhoneAlt);

Vue.component('font-awesome-icon', FontAwesomeIcon);
// ------------------- Font icons -------------------

// configure router
const router = new VueRouter({
  mode: 'history',
  routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior: (to) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  }
});

import store from './store'
import axios from "axios";
import i18n from './i18n'


router.beforeEach(async (to, from, next) => {
  // in this way, extract the variable requireRole from to.meta, not all the attributes of to.meta!
  const { requiresRole } = to.meta;
  let loggedUser = store.getters.isLoggedIn;

  // name of the routes to avoid if the user is already logged in
  let listSpecialPaths = ["Register", "Login", "TwoFA", "confirm_registration", "decline_registration", "restore_account"];

  if(!to.meta.requiresAuth && !listSpecialPaths.includes(to.name))
    return next();

  if(!loggedUser) {
    await axios.post(process.env.VUE_APP_SERVER_BASE_URL + 'me', {})
    .then(response => {

      store.commit('auth_success', response.data.user);
      store.commit('setCurrencies', response.data.currencies);
      loggedUser = true;
    }).catch(error => {});
  }

  if (!loggedUser && !to.meta.requiresAuth)
    return next();

  // redirect to dashboard if the user is logged in and is trying to access to a "special path".
  if(listSpecialPaths.includes(to.name) && loggedUser)
    return next('/customer/dashboard');

  // redirect to login page if not logged in and trying to access a restricted page
  if (!loggedUser && to.meta.requiresAuth)
    return next({ path: '/login', query: { redirect: to.path } });

  // if we need an array of roles to check, use this:
  // let checker = (arr, target) => target.every(v => arr.includes(v));

  // check if route is restricted by role
  if(!requiresRole || store.getters.hasRole(requiresRole))
    return next(); // role authorized

  return next('/customer/dashboard');
});





initProgress(router);


/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  i18n,
  store
});


