<template>
  <div class="content">

    <div class="row">
      <div class="col-12">

        <div class="card">
          <div class="card-header">
            <h3 v-if="listingData">{{ listingData.listingTitle }}</h3>

            <div class="row">
              <div v-if="loading" class="loading col-12"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.loading') }}</div>
            </div>
          </div>


          <div class="card-body" v-if="listingData">
            <div class="row">
              <div class="col-6 col-md-4 col-xl-3">
                <img :src="listingData.coverImageUrl"
                     v-if="listingData.coverImageUrl"
                     width="200px" height="auto" class="m-auto d-block"/>
              </div>

              <div class="col-6 col-md-8 col-xl-9">
                <p class="m-2">{{ listingData.packageName }} - {{ listingData.currency }} {{ listingData.packagePrice }}</p>
                <p class="m-2"><i class="fa fa-star text-warning"></i> 4.5 (130 reviews)</p>
              </div>

            </div>
            <div class="row mt-4">
              <div class="col-12">
                <p style="white-space: pre-line">{{ listingData.listingDescription }}</p>
              </div>
            </div>

          </div>

        </div>


      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
          </div>
          <div class="card-body">

            <div class="row" v-if="activity && (activity.status === 'new' || activity.status === 'workInProgress')">
              <div class="col-12 text-center">
                <p class="mt-2">The work is not ready. Let's wait for the professional.</p>
              </div>
            </div>


            <div class="row">
              <div class="col-6 text-center">
                <i class="nc-icon nc-delivery-fast align-middle" style="font-size: 28px"></i> Delivered in:
                <span v-if="listingData">
                  <b v-if="delivered_in.minutes !== null">
                    <span v-if="delivered_in.days > 0">{{ delivered_in.days }} days, </span>
                    <span v-if="delivered_in.days > 0 || delivered_in.hours > 0">{{ delivered_in.hours }} hours, </span>
                    <span v-if="delivered_in.days > 0 || delivered_in.hours > 0 || delivered_in.minutes > 0">{{ delivered_in.minutes }} minutes.</span>
                    <span v-else> < 1 minute</span>
                  </b>
                  <b v-else>-</b>
                  ({{ listingData.deliveryTime }} {{listingData.deliveryTimeUnit}})
                </span>
                <span v-else> - </span>
              </div>
              <div class="col-6 text-center">
                <i class="nc-icon nc-refresh-69 align-middle" style="font-size: 28px"></i> Revision n.
                <span v-if="listingData">
                  <b> {{ activity.revisionNumber }}</b>
                  (Max {{ listingData.revisionsMax }})
                </span>
                <span v-else> - </span>
              </div>
            </div>



            <div class="mt-3" v-if="audit && audit.status === 'new'">
              <div class="row">
                <div class="col-6 text-center">
<!--                  <ValidationObserver v-slot="{ handleSubmit }">-->
<!--                    <form @submit.prevent="handleSubmit(accept)">-->
<!--                      <p-button native-type="submit" class="btn-success m-3"-->
<!--                                :disabled="saving || loading">-->
<!--                        <i class="nc-icon nc-check-2"></i> Accept to review!</p-button>-->
<!--                    </form>-->
<!--                  </ValidationObserver>-->
                  <p-button class="btn-success m-3"
                            :disabled="saving || loading" @click.native="showAcceptModal = true">
                    <i class="nc-icon nc-check-2"></i> Accept to review!</p-button>

                  <modal :show.sync="showAcceptModal" headerClasses="justify-content-center">
                    <h4 slot="header" class="title title-up">Accept to review</h4>
                    <div class="row">
                      <div class="col-12">
                        <ValidationObserver v-slot="{ handleSubmit }">
                          <form @submit.prevent="handleSubmit(accept)">
                            <p>The reviews needs to be propagate on the Blockchain. You can do this by your own, or you can let us handle this for you!</p>

                            <p>Do you want to propagate yourself the result on the Blockchain?</p>

                            <ValidationProvider name="who_propagate" v-slot="{ passed, failed }" rules="required">
                              <p-radio v-model="propagatePlatform" label="true" class="text-left" value="true">No please, do it for me</p-radio>
                              <p-radio v-model="propagatePlatform" label="false" class="text-left" value="false">Yes, I'll do it</p-radio>
                            </ValidationProvider>


                            <ValidationProvider name="auditor_wallet" :rules="{required_if: {target: 'who_propagate', values: ['false']}}" v-slot="{ passed, failed }">
                              <fg-input name="auditor_wallet" v-model="auditorWallet" :disabled="propagatePlatform !== 'false'" placeholder="Your wallet"
                                        :error="failed ? $t('common.field_required') : null"
                                        :hasSuccess="passed"></fg-input>
                            </ValidationProvider>

                            <p-button native-type="submit" class="btn-success m-3" :disabled="saving">
                              <i class="nc-icon nc-check-2"></i> Accept to review!</p-button>
                          </form>
                        </ValidationObserver>
                      </div>
                    </div>
                  </modal>


                </div>
                <div class="col-6 text-center">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(reject)">
                      <p-button native-type="submit" class="btn-danger m-3"
                                :disabled="saving || loading">
                        <i class="nc-icon nc-simple-remove"></i> Refuse to review!</p-button>
                    </form>
                  </ValidationObserver>
                </div>
              </div>
            </div>



            <div class="row mt-3" v-if="audit && audit.status === 'accepted' && activity">
              <div v-if="activity.status === 'waitingAuditors'" class="col-12 text-center">
                <div class="row">
                  <div class="col-12">
                    <a :href="activity.linkToData" target="_blank">
                      <i class="nc-icon nc-cloud-download-93 align-middle" style="font-size: 28px"></i> Download</a>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 mt-4 text-center" v-if="activity.note">
                    <p>A note from the professional:</p>
                    <p>{{ activity.note }}</p>
                  </div>
                </div>
              </div>

              <div v-else class="col-12 text-center mt-3">
                <p class="text-center">Here you will be able to download all the material created by the professional for you.</p>
              </div>
            </div>


            <div class="mt-3">
              <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(deliverResults)">

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="mt-5 col-sm-2 control-label">Score:</label>

<!--                        <ValidationProvider v-if="audit && activity" name="score" rules="required|min_value:1|max_value:10" v-slot="{ passed, failed }">-->
<!--                        <fg-input :disabled="saving || audit.status !== 'accepted' || activity.status !== 'waitingAuditors'"-->
<!--                                  v-model="audit.overallScore"-->
<!--                                  :error="failed ? $t('common.field_required') : null"-->
<!--                                  :hasSuccess="passed"-->
<!--                                  name="score"-->
<!--                                  placeholder="Score" type="number" class="col-12 col-md-6 col-lg-4 col-xl-3">-->
<!--                          <template slot="addonLeft"><i class="fa fa-star text-warning"></i></template>-->
<!--                        </fg-input>-->
<!--                        </ValidationProvider>-->

<!--                        <fg-input :disabled="true"-->
<!--                                  v-else-->
<!--                                  placeholder="Score" type="number" class="col-12 col-md-6 col-lg-4 col-xl-3">-->
<!--                          <template slot="addonLeft"><i class="fa fa-star text-warning"></i></template>-->
<!--                        </fg-input>-->


                        <el-slider v-if="audit && activity"
                                   name="score" class="slider-info col-12 col-md-6 col-lg-4 col-xl-3" label="Score"
                                   v-model="audit.overallScore"
                                   :disabled="saving || audit.status !== 'accepted' || activity.status !== 'waitingAuditors'"
                                   :max="10" :min="1" :step="0.5"
                                   :marks="marks">
                        </el-slider>

                        <el-slider v-else name="score" class="slider-info col-12 col-md-6 col-lg-4 col-xl-3" label="Score"
                                   :disabled="true"
                                   :max="10" :min="1" :step="0.5">
                        </el-slider>

                      </div>
                    </div>
                  </div>


                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Comments</label>

                        <ValidationProvider v-if="audit && activity" name="comments" rules="required" v-slot="{ passed, failed }">
                        <textarea rows="5" class="form-control border-input"
                                  placeholder="Add your comments"
                                  name="comments"
                                  v-model="audit.description"
                                  :disabled="saving || audit.status !== 'accepted' || activity.status !== 'waitingAuditors'"
                                  :error="failed ? $t('common.field_required') : null"
                                  :hasSuccess="passed">
                        </textarea>
                        </ValidationProvider>

                        <textarea rows="5" class="form-control border-input"
                                  placeholder="Add your comments"
                                  v-else
                                  :disabled="true">
                        </textarea>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <p-button native-type="submit" type="success" class="btn-success m-3"
                                v-if="audit && activity"
                                :disabled="saving || audit.status !== 'accepted' || activity.status !== 'waitingAuditors'">
                        <i class="nc-icon nc-check-2"></i> Deliver </p-button>

                      <p-button type="success" class="btn-success m-3"
                                v-else
                                :disabled="true">
                        <i class="nc-icon nc-check-2"></i> Deliver </p-button>
                    </div>
                  </div>
                </form>
              </ValidationObserver>
            </div>

            <div class="mt-3 text-success" v-if="audit && audit.status === 'completed'">


              <div class="row" v-if="audit.blockchainStatus === 'not_sent'">
                <div class="col-12 text-center">
                  <p-button native-type="button" type="success" class="btn-success m-3" @click="sendBlockchain(audit.auditorWallet)">
                    <i class="nc-icon nc-check-2"></i> Send in blockchain </p-button>

                </div>
              </div>

              <div class="row" v-if="audit.blockchianTxID">
                <div class="col-12 text-center">
                  <a :href="process.env.VUE_APP_BSC_SCAN + 'tx/' + audit.blockchianTxID" target="_blank">
                    <i class="nc-icon nc-app align-middle" style="font-size: 28px"></i>
                    See on blockchain
                  </a>
                </div>
              </div>

              <p style="font-size: 18px"><b>Completed! Thank you!</b></p>

            </div>

          </div>
        </div>
      </div>
    </div>


    <div class="row">

      <feedback-card v-for="f in feedback" :key="f.id" class="col-4"
                     :score="f.score"
                     :review="f.review"
                     :role="f.createdByRole"
                     :created-on="f.createdOn.split('T')[0]">
      </feedback-card>

    </div>

    <router-view></router-view>
  </div>
</template>

<script>
  import axios from 'axios';
  import {Table, TableColumn, Message, Slider } from 'element-ui'
  import PButton from "src/components/UIComponents/Button";
  import VueCountdown from '@chenfengyuan/vue-countdown';
  import { Collapse, CollapseItem, Modal } from 'src/components/UIComponents';
  import feedbackCard from "src/components/GeneralViews/FeedbackCard";
  import Swal from 'sweetalert2';
  import { blockchainSendAudit } from "src/util/sc/clientBase";
  import { extend } from "vee-validate";
  import { required, min_value, max_value, required_if } from "vee-validate/dist/rules";
  extend("required", required);
  extend("min_value", min_value);
  extend("max_value", max_value);
  extend("required_if", required_if);

  export default {
    name: 'ManageAudit',
    components: {
      PButton,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [VueCountdown.name]: VueCountdown,
      [Slider.name]: Slider,
      Collapse,
      CollapseItem,
      feedbackCard,
      Modal
    },
    data() {
      return {
        images_base_url: process.env.VUE_APP_IMAGES_BASE_URL,
        loading: true,
        error: null,
        saving: false,
        audit: null,
        activity: null,
        listingData: null,
        delivered_in: {
          days: null,
          hours: null,
          minutes: null},
        feedback: [],
        marks: {
          1: {
                style: {color: 'red'},
                label: this.$createElement('strong', '1')
            },
          5: {
              style: {color: 'gray'},
              label: this.$createElement('strong', '5')
          },
          10: {
              style: {color: 'lightgreen'},
              label: this.$createElement('strong', '10')
          }
        },
        showAcceptModal: false,
        auditorWallet: null,
        propagatePlatform: null
      }
    },
    methods: {
      getActivity(activityId) {
        axios.get(process.env.VUE_APP_SERVER_BASE_URL + "activities/" + activityId,
            {headers: {'X-LOCALE': this.$store.getters.getLang}})
        .then(response => {
          this.activity = response.data;
          this.getListingData(this.activity.checkout.checkoutListingSummary);
          this.getFeedback();

          if(this.activity.status === 'waitingAuditors') {
            let lastChangeOn = new Date(this.activity.lastChangeOn);
            let startedAt = new Date(this.activity.startedAt);

            var diffInTime = lastChangeOn.getTime() - startedAt.getTime();
            this.delivered_in.days = parseInt(diffInTime / (1000 * 3600 * 24));
            diffInTime -= this.delivered_in.days * 1000 * 3600 * 24;
            this.delivered_in.hours = parseInt(diffInTime / (1000 * 3600));
            diffInTime -= this.delivered_in.hours * 1000 * 3600;
            this.delivered_in.minutes = parseInt(diffInTime / (1000 * 60));
          }
        }).catch(error => {
          this.loading = false;
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.$store.commit('logout');
            this.$router.push('/login');
          }
          else if (error.response && error.response.status === 404) {
            console.log("order not found");
            this.$router.push('/auditor/dashboard');
          }
          else {
            this.error = 'err_data_not_received';
            console.log(error);
          }
        });
      },
      getListingData(listingSummaryIri) {
        let id = listingSummaryIri.split("/")[3];
        axios.get(process.env.VUE_APP_SERVER_BASE_URL + "checkout_listing_summaries/" + id,
            {headers: {'X-LOCALE': this.$store.getters.getLang}})
            .then(response => {
              this.listingData = response.data;
              this.getCoverImage(response.data.coverImage);
            }).catch(error => {
          this.loading = false;
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.$store.commit('logout');
            this.$router.push('/login');
          }
          else if (error.response && error.response.status === 404) {
            console.log("order not found");
            this.$router.push('/auditor/dashboard');
          }
          else {
            this.error = 'err_data_not_received';
            console.log(error);
          }
        });
      },
      getCoverImage(iri) {
        let id = iri.split("/")[3];
        axios.get(process.env.VUE_APP_SERVER_BASE_URL + "listing_images/" + id,
            {headers: {'X-LOCALE': this.$store.getters.getLang}})
            .then(res => {
              this.listingData.coverImageUrl = res.data.contentUrl;

              this.loading = false;
            }).catch(error => {
          this.loading = false;
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.$store.commit('logout');
            this.$router.push('/login');
          }
          else if (error.response && error.response.status === 404) {
            console.log("order not found");
            this.$router.push('/auditor/dashboard');
          }
          else {
            this.error = 'err_data_not_received';
            console.log(error);
          }
        });
      },
      accept() {
        this.saving = true;

        let data = {};

        if (this.propagatePlatform === true || this.propagatePlatform === 'true')
            data.blockchainTxMadeByUser = false;
        else {
            data.blockchainTxMadeByUser = true;
            data.auditorWallet = this.auditorWallet;
        }

        let config = {
          method: 'patch',
          url: process.env.VUE_APP_SERVER_BASE_URL + 'audits/' + this.audit.id + "/accept",
          data: data,
          headers: {'Content-Type': 'application/merge-patch+json'}
        };

        axios(config).then(response => {
          this.saving = false;
          Message.success("Thank you! Let's start your amazing job!");
          this.audit = response.data;
        }).catch(error => {
          this.saving = false;
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.$store.commit('logout');
            this.$router.push('/login');
          } else {
            Message.error('An error occurred. Please try again.');
            console.log(error);
          }
        });

      },
      reject() {
        this.saving = true;

        let config = {
          method: 'patch',
          url: process.env.VUE_APP_SERVER_BASE_URL + 'audits/' + this.audit.id,
          data: {
            status: 'rejected'
          },
          headers: {'Content-Type': 'application/merge-patch+json'}
        };

        axios(config).then(response => {
          this.saving = false;
          Message.success("Sorry to hear that :( Let's see you next time!");
          this.audit = response.data;
        }).catch(error => {
          this.saving = false;
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.$store.commit('logout');
            this.$router.push('/login');
          }
          else {
            Message.error('An error occurred. Please try again.');
            console.log(error);
          }
        });
      },
      deliverResults() {
        this.saving = true;

        let config = {
          method: 'patch',
          url: process.env.VUE_APP_SERVER_BASE_URL + 'audits/' + this.audit.id + '/deliver',
          data: {
            overallScore: parseFloat(this.audit.overallScore),
            description: this.audit.description
          },
          headers: {'Content-Type': 'application/merge-patch+json'}
        };

        axios(config).then(response => {

          this.audit = response.data;
          Message.success("Your work has been delivered!");

          // if response contains txID, show in blockchain (we made the transaction for the auditor)
          if (response.data.blockchainTxID && response.data.blockchainTxID !== '')
          {
              //TODO: mettere un bottone o boh
              Message.success("See tx: " + response.data.blockchainTxID);
              this.saving = false;
          }
          else
            this.sendBlockchain(response.data.auditorWallet);

        }).catch(error => {
          this.saving = false;
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.$store.commit('logout');
            this.$router.push('/login');
          }
          else {
            this.error = 'An error occurred. Please try again.';
            console.log(error);
          }
        });
      },
      sendBlockchain(wallet) {
        Swal.fire({
            title: '',
            icon: 'info',
            text: "Reminder: As you specified in acceptance phase, you will be able to use only the following wallet address: " + this.audit.auditorWallet,
            inputLabel: 'Wallet',
            confirmButtonClass: "btn btn-success",
            type: "info",
            buttonsStyling: false
        }).then((result) => {
            //TODO: to TEST!
            blockchainSendAudit(this.activity.checkout.smartContractAddress, wallet, parseFloat(this.audit.overallScore)).then(response => {

                //TODO: in realtà, dovremmo aspettare la conferma in blockchain
                axios.patch(process.env.VUE_APP_SERVER_BASE_URL + "audits/" + this.audit.id,
                    {blockchainTxID: response, blockchainStatus: "sent"},
                    {headers: {'Content-Type': 'application/merge-patch+json'}})
                    .then(res => {
                        Message.success("Everything is saved!");
                        this.audit = res.data;
                    }).catch(error => {
                    if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                        this.$store.commit('logout');
                        this.$router.push('/login');
                    } else {
                        this.error = 'An error occurred. Please try again.';
                        console.log(error);
                    }
                });


            }).catch(error => {
                Message.error("Transaction not sent!");
                console.log("error: " + error);
            }).finally(() => {
                this.saving = false;
            });
        });
      },
      getFeedback() {
        axios.get(process.env.VUE_APP_SERVER_BASE_URL + "feedback/get_user_feedback/" + this.activity.checkout['@id'].split("/")[3],
            {headers: {'X-LOCALE': this.$store.getters.getLang}})
        .then(response => {
          for(let i = 0; i< response.data['hydra:totalItems']; i++)
            this.feedback.push(response.data['hydra:member'][i]);
        }).catch(error => {
          this.loading = false;
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.$store.commit('logout');
            this.$router.push('/login');
          }
          else {
            this.error = 'err_data_not_received';
            console.log(error);
          }
        });
      },
    },
    beforeMount() {
      this.loading = true;
      let auditId = this.$route.params.id;
      if(!auditId)
        this.$router.push("/auditor/dashboard");

      axios.get(process.env.VUE_APP_SERVER_BASE_URL + "audits/" + auditId,
          {headers: {'X-LOCALE': this.$store.getters.getLang}})
      .then(response => {
        this.audit = response.data;
        this.getActivity(this.audit.activity.split("/")[3]);
      }).catch(error => {
        this.loading = false;
        if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
          this.$store.commit('logout');
          this.$router.push('/login');
        }
        else if (error.response && error.response.status === 404) {
          console.log("order not found");
          this.$router.push('/auditor/dashboard');
        }
        else {
          this.error = 'err_data_not_received';
          console.log(error);
        }
      });
    }
  }
</script>

<style scoped>

</style>
