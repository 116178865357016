<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <h5 class="info-text"> {{ $t('listing.start_with_basic_info') }}</h5>

      <div v-if="form_error" class="alert text-danger text-center"><strong>{{ $t(form_error) }}</strong></div>

      <div class="row justify-content-center">
        <div v-if="loading" class="loading col-12"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.loading') }}...</div>
      </div>

      <div class="row justify-content-center mt-5"  v-for="(tr, index) in listingTranslations">

        <div class="col-2 col-md-1" v-if="index > 0 || listingTranslations.length > 1">
          <el-button @click="deleteTranslation(index)" class="btn-danger mt-4"><i class="nc-icon nc-simple-remove"></i></el-button>
        </div>

        <div class="col-4 col-md-4">
          <fg-input>
            <label>{{ $t('listing.select_lang') }}:</label>
            <el-select class="select-primary" size="small" v-model="tr.lang"
                       v-on:change="handleChanges">
              <el-option v-for="option in languages"
                         class="select-primary" :value="option"
                         :label="option.toUpperCase()" :key="option">
              </el-option>
            </el-select>
          </fg-input>
        </div>
        <div class="col-6 col-md-5">
          <ValidationProvider name="title" :rules="'required|min:' + titleLength.min + '|max:' + titleLength.max" v-slot="{ passed, failed }">
            <label>{{ $t('listing.title') }} *</label><br>
            <fg-input name="title" addon-left-icon="nc-icon nc-caps-small" :placeholder="$t('listing.title')"
                      v-on:change="handleChanges"
                      :error="failed ? $t('listing.required_and_in_range_char', {min: titleLength.min, max: titleLength.max}) : null"
                      :hasSuccess="passed" v-model="tr.title">
            </fg-input>
            <span :class="tr.title.length >= titleLength.min && tr.title.length <= titleLength.max  ? 'text-success' : 'text-danger'">
              {{ tr.title.length }} / {{ titleLength.max }} characters (min {{ titleLength.min }})
            </span>
          </ValidationProvider>
        </div>

        <div class="col-12 col-sm-10 mt-3">
          <ValidationProvider name="shortDescription" v-slot="{ passed, failed }"
                              :rules="'required|min:' + shortDescLength.min + '|max:' + shortDescLength.max">
            <label>{{ $t('listing.short_desc') }} *</label><br>
            <textarea class="form-control" rows="4" name="shortDescription"
                      v-model="tr.shortDescription"
                      :placeholder="$t('listing.short_desc')" v-on:change="handleChanges">
            </textarea>
            <span :class="tr.shortDescription.length >= shortDescLength.min && tr.shortDescription.length <= shortDescLength.max  ? 'text-success' : 'text-danger'">
              {{ tr.shortDescription.length }} / {{ shortDescLength.max }} characters (min {{ shortDescLength.min }})
            </span>

            <fg-input type="hidden" name="shortDescription" v-model="tr.shortDescription"
                      :error="failed ? $t('listing.required_and_in_range_char', {min: shortDescLength.min, max: shortDescLength.max}) : null"
                      :hasSuccess="passed">
            </fg-input>
          </ValidationProvider>

          <ValidationProvider name="description" :rules="'required|min:' + descLength.min + '|max:' + descLength.max" v-slot="{ passed, failed }">
            <label>{{ $t('listing.desc') }} *</label><br>
            <textarea class="form-control" rows="6" name="description"
                      style="max-height: 400px; resize: vertical; min-height: 100px;"
                      v-model="tr.description"
                      :placeholder="$t('listing.desc')" v-on:change="handleChanges">
            </textarea>
            <span :class="tr.description.length >= descLength.min && tr.description.length <= descLength.max  ? 'text-success' : 'text-danger'">
              {{ tr.description.length }} / {{ descLength.max }} characters (min {{ descLength.min }})
            </span>

            <fg-input type="hidden" name="description" v-model="tr.description"
                      :error="failed ? $t('listing.required_and_in_range_char', {min: descLength.min, max: descLength.max}) : null"
                      :hasSuccess="passed">
            </fg-input>
          </ValidationProvider>
        </div>
      </div>

      <div class="row justify-content-center mt-5">
        <div class="col-12 col-sm-10">
          <el-button @click="addDomain" class="mt-5">{{ $t('listing.add_language') }}</el-button>

          <div v-if="saving" class="text-danger text-center"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.saving') }}...</div>

          <div v-if="form_error" class="alert text-danger text-center"><strong>{{ $t(form_error) }}</strong></div>
        </div>
      </div>

    </form>
  </ValidationObserver>
</template>
<script>
  import { Select, Option, Button } from 'element-ui';
  import { extend } from "vee-validate";
  import { required, min, max } from "vee-validate/dist/rules";
  import axios from 'axios';

  extend("required", required);
  extend("min", min);
  extend("max", max);


  export default {
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Button.name]: Button
    },
    data() {
      return {
        listingTranslations: [{lang: 'en', title: '', shortDescription: '', description: ''}],
        owner: this.$store.getters.getUserIri,
        form_error: null,
        formChanged: false,
        loading: true,
        languages: JSON.parse(process.env.VUE_APP_LISTING_SUPPORTED_LANGUAGES),
        saving: false,
        shortDescLength: {min: 50, max: 200},
        descLength: {min: 50, max: 1000},
        titleLength: {min: 5, max: 50}
      }
    },
    methods: {
      deleteTranslation(index) {
        if(this.listingTranslations.length > 1)
        {
          this.listingTranslations.splice(index, 1);
          this.handleChanges();
        }
      },
      addDomain() {
        this.listingTranslations.push({lang: 'en', title: '', shortDescription: '', description: ''});
      },
      handleChanges() {
        this.form_error = null;
        this.formChanged = true;
      },
      validate() {
        return this.$refs.form.validate().then( res => {
          if(!res)
            return false;

          if (this.formChanged) {
            this.saving = true;

            let config = null;
            let translations = [];
            for (let i = 0; i < this.listingTranslations.length; i++)
              translations.push({
                language: this.listingTranslations[i].lang,
                title: this.listingTranslations[i].title,
                description: this.listingTranslations[i].description,
                shortDescription: this.listingTranslations[i].shortDescription
              });

            if (this.listingID === null) {
              config = {
                method: 'post',
                url: process.env.VUE_APP_SERVER_BASE_URL + 'listings',
                data: {
                  jsonTextTranslations: translations,
                }
              };
            }
            else {
              //TODO: check if there are checkouts in progress, in order to warning the professional
              config = {
                method: 'patch',
                url: process.env.VUE_APP_SERVER_BASE_URL + 'listings/' + this.listingID,
                headers: {'Content-Type': 'application/merge-patch+json'},
                data: {jsonTextTranslations: translations}
              };
            }

            return axios(config)
              .then(response => {
                this.formChanged = false;
                this.$emit('new-listing-saved', response.data);

                // this.$store.commit('setListing', response.data['@id']);
                // this.listingID = this.$store.getters.getListingId;
                console.log("listing saved!");
                this.saving = false;
                this.$emit('on-validated', true);
                return true;

              }).catch(error => {
                this.saving = false;
                if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                    this.$store.commit('logout');
                    this.$router.push('/login');
                } else {
                  this.form_error = 'err_try_again';
                  console.log(error);
                }
                return false;
              });
          }
          else
          {
            this.$emit('on-validated', true);
            return true;
          }

        });

      }
    },
    beforeMount() {
      if(this.listingID !== null)
      {
        axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'listings/' + this.listingID + '/all_lang')
        .then(response => {
          let translations = [];
          for(let key in response.data)
          {
            translations.push({
              lang: key,
              title: response.data[key].title,
              description: response.data[key].description,
              shortDescription: response.data[key].shortDescription
            });
          }
          this.listingTranslations = translations;
          this.loading = false;
        }).catch(error => {
          this.loading = false;
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.$store.commit('logout');
            this.$router.push('/login');
          } else {
            this.form_error = 'listing.err_listing_data_not_retrieved';
            console.log(error);
          }
        });
      }
      else
        this.loading = false;
    },
    computed: {
    },
    props: {listingID: String}
  }
</script>
<style lang="scss" scoped>
  .card-wizard .picture-container .picture {
    input[type="file"] {
      display: none;
    }
  }
</style>
