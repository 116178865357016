<template>
  <div class="wrapper">
<!--    <side-bar type="sidebar" :sidebar-links="$sidebar.sidebarLinks" :title="company_name">-->
<!--      <user-menu></user-menu>-->
<!--    </side-bar>-->
    <top-navbar></top-navbar>
    <div class="main-panel no-sidebar">
<!--      <top-navbar></top-navbar>-->

      <div class="content" >
        <transition name="fade" mode="out-in">
          <!-- your content here -->

          <div class="content">
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header">
                    <div class="table-responsive">
                      <el-table class="table-shopping"
                                :summary-method="getSummaries"
                                show-summary
                                style="width: 100%"
                                :data="productsTable">

                        <el-table-column min-width="50">
                          <template slot-scope="props">
                            <p-button type="success" size="sm" icon
                                      v-if="props.row.status === 'toPay'"
                                      v-on:click="getCheckoutInfo(props.row.id)">
  <!--                                    v-on:click="payOrder(props.row.id, props.$index)">-->
                              <i class="nc-icon nc-cart-simple"></i>
                            </p-button>
                            <p-button type="success" size="sm" icon
                                      v-if="props.row.status === 'new'"
                                      v-on:click="$router.push({path: '/checkout/' + props.row.packageId + '/' + props.row.id})">
                              <i class="nc-icon nc-refresh-69"></i>
                            </p-button>
                            <p-button type="danger" size="sm" icon v-on:click="deleteCheckout(props.row.id, props.$index)">
                              <i class="fa fa-trash"></i>
                            </p-button>
                          </template>
                        </el-table-column>

                        <el-table-column min-width="100" label="Date" prop="date">
                        </el-table-column>

                        <el-table-column min-width="100">
                          <template slot-scope="props">
                            <div class="img-container">
                              <img :src="props.row.image" alt="">
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column min-width="220" class-name="td-name">
                          <template slot-scope="{row}">
                            <a style="font-size: 18px">{{row.title}}</a>
                            <br>
                            <small>{{row.subTitle}}</small>
                          </template>
                        </el-table-column>
                        <el-table-column min-width="80" label="Price" header-align="right">
                          <template slot-scope="{row}">
                            <div class="text-right" style="font-size: 22px">
                              <small>&euro;</small> {{ row.priceInEur }}
                            </div>
                          </template>
                        </el-table-column>
  <!--                      <el-table-column-->
  <!--                        class-name="td-number"-->
  <!--                        min-width="150"-->
  <!--                        label="QTY"-->
  <!--                        class="td-number td-quantity">-->
  <!--                        <template slot-scope="props">-->
  <!--                          {{props.row.quantity}}-->
  <!--                          <div class="btn-group btn-group-sm">-->
  <!--                            <p-button type="default" round outline size="sm" @click="props.row.quantity > 0 ? props.row.quantity&#45;&#45; : 0">-->
  <!--                              <i class="nc-icon nc-simple-delete"></i>-->
  <!--                            </p-button>-->
  <!--                            <p-button type="default" round outline size="sm" @click="props.row.quantity++">-->
  <!--                              <i class="nc-icon nc-simple-add"></i>-->
  <!--                            </p-button>-->
  <!--                          </div>-->
  <!--                        </template>-->
  <!--                      </el-table-column>-->

                        <el-table-column min-width="80" label="Platform Services" header-align="right">
                          <template slot-scope="{row}">
                            <div class="text-right" style="font-size: 22px">
                              <small>&euro;</small> {{row.servicesCost}}
                            </div>
                          </template>
                        </el-table-column>

                        <el-table-column min-width="80" label="Auditors" header-align="right">
                          <template slot-scope="{row}">
                            <div class="text-right" style="font-size: 22px">
                              <small>&euro;</small> {{row.auditorsCost}}
                            </div>
                          </template>
                        </el-table-column>

                        <el-table-column class-name="td-number" label="Total"  min-width="100">
                          <template slot-scope="props">
  <!--                          <strong><small>&euro;</small> {{props.row.quantity * props.row.price}} </strong>-->
                            <strong><small>&euro;</small> {{ (props.row.priceInEur + props.row.servicesCost + props.row.auditorsCost) }} </strong>
                          </template>
                        </el-table-column>

                      </el-table>
                    </div>

                    <div class="row">
                      <div v-if="loading" class="loading col-12"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.loading') }}</div>
                    </div>


                  </div>
                </div>
              </div>
            </div>



            <div class="row" v-if="loadingCheckout || checkout">

            <div class="col-md-12 col-lg-6 m-auto">
              <div class="card">

                <div class="card-body">

                  <div class="row" v-if="loadingCheckout">
                    <div class="col-12 text-center text-danger">
                    <i class="fa fa-spin fa-spinner"></i> {{ $t('common.loading') }}
                    </div>
                  </div>

                  <div class="row" v-else>
                    <div class="col-12 m-auto">

                      <div class="px-3 mt-3">
                        <h4>Summary</h4>
                        <div class="row">
                          <div class="col-6">
                            <p>Professional:</p>
                          </div>
                          <div class="col-6 text-right">
                            <b>{{checkout.paymentMethod === 'paypal' || checkout.paymentMethod === 'bank' ? "€" : checkout.paymentMethod}} {{ packagePriceInPaymentMethodChosen }}</b>
                          </div>

                          <div class="col-6">
                            <p>Auditors ({{checkout.auditors}}):</p>
                          </div>
                          <div class="col-6 text-right">
                            <b>{{checkout.paymentMethod === 'paypal' || checkout.paymentMethod === 'bank' ? "€" : checkout.paymentMethod}}  {{ checkout.auditorsTotalCost }}</b>
                          </div>
                        </div>

                        <hr/>

                        <div class="row">
                          <div class="col-8">
                            <p>Total:</p>
                          </div>
                          <div class="col-4 text-right">
                            <b>{{checkout.paymentMethod === 'paypal' || checkout.paymentMethod === 'bank' ? "€" : checkout.paymentMethod}}  {{ checkout.auditorsTotalCost + packagePriceInPaymentMethodChosen }}</b>
                          </div>

                        </div>

                        <br>
                        <hr/>

                        <div class="row" v-if="checkout.useSETS">
                          <div class="col-8">
                            <p>Platform Services:</p>
                          </div>
                          <div class="col-4 text-right">
                            <b>SETS {{ checkout.servicesTotalCost }}</b>
                          </div>

                          <div class="col-12" v-if="checkout.servicesTotalCost > userCreditSETS">
                            <p class="text-danger text-center"><b>Your SETS balance is not enough! Please recharge you credit first!</b></p>
                              <p-button type="success" class="m-auto d-block" disabled>Pay with {{checkout.paymentMethod}}</p-button>
                          </div>

                          <div class="col-12" v-else>
                            <p class="text-success text-center"><b>Your SETS balance will be used for the platform services payment.</b></p>
                            <p-button type="success" class="m-auto d-block"
                                      :disabled="savingCheckout"
                                      v-on:click="payOrder(checkout.id)">Pay with {{checkout.paymentMethod}}</p-button>
                            <p class="description text-center mt-2" style="font-size: 12px">(*) This is just a demo. We will not ask you to pay.</p>
                            <div class="row">
                              <div v-if="savingCheckout" class="loading col-12"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.saving') }}</div>
                            </div>
                          </div>


                        </div>


                        <div class="row" v-else>
                          <div class="col-8">
                            <p>Platform Services:</p>
                          </div>

                          <div class="col-4 text-right">
                            <b>{{checkout.paymentMethod === 'paypal' || checkout.paymentMethod === 'bank' ? "€" : checkout.paymentMethod}} {{ checkout.servicesTotalCost }}</b>
                          </div>


                          <hr/>
                          <div class="col-8">
                            <p>Total:</p>
                          </div>
                          <div class="col-4 text-right">
                            <b>{{checkout.paymentMethod === 'paypal' || checkout.paymentMethod === 'bank' ? "€" : checkout.paymentMethod}}  {{ checkout.auditorsTotalCost + packagePriceInPaymentMethodChosen + checkout.servicesTotalCost }}</b>
                          </div>


                          <div class="col-12 m-auto">
                            <p-button type="success" class="m-auto d-block" :disabled="savingCheckout">Pay with {{checkout.paymentMethod}} *</p-button>
                            <p class="description text-center mt-2" style="font-size: 12px">(*) This is just a demo. We will not ask you to pay.</p>
                          </div>

                          <div v-if="savingCheckout" class="loading col-12"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.saving') }}</div>
                        </div>

                      </div>
                    </div>

                  </div>
                </div>



              </div>
            </div>
          </div>

          </div>
          <router-view></router-view>
        </transition>
      </div>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">
  .el-table .cell {
    word-break: break-word;
  }
</style>
<script>
  import axios from 'axios';
  import TopNavbar from '../../TopNavbar.vue'
  import ContentFooter from '../../ContentFooter.vue'
  // import UserMenu from 'src/components/UIComponents/SidebarPlugin/UserMenu.vue'
  import {Table, TableColumn, Message} from 'element-ui'
  import PButton from "src/components/UIComponents/Button";
  import Swal from 'sweetalert2';

  export default {
    name: 'cart',
    components: {
      PButton,
      TopNavbar,
      ContentFooter,
      // UserMenu,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
    },
    methods: {
      // toggleSidebar () {
      //   if (this.$sidebar.showSidebar) {
      //     this.$sidebar.displaySidebar(false)
      //   }
      // },
      priceInEur (v, from) {
          let price = this.$store.getters.transformCurrency(v, from, '€');
          return parseFloat(price.toFixed(2));
      },
      getSummaries (param) {
        const { columns } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = 'Total';
          } else if (index < columns.length - 1) {
            sums[index] = '';
          } else {
            let sum = 0;
            this.productsTable.forEach((obj) => {
              // sum += obj.quantity * obj.price;
              sum += obj.priceInEur + obj.servicesCost + obj.auditorsCost;
            });
            sums[index] = `€ ${sum}`;
          }
        });
        return sums;
      },
      deleteCheckout(idCheckout, rowIndex) {
        axios.delete(process.env.VUE_APP_SERVER_BASE_URL + "checkouts/" + idCheckout)
        .then(response => {
          this.productsTable.splice(rowIndex, 1);
        }).catch(error => {
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.$store.commit('logout');
            this.$router.push('/login');
          }
          else {
            this.error = 'err_data_not_received';
            console.log(error);
          }
        });
      },
      getCheckoutInfo(checkoutID) {
          this.loadingCheckout = true;
          axios.get(process.env.VUE_APP_SERVER_BASE_URL + "checkouts/" + checkoutID,
              {headers: {'X-LOCALE': this.$store.getters.getLang}})
          .then(response => {
            this.checkout = response.data;
          }).catch(error => {
            if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                this.$store.commit('logout');
                this.$router.push('/login');
            }
            else {
                this.error = 'err_data_not_received';
                console.log(error);
            }
          }).finally(() => {this.loadingCheckout = false;});
      },
      payOrder(idCheckout) {
        this.savingCheckout = true;
        axios.post(process.env.VUE_APP_SERVER_BASE_URL + "checkout/start/" + idCheckout, {priceInPaymentMethodChosen: this.packagePriceInPaymentMethodChosen})
        .then(response => {
          this.$store.commit('setUserCreditSETS', response.data.creditSETS);
          Swal.fire({
            title: 'Order paid',
            text: 'The order has been successfully paid. The professional will start to work for you very soon!',
            confirmButtonClass: "btn btn-success",
            type: "success",
            buttonsStyling: false
          })
          .then((result) => {
            this.$router.push('/customer/orders');
          });

        }).catch(error => {
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.$store.commit('logout');
            this.$router.push('/login');
          }
          else {
              if(error.response.data.message)
                  Message.error(this.$i18n.t('cart.' + error.response.data.message));
              else
                  Message.error(this.$i18n.t('err_try_again'));
            console.log(error);
          }
        }).finally(() => {this.savingCheckout = false;});
      }
    },
    mounted: function () {},
    data() {
      return {
        company_name: process.env.VUE_APP_COMPANY_NAME,
        loading: true,
        error: null,
        saving: false,
        productsTable: [],
        checkout: null,
        loadingCheckout: false,
        savingCheckout: false,
        userCreditSETS: this.$store.getters.getCreditSETS
      }
    },
    computed: {
        packagePriceInPaymentMethodChosen: function () {
            if (!this.checkout)
                return 0;
            let price = this.checkout.checkoutListingSummary.packagePrice;
            let c = this.checkout.checkoutListingSummary.currency;
            let pm = this.checkout.paymentMethod;
            if(pm === "paypal" || pm === "bank")
                pm = "€";
            return this.$store.getters.transformCurrency(price, c, pm);
        }
    },
    beforeMount() {
      this.loading = true;
      axios.get(process.env.VUE_APP_SERVER_BASE_URL + "checkouts?status[]=new&status[]=toPay",
          {headers: {'X-LOCALE': this.$store.getters.getLang}})
      .then(response => {
        for(let i = 0; i< response.data['hydra:member'].length; i++) {
          let c = response.data['hydra:member'][i];
          let item = {
            id: c.id,
            packageId: c.checkoutListingSummary.listingPackage.split("/")[3],
            date: (new Date(c.createdAt)).toLocaleString(),
            image: c.checkoutListingSummary.coverImage.contentUrl,
            title: c.checkoutListingSummary.listingTitle,
            subTitle: c.checkoutListingSummary.packageName,
            price: c.checkoutListingSummary.packagePrice,
            currency: c.checkoutListingSummary.currency,
            priceInEur: this.priceInEur( c.checkoutListingSummary.packagePrice, c.checkoutListingSummary.currency),
            auditorsCost: c.auditorsTotalCost > 0 ? c.auditorsTotalCost - (c.auditorsTotalCost * c.checkoutListingSummary.auditorsDuePercentage / 100) : 0,
            servicesCost: c.servicesTotalCost,
            feeCost: 1,
            status: c.status
          };
          this.productsTable.push(item);
        }
        this.loading = false;
      }).catch(error => {
        this.loading = false;
        if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
          this.$store.commit('logout');
          this.$router.push('/login');
        }
        else {
          this.error = 'err_data_not_received';
          console.log(error);
        }
      });
    }
  }

</script>
