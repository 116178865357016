<template>
  <div class="card">
    <div class="card-header">
      <h4 class="title">{{ $t('user_profile.edit_profile') }}</h4>
    </div>
    <div class="card-body">

      <div class="row">
        <div class="col-4 mb-3">
          <el-upload class="picture" action="" accept="image/*"
                     :on-change="handlePreview" :auto-upload="false"
                     :multiple="false"
                     id="upload_user_profile_photo"
                     ref="file"
                     :show-file-list="false">
            <img v-if="photo_profile" :src="photo_profile" class="picture-src border-gray" style="max-height: 200px; max-width: 200px">
            <div slot="tip" class="el-upload__tip">Click on the image to upload a new photo profile</div>
            <p v-if="loading" class="text-danger">{{ $t('user_profile.saving_image') }}..</p>
            <p v-if="image_error" class="text-danger">{{ $t('user_profile.err_saving_image') }}</p>
          </el-upload>
        </div>
      </div>

      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(updateProfile)">

          <div class="row">
            <div class="col-md-5">
              <fg-input type="email" label="E-mail" :disabled="true" placeholder="" v-model="user.email">
              </fg-input>
            </div>

            <div class="col-md-5">
              <ValidationProvider name="username" rules="required" v-slot="{ passed, failed }">
                <fg-input type="text" name="username" label="Username" placeholder="Username"
                          v-model="user_profile_info.username"
                          :error="failed ? $t('common.field_required') : null"
                          :hasSuccess="passed">
                </fg-input>
              </ValidationProvider>
            </div>

            <div class="col-md-2">
              <fg-input type="text" :label="$t('user_profile.language')"
                        :placeholder="$t('user_profile.language')" v-model="user_profile_info.language">
              </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <ValidationProvider name="firstName" rules="required" v-slot="{ passed, failed }">
                <fg-input type="text" name="firstName" :label="$t('registration.first_name')"
                          :placeholder="$t('registration.first_name')"
                          v-model="user_profile_info.firstName"
                          :error="failed ? $t('common.field_required') : null"
                          :hasSuccess="passed">
                </fg-input>
              </ValidationProvider>
            </div>

            <div class="col-md-6">
              <ValidationProvider name="lastName" rules="required" v-slot="{ passed, failed }">
                <fg-input type="text" name="lastName" :label="$t('registration.last_name')"
                          :placeholder="$t('registration.last_name')"
                          v-model="user_profile_info.lastName"
                          :error="failed ? $t('common.field_required') : null"
                          :hasSuccess="passed">
                </fg-input>
              </ValidationProvider>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <fg-input type="text" :label="$t('user_profile.address')"
                        :placeholder="$t('user_profile.address')" v-model="user_profile_info.address">
              </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <fg-input type="text" :label="$t('user_profile.city')"
                        :placeholder="$t('user_profile.city')" v-model="user_profile_info.city">
              </fg-input>
            </div>
            <div class="col-md-4">
              <fg-input type="text" :label="$t('user_profile.country')"
                        :placeholder="$t('user_profile.country')" v-model="user_profile_info.country">
              </fg-input>
            </div>
            <div class="col-md-4">
              <fg-input type="number" :label="$t('user_profile.zip_code')"
                        :placeholder="$t('user_profile.zip_code')" v-model="user_profile_info.postalCode">
              </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>{{ $t('user_profile.about_me') }}</label>
                <textarea rows="5" class="form-control border-input" v-model="user_profile_info.aboutMe"
                    :placeholder="$t('user_profile.about_me_desc')">
                </textarea>
              </div>
            </div>
          </div>

          <div class="row">
<!--            <div class="col-md-4">-->
<!--              <div class="form-group">-->
<!--              <fg-input type="text" :placeholder="$t('user_profile.phone_num')" v-model="user_profile_info.contactPhone">-->
<!--                <template slot="addonLeft">-->
<!--                  <font-awesome-icon :icon="['fas', 'phone-alt']" size="lg" />-->
<!--                </template>-->
<!--              </fg-input>-->
<!--              </div>-->
<!--            </div>-->
            <div class="col-md-4">
              <fg-input type="text" placeholder="Facebook" v-model="user_profile_info.contactFacebook">
                <template slot="addonLeft">
                  <img src="/static/img/social-icons/facebook-icon.png" alt="FB" width="20px" height="auto">
                </template>
              </fg-input>
            </div>
            <div class="col-md-4">
              <fg-input type="text" placeholder="LinkedIn" v-model="user_profile_info.contactLinkedin">
                <template slot="addonLeft">
                  <img src="/static/img/social-icons/linkedin-icon.png" alt="IN" width="22px" height="auto">
                </template>
              </fg-input>
            </div>

            <div class="col-md-4">
              <fg-input type="text" placeholder="Telegram" v-model="user_profile_info.contactTelegram">
                <template slot="addonLeft">
                  <img src="/static/img/social-icons/telegram-icon.png" alt="TG" width="20px" height="auto">
                </template>
              </fg-input>
            </div>

            <div class="col-md-4">
              <fg-input type="text" placeholder="Twitter" v-model="user_profile_info.contactTwitter">
                <template slot="addonLeft">
                  <img src="/static/img/social-icons/twitter-icon.png" alt="TW" width="20px" height="auto">
                </template>
              </fg-input>
            </div>
          </div>

          <div class="text-center">
            <div class="pull-left">
              <p-button native-type="button" type="link" class="mt-3" v-on:click="deleteAccount" :disabled="isDisabled"><u>delete account</u></p-button>
            </div>

            <div v-if="form_error" class="alert text-danger"><strong>{{ $t(form_error) }}</strong></div>
            <p-button native-type="submit" type="info" class="btn-fill btn-wd" :disabled="isDisabled">
              {{ $t('user_profile.update_profile') }}</p-button>
          </div>
          <div class="clearfix"></div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
  import { Upload, Message } from 'element-ui';
  import axios from 'axios';
  import { extend } from "vee-validate";
  import { required } from "vee-validate/dist/rules";
  import Swal from 'sweetalert2';

  extend("required", required);

  export default {
    components: {
      [Upload.name]: Upload
    },
    data () {
      return {
        user: {
          email: this.$store.getters.getEmail
        },
        photo_profile: this.$store.getters.getUserPhoto,
        imageName: '',
        image: null,
        image_error: null,
        form_error: null,
        loading: false,
        isDisabled: false
      }
    },
    methods: {
      handlePreview(file) {
        this.photo_profile = URL.createObjectURL(file.raw);
        this.image = new Blob([file.raw], {type: file.type});
        this.imageName = file.name;
        this.loading = true;
        this.image_error = false;

        var data = new FormData();
        data.append('profilePhotoFile', this.image, this.imageName);
        let avatarId = this.$props['user_profile_info'].avatarIri.split("/");
        avatarId = avatarId[avatarId.length-1];
        axios.post(process.env.VUE_APP_SERVER_BASE_URL + 'avatars/' + avatarId + "/change", data)
          .then(response => {
            this.$store.commit('updateProfilePhoto', response.data.avatarUrl);
            this.loading = false;
          }).catch(error => {
            this.loading = false;
            this.image_error = true;
            if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                this.$store.commit('logout');
                this.$router.push('/login');
            } else
            {
              console.log(error);
            }
          });
      },
      updateProfile () {
        this.loading = true;
        this.isDisabled = true;

        let config = {
          method: 'patch',
          url: process.env.VUE_APP_SERVER_BASE_URL + 'users/' + this.$store.getters.getUserId,
          data: this.$props['user_profile_info'],
          headers: {'Content-Type': 'application/merge-patch+json'}
        };

        axios(config).then(response => {
          this.loading = false;
          this.isDisabled = false;
          this.$emit('user-info-updated');
        }).catch(error => {
          this.loading = false;
          this.isDisabled = false;
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            if (error.response.data.message) {
              console.log(error.response.data.message);
              this.$store.commit('logout');
              this.$router.push('/login');
            } else {
              this.form_error = 'err_user_data_not_updated';
            }
          } else if (error.response && error.response.data) {
            this.form_error = 'err_user_data_not_updated';
            console.log(error.response.data.error);
          } else {
            this.form_error = 'err_user_data_not_updated';
            console.log(error);
          }
        });
      },
      deleteAccount() {
          Swal.fire({
              title: this.$i18n.t("are_you_sure"),
              icon: 'warning',
              text: this.$i18n.t("user_profile.delete_confirmation_message"),
              confirmButtonClass: "btn btn-warning",
              cancelButtonClass: "btn btn-danger",
              type: "warning",
              buttonsStyling: false,
              showCancelButton: true,
          }).then((result) => {
              if (result.isConfirmed) {
                  this.loading = true;
                  this.isDisabled = true;
                  axios.post(process.env.VUE_APP_SERVER_BASE_URL + "users/delete_account_request/", {}).then(response => {
                      this.$store.commit('logout');
                      this.$router.push('/login').then(() => {
                          Message.success(this.$i18n.t("registration.account_deleted"));
                      });

                  }).catch(error => {
                      if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                          this.$store.commit('logout');
                          this.$router.push('/login');
                      }
                      else if(error.response.data.message)
                          this.form_error = error.response.data.message;
                      else {
                          this.form_error = 'err_try_again';
                          console.log(error);
                      }
                  }).finally(() => {
                      this.loading = false;
                      this.isDisabled = false;
                  });
              }
          });
      }
    },
    props: ['user_profile_info'],
  }

</script>
<style>

</style>
