import Login from 'src/components/Pages/Frontend/Login';
import NotFound from "src/components/Pages/NotFoundPage";
import Register from "src/components/Pages/Frontend/Register";
import UserProfile from "../components/Pages/Backend/User/UserProfile";
import MyAudits from "../components/Pages/Backend/Audit/MyAudits";
import Audit from "../components/Pages/Backend/Audit/Audit";
import ManageAudit from "../components/Pages/Backend/Audit/ManageAudit";
import Professional from "../components/Pages/Backend/Professional/Professional";
import DashboardProfessional from "src/components/Pages/Backend/Professional/Dashboard/Dashboard";
import New from "src/components/Pages/Backend/Professional/New/New";
import MyListings from "src/components/Pages/Backend/Professional/MyListings/MyListings";
import Activities from "src/components/Pages/Backend/Professional/MyActivities/Activities";
import ManageActivity from "src/components/Pages/Backend/Professional/MyActivities/ManageActivity";
import Checkout from "src/components/Pages/Backend/Customer/Checkout/Checkout";
import Cart from "src/components/Pages/Backend/Customer/Cart/Cart";
import Customer from "src/components/Pages/Backend/Customer/Customer";
import DashboardCustomer from "src/components/Pages/Backend/Customer/Dashboard/Dashboard";
import Catalogue from "src/components/Pages/Backend/Customer/Catalogue/Catalogue";
import Orders from "src/components/Pages/Backend/Customer/Orders/Orders";
import ShowOrder from "src/components/Pages/Backend/Customer/Orders/ShowOrder";
import DashboardAuditor from "src/components/Pages/Backend/Audit/Dashboard/Dashboard";
import ListingDetails from "src/components/Pages/Backend/ListingDetails/ListingDetails";
import User from "src/components/Pages/Backend/User/User";
import UserSETScredit from "src/components/Pages/Backend/User/UserSETScredit";
import ConfirmRegistration from "src/components/Pages/Frontend/ConfirmRegistration";
import Subscriptions from "src/components/Pages/Backend/User/Subscriptions";
import DeclineRegistration from "src/components/Pages/Frontend/DeclineRegistration";
import RestoreAccount from "src/components/Pages/Frontend/RestoreAccount";
import PrivacyPolicy from "src/components/Pages/Frontend/PrivacyPolicy";
import TermsAndConditions from "src/components/Pages/Frontend/TermsAndConditions";
import CookiePolicy from "src/components/Pages/Frontend/CookiePolicy";
import ContactUs from "src/components/Pages/Frontend/ContactUs";
import TwoFA from "src/components/Pages/Frontend/TwoFA";
import Security from "src/components/Pages/Backend/User/Security";
import {Role} from "src/role";
import Agent from "../components/Pages/Backend/Agent/Agent";
import ToApprove from "../components/Pages/Backend/Agent/ToApprove";

let registerPage = {
  path: '/register',
  name: 'Register',
  component: Register,
  meta: {
    requiresAuth: false
  },
};

let loginPage = {
  path: '/login',
  name: 'Login',
  component: Login,
  meta: {
    requiresAuth: false
  },
};



let twoFA = {
  path: '/login/2fa',
  name: 'TwoFA',
  component: TwoFA,
  meta: {
    requiresAuth: false
  },
};




let professional = {
  path: '/professional',
  component: Professional,
  redirect: '/professional/dashboard',
  meta: {
    requiresAuth: true,
    requiresRole: Role.ProfessionalRead
  },
  children: [
    {
      path: 'dashboard',
      name: 'dashboardProfessional',
      component: DashboardProfessional,
      meta: {
        requiresAuth: true,
        requiresRole: Role.ProfessionalRead
      },
    },
    {
      path: 'new:id?',
      name: 'new_listing',
      component: New,
      meta: {
        requiresAuth: true,
        requiresRole: Role.ProfessionalWrite
      },
    },
    {
      path: 'my_listings',
      name: 'myListings',
      component: MyListings,
      meta: {
        requiresAuth: true,
        requiresRole: Role.ProfessionalWrite
      },
    },
    {
      path: 'activities',
      name: 'activities',
      component: Activities,
      meta: {
        requiresAuth: true,
        requiresRole: Role.ProfessionalRead
      },
    },
    {
      path: 'activity/:activity_id',
      name: 'manage_activity',
      component: ManageActivity,
      meta: {
        requiresAuth: true,
        requiresRole: Role.ProfessionalRead
      },
    },
  ]
};

let customer = {
  path: '/customer',
  component: Customer,
  redirect: '/customer/dashboard',
  meta: {
    requiresAuth: true
  },
  children: [
    {
      path: 'dashboard',
      name: 'dashboardCustomer',
      component: DashboardCustomer,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: 'catalogue',
      name: 'catalogue',
      component: Catalogue,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: 'orders',
      name: 'orders',
      component: Orders,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: 'order/:checkout_id',
      name: 'order_detail',
      component: ShowOrder,
      meta: {
        requiresAuth: true
      },
    }
  ]
};

let user  = {
  path: '/user',
  component: User,
  redirect: '/user/userProfile',
  meta: {
    requiresAuth: true
  },
  children: [
    {
      path: 'profile',
      name: 'userProfile',
      component: UserProfile,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: 'security',
      name: 'security',
      component: Security,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: 'sets_credit',
      name: 'setsCredit',
      component: UserSETScredit,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: 'subscriptions',
      name: 'subscriptions',
      component: Subscriptions,
      meta: {
        requiresAuth: true
      },
    },
  ]
};


let checkout  = {
  path: '/checkout/:id/:checkout_id?',
  name: 'checkout',
  component: Checkout,
  meta: {
    requiresAuth: true
  }
};


let cart  = {
  path: '/cart',
  name: 'cart',
  component: Cart,
  meta: {
    requiresAuth: true
  }
};


let audits = {
  path: '/auditor',
  name: 'audits',
  component: Audit,
  redirect: '/auditor/dashboard',
  meta: {
    requiresAuth: true,
    requiresRole: Role.AuditorRead
  },
  children: [
    {
      path: 'dashboard',
      name: 'dashboardAuditor',
      component: DashboardAuditor,
      meta: {
        requiresAuth: true,
        requiresRole: Role.AuditorRead
      },
    },
    {
      path: 'list',
      name: 'listMyAudits',
      component: MyAudits,
      meta: {
        requiresAuth: true,
        requiresRole: Role.AuditorRead
      },
    },
    {
      path: 'manage/:id',
      name: 'manageAudit',
      component: ManageAudit,
      meta: {
        requiresAuth: true,
        requiresRole: Role.AuditorRead
      },
    },
  ]
};


let listingPage  = {
  path: '/listing/details/:id',
  name: 'listing_data',
  component: ListingDetails,
  meta: {
    requiresAuth: true
  }
};


let agents = {
  path: '/agent',
  name: 'agents',
  component: Agent,
  redirect: '/agent/to_approve',
  meta: {
    requiresAuth: true,
    requiresRole: Role.Agent
  },
  children: [
    {
      path: 'to_approve',
      name: 'listingsToApprove',
      component: ToApprove,
      meta: {
        requiresAuth: true,
        requiresRole: Role.Agent
      },
    }
  ]
};


const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  loginPage,
  twoFA,
  registerPage,
  user,
  checkout,
  cart,
  professional,
  customer,
  audits,
  listingPage,
  agents,
  {
    path: '/confirm_registration/:userId/:registrationString',
    name: 'confirm_registration',
    component: ConfirmRegistration,
    meta: { requiresAuth: false }
  },
  {
    path: '/decline_registration/:userId/:registrationString',
    name: 'decline_registration',
    component: DeclineRegistration,
    meta: { requiresAuth: false }
  },
  {
    path: '/restore_account/:userId/:registrationString',
    name: 'restore_account',
    component: RestoreAccount,
    meta: { requiresAuth: false }
  },
  {
    path: '/contact_us/',
    name: 'contactUs',
    component: ContactUs,
    meta: { requiresAuth: false }
  },
  {
    path: '/privacy_policy',
    name: 'privacy_policy',
    component: PrivacyPolicy,
    meta: { requiresAuth: false }
  },
  {
    path: '/cookie_policy',
    name: 'cookie_policy',
    component: CookiePolicy,
    meta: { requiresAuth: false }
  },
  {
    path: '/terms_and_conditions',
    name: 'terms',
    component: TermsAndConditions,
    meta: { requiresAuth: false }
  },
  {path: '*', component: NotFound, meta: { requiresAuth: false }}
];


export default routes
