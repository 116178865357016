<template>
<div style="height: 100%">
  <card type="pricing" style="height: 100%">
    <h6 slot="header" class="card-category mb-0"> {{ subscription.name }} </h6>
    <div v-if="userPlan != null && userPlan.subscription === subscription['@id']">
      <badge type="warning" v-if="userPlan.status==='cancelled'">{{ $t('subscriptions.ends_on') }} {{ userPlan.endAt.split('T')[0] }}</badge>
      <badge type="success" v-else-if="userPlan.status==='new'">{{ $t('subscriptions.active-renew') }} {{ userPlan.endAt.split('T')[0] }}</badge>
      <badge type="danger" v-else>{{ $t('subscriptions.' + userPlan.status) }}</badge>
      <p class="mt-2"></p>
    </div>
    <div class="card-icon icon-primary">
      <i :class="subscription.icon"></i>
    </div>
    <h3 class="card-title">{{ subscription.price }}€<span style="font-size: 16px">/{{ $t('subscriptions.month') }}</span></h3>
<!--    <ul>-->
<!--      <li>{{ $t('subscriptions.credits') }}: <b>{{ subscription.credits }}</b></li>-->
<!--    </ul>-->

    <p-button v-if="userPlan == null" type="primary" round slot="footer" v-on:click="submit()" :disabled="saving">
      {{ $t('subscriptions.subscribe') }}
    </p-button>

  </card>

<!--  <stripe-checkout-->
<!--    :ref="subscription.id"-->
<!--    :pk="publishableKey"-->
<!--    :session-id="sessionId"-->
<!--  />-->
</div>
</template>


<script>

  import { Card, Button, Badge } from 'src/components/UIComponents';
  // import { StripeCheckout } from '@vue-stripe/vue-stripe';
  import axios from 'axios';
  import { Message } from 'element-ui';

  export default {
    components: {
      Card,
      [Button.name]: Button,
      [Badge.name]: Badge,
      // StripeCheckout
    },
    data() {
      return {
        // publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
        // sessionId: null,
        saving: false
      }
    },
    methods: {
      submit () {
        // this.saving = true;
        // axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'subscriptions_stripe/create_session/' + this.$props.subscription.stripePriceId)
        // .then(response => {
        //   this.saving = false;
        //   this.sessionId = response.data;
        //   this.$refs[this.$props.subscription.id].redirectToCheckout();
        // }).catch(error => {
        //   this.saving = false;
        //   if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
        //     if (error.response.data.message) {
        //       this.$store.commit('logout');
        //       this.$router.push('/login');
        //     }
        //   } else {
        //     Message.error(this.$i18n.t('subscriptions.error_loading_page'));
        //     console.log(error);
        //   }
        // });
      },
    },
    props: ['subscription', 'userPlan']
  }
</script>
<style>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .15s
  }

  .fade-enter,
  .fade-leave-to
    /* .fade-leave-active in <2.1.8 */

  {
    opacity: 0
  }

  .hover-color:hover {
    background-color: #e2f6fd;
  }
  .hover-color:hover a {
    text-decoration: none;
  }
</style>
