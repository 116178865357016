<template>
  <div class="row">

    <div class="col-12 col-xl-9 m-auto">
      <div class="card">
        <div class="card-header">
          <h4 class="title mt-0 text-center">{{$t('security.2fa.2fa_protection')}}</h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div v-if="loading" class="loading col-12"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.loading') }}</div>
          </div>
          <div class="row">
            <div class="col-md-6 m-auto">

              <div v-if="loading">
                <h5>{{$t('security.2fa.how_to_use')}}</h5>
                <ul>
                  <li>{{$t('security.2fa.how_to_use_step1')}}<br>
                    <div class="m-3">
                      <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                         class="mx-2" target="_blank">
                        <img src="/static/img/playstore.jpg" alt="Play Store" width="30%" class="d-inline-block">
                      </a>
                      <a href="https://apps.apple.com/it/app/google-authenticator/id388497605" target="_blank" class="mx-2">
                        <img src="/static/img/appstore.jpg" alt="App Store" width="30%" class="d-inline-block">
                      </a>
                    </div>
                  </li>
                  <li>{{$t('security.2fa.how_to_use_step2')}}</li>
                  <li>{{$t('security.2fa.how_to_use_step3')}}</li>
                  <li>{{$t('security.2fa.how_to_use_step4')}}</li>
                </ul>

                <div class="text-center d-none d-md-block">
                  <h6 class="text-center font-weight-bold mb-0">{{$t('security.2fa.scan_to_copy')}}</h6>
                  <br>
                  <div v-if="loading" class="loading col-12"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.loading') }}</div>
                </div>
              </div>


              <div v-else-if="secret_code_2fa">
                <h5>{{$t('security.2fa.how_to_use')}}</h5>
                <ul>
                  <li>{{$t('security.2fa.how_to_use_step1')}}<br>
                    <div class="m-3">
                      <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                         class="mx-2" target="_blank">
                        <img src="/static/img/playstore.jpg" alt="Play Store" width="30%" class="d-inline-block">
                      </a>
                      <a href="https://apps.apple.com/it/app/google-authenticator/id388497605" target="_blank" class="mx-2">
                        <img src="/static/img/appstore.jpg" alt="App Store" width="30%" class="d-inline-block">
                      </a>
                    </div>
                  </li>
                  <li>{{$t('security.2fa.how_to_use_step2')}}</li>
                  <li>{{$t('security.2fa.how_to_use_step3')}}</li>
                  <li>{{$t('security.2fa.how_to_use_step4')}}</li>
                </ul>

                <div class="text-center d-none d-md-block">
                  <h6 class="text-center font-weight-bold mb-0">{{$t('security.2fa.scan_to_copy')}}</h6>
                  <br>
                  <qrcode-vue :value="'otpauth://totp/' + user_email + '?secret=' + secret_code_2fa + '&issuer=' + product_name"
                              :size="150" level="H"/>
                </div>

<!--                <div class="text-center p-2">-->
<!--                  <el-tooltip id="tooltip_copy_2fa" title="" content="">-->
<!--                    <button type="primary" class="btn" round id="copy_2fa" :data-clipboard-text="secret_code_2fa">-->
<!--                      <i class="nc-icon nc-single-copy-04"></i> {{$t('common.copy')}}-->
<!--                    </button>-->
<!--                  </el-tooltip>-->
<!--                </div>-->

                <p class="text-center mt-2"><strong id="2fa_code">{{$t('security.2fa.secret_code')}} {{ secret_code_2fa }}</strong></p>
              </div>

              <div v-else>
                <h4 class="text-success text-center">
                  <i class="nc-icon nc-lock-circle-open align-middle"></i>
                    {{$t('security.2fa.enabled')}}
                  <i class="nc-icon nc-lock-circle-open align-middle"></i>
                </h4>

                <h6 class="text-center">{{$t('security.2fa.how_to_disable')}}</h6>
              </div>

            </div>


            <div class="col-md-6 m-auto">

              <h4 class="text-warning">{{$t('security.2fa.warnings')}}</h4>
              <ul><div v-html="$t('security.2fa.warning_message')"></div></ul>

              <div class="row">
                <div class="col-md-8">
                  <ValidationObserver v-slot="{ handleSubmit }" ref="form_2fa">
                    <form @submit.prevent="handleSubmit(manage_2fa)">

                      <div v-if="error_2fa" class="alert text-danger"><strong>{{ $t(error_2fa) }}</strong></div>

                      <ValidationProvider name="password" rules="required|min:1" v-slot="{ passed, failed }">
                        <fg-input type="password" v-model="password" addon-left-icon="nc-icon nc-key-25"
                                  placeholder="Password"
                                  :error="failed ? $t('common.field_required') : null" :hasSuccess="passed"
                                  autocomplete="current-password" name="password"></fg-input>
                      </ValidationProvider>

                      <ValidationProvider name="code" rules="required|numeric" v-slot="{ passed, failed }">
                        <fg-input type="text" v-model="code_2fa" addon-left-icon="nc-icon nc-lock-circle-open"
                                  :error="failed ? $t('common.numeric') : null" :hasSuccess="passed"
                                  placeholder="Enter 2FA code" name="code"></fg-input>
                      </ValidationProvider>

                      <p-button v-if="secret_code_2fa" native-type="submit" slot="footer" type="warning" round block class="mb-3"
                                :disabled="saving">{{ $t('security.2fa.enable') }}</p-button>
                      <p-button v-else native-type="submit" slot="footer" type="warning" round block class="mb-3"
                                :disabled="saving">{{ $t('security.2fa.disable') }}</p-button>
                      <div v-if="saving_2fa" class="text-danger text-center"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.saving') }}..</div>
                    </form>
                  </ValidationObserver>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>





    <div class="col-12 col-xl-9 m-auto">
      <div class="card mt-4">
        <div class="card-header">
          <h4 class="title mt-0 text-center">{{$t('security.change_pwd.change_pwd')}}</h4>
        </div>
        <div class="card-body pb-3">

          <div class="row">
            <div class="col-md-6 col-xl-4 m-auto">
              <ValidationObserver v-slot="{ handleSubmit }" ref="form_change_pwd">
                <form @submit.prevent="handleSubmit(change_pwd)">

                  <div v-if="error_pwd" class="alert text-danger"><strong>{{ $t(error_pwd) }}</strong></div>

                  <ValidationProvider name="old_pwd" rules="required|min:1" v-slot="{ passed, failed }">
                    <fg-input type="password" v-model="old_pwd" addon-left-icon="nc-icon nc-key-25"
                              :placeholder="$t('security.change_pwd.old_pwd')"
                              :error="failed ? $t('common.field_required') : null" :hasSuccess="passed"
                              autocomplete="password" name="old_pwd"></fg-input>
                  </ValidationProvider>

                  <ValidationProvider vid="confirmation" rules="required|min:8" v-slot="{ passed, failed }">
                    <fg-input  type="password" name="password" v-model="new_pwd"
                               addon-left-icon="nc-icon nc-key-25"
                               autocomplete="password"
                               :placeholder="$t('security.change_pwd.new_pwd')"
                               :error="failed ? $t('registration.password_required') : null"
                               :hasSuccess="passed">
                    </fg-input>
                  </ValidationProvider>

                  <ValidationProvider rules="required|confirmed:confirmation" v-slot="{ passed, failed }">
                    <fg-input  type="password" name="confirm" v-model="confirm_pwd"
                               addon-left-icon="nc-icon nc-key-25"
                               :placeholder="$t('registration.confirm_pass')"
                               :error="failed ? $t('common.field_required') : null"
                               :hasSuccess="passed">
                    </fg-input>
                  </ValidationProvider>


                  <p-button native-type="submit" slot="footer" type="warning" round block class="mb-3"
                            :disabled="saving">{{ $t('security.change_pwd.change') }}</p-button>

                  <div v-if="saving_new_pwd" class="text-danger text-center"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.saving') }}..</div>


                </form>
              </ValidationObserver>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>
<style lang="scss">

</style>
<script>
  import axios from 'axios';
  import { extend } from "vee-validate";
  import { required, numeric, confirmed } from "vee-validate/dist/rules";
  import { Tooltip, Message } from 'element-ui';
  import QrcodeVue from 'qrcode.vue'

  extend("required", required);
  extend("numeric", numeric);
  extend("confirmed", confirmed);

  export default {
    name: "security",
    components: {
      [Tooltip.name]: Tooltip,
      QrcodeVue
    },
    methods: {
      manage_2fa(event) {
        this.saving = true;
        this.saving_2fa = true;
        this.error_2fa = null;
        let data = {
            "password": this.password,
            "code_2fa": this.code_2fa,
            "secret_code_2fa": this.secret_code_2fa};

        axios.post(process.env.VUE_APP_SERVER_BASE_URL + 'users/manage2fa', data)
        .then(response => {
          let $message = "security.2fa.disabled_success";
          if(response.data.new_code)
              // We disabled the 2FA
              this.secret_code_2fa = response.data.new_code;
          else {
              // We enabled the 2FA
              this.secret_code_2fa = null;
              $message = "security.2fa.enabled_success";
          }
          Message.success(this.$i18n.i($message));
        }).catch(error => {
            if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                this.$store.commit('logout');
                this.$router.push('/login');
            }
            else if (error.response.data.message)
                this.error_2fa = 'security.2fa.' + error.response.data.message;
            else {
                this.error_2fa = "err_try_again";
                console.log(error);
            }
        }).finally(() => {this.saving = this.saving_2fa = false; this.password = this.code_2fa = null; this.$refs.form_2fa.reset();});
      },
      change_pwd() {
          this.saving = true;
          this.saving_new_pwd = true;
          this.error_pwd = null;
          let data = {
              "password": this.old_pwd,
              "new_password": this.new_pwd};

          axios.post(process.env.VUE_APP_SERVER_BASE_URL + 'users/change_password', data)
          .then(response => {
              let $message = "security.change_pwd.pwd_changed";
              Message.success(this.$i18n.t($message));
          }).catch(error => {
            if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                this.$store.commit('logout');
                this.$router.push('/login');
            }
            else if (error.response.data.message)
                this.error_pwd = 'security.change_pwd.' + error.response.data.message;
            else {
                this.error_pwd = "err_try_again";
                console.log(error);
            }
          }).finally(() => {
              this.saving = this.saving_new_pwd = false;
              this.old_pwd = this.new_pwd = this.confirm_pwd = null;
              this.$refs.form_change_pwd.reset();
          });
      }
    },
    data() {
      return {
        secret_code_2fa: null,
        loading: false,
        saving: false,
        saving_2fa: false,
        saving_new_pwd: false,
        error_2fa: null,
        password: null,
        code_2fa: null,
        product_name: process.env.VUE_APP_PRODUCT_NAME,
        company_name: process.env.VUE_APP_COMPANY_NAME,
        user_email: this.$store.getters.getEmail,
        error_pwd: null,
        old_pwd: null,
        new_pwd: null,
        confirm_pwd: null
      }
    },
    beforeMount() {
      this.loading = true;

      axios.get(process.env.VUE_APP_SERVER_BASE_URL + "users/security_info")
      .then(response => {
        if (response.data.secret_2fa)
            this.secret_code_2fa = response.data.secret_2fa;
      }).catch(error => {
        if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.$store.commit('logout');
            this.$router.push('/login');
        }
        else {
            this.error = 'err_try_again';
            console.log(error);
        }
      }).finally(() => {this.loading = false;});
    },
  }

</script>
