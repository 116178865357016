<template>
  <footer class="footer">

    <div class="container-fluid">
      <div class="row">
        <div class="col-12 copyright text-center" style="font-size: small; line-height: 18px;">
          <img height="18px" width="auto" src="/img/logo/logo_small.png" :alt="company_name" style="margin-right: 4px"/>
          <span style="font-weight: bold">{{ company_name }} {{ company_type }}</span><br>
          <span style="font-weight: normal">{{ company_country }}<br>
                    {{ company_address }}</span>
        </div>
      </div>

      <nav class="pull-left">
        <ul>
          <li>
            <router-link to="/privacy_policy/" tag="a">Privacy Policy</router-link>
          </li>
          <li>
            <router-link to="/cookie_policy/" tag="a">Cookie Policy</router-link>
          </li>
          <li>
            <router-link to="/terms_and_conditions/" tag="a">{{$t('common.terms_conditions')}}</router-link>
          </li>
        </ul>
      </nav>
      <div class="copyright pull-right">
        &copy; {{ company_name }} {{ company_type }} {{ company_year }} - {{ now }}. {{ $t('common.right_reserved') }}
      </div>
    </div>
  </footer>
</template>
<script>
  export default {
    data() {
      return {
        company_name: process.env.VUE_APP_COMPANY_NAME,
        company_type: process.env.VUE_APP_COMPANY_TYPE,
        company_country: process.env.VUE_APP_COMPANY_COUNTRY,
        company_address: process.env.VUE_APP_COMPANY_ADDRESS,
        company_year: process.env.VUE_APP_COMPANY_YEAR,
        now: (new Date()).getFullYear(),
      }
    }
  }

</script>
<style>

</style>
