<template>
  <div class="card card-stats">
    <div class="card-body">
      <div class="row">
        <div class="col-5 col-md-4">
          <slot name="header">
            <div class="icon-big text-center"
                 :class="`text-${type}`">
              <i :class="icon"></i>
            </div>
          </slot>
        </div>
        <div class="col-7 col-md-8">
          <slot name="content">
            <div class="numbers">
              <p class="card-category">{{smallTitle}}</p>
              <p class="card-title">{{title}}</p>
            </div>
          </slot>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <hr/>
      <div class="row">
        <div class="col-10">
          <slot name="footer"></slot>
        </div>
        <div class="col-1">
          <el-tooltip v-if="tooltipInfo" :content="tooltipInfo">
          <i class="nc-icon nc-alert-circle-i text-info"></i>
          </el-tooltip>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
  import { Tooltip } from 'element-ui';

  export default {
    name: 'stats-card',
    components: {
      [Tooltip.name]: Tooltip
    },
    props:{
      type: {
        type: String,
        description: 'Card text type (primary|info|danger|default|warning|success)'
      },
      title: {
        type: String,
        description: 'Card title'
      },
      smallTitle: {
        type: String,
        description: 'Card small title'
      },
      icon: {
        type: String,
        description: 'Card icon'
      },
      tooltipInfo: {
        type: String,
        description: 'Add an i icon with tooltip on the right of the footer'
      }
    }
  }

</script>
<style scoped>
  .card-content{
    min-height: 85px
  }
</style>
