var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register-page"},[_c('app-navbar'),_c('div',{staticClass:"wrapper wrapper-full-page "},[_c('div',{staticClass:"full-page register-page section-image",attrs:{"filter-color":"black"}},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-5 col-md-6 m-auto"},[_c('card',{staticClass:"text-center",attrs:{"type":"signup"}},[_c('template',{slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.$t("registration.register")))])]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.register)}}},[(_vm.form.error)?_c('div',{staticClass:"alert text-danger"},[_c('strong',[_vm._v(_vm._s(_vm.$t(_vm.form.error)))])]):_vm._e(),_c('ValidationProvider',{attrs:{"name":"firstName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"addon-left-icon":"nc-icon nc-single-02","placeholder":_vm.$t('registration.first_name'),"type":"text","name":"firstName","autocomplete":"first name","error":failed ? _vm.$t('common.field_required') : null,"hasSuccess":passed},model:{value:(_vm.form.firstName),callback:function ($$v) {_vm.$set(_vm.form, "firstName", $$v)},expression:"form.firstName"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"lastName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"addon-left-icon":"nc-icon nc-single-02","placeholder":_vm.$t('registration.last_name'),"type":"text","name":"lastName","autocomplete":"last name","error":failed ? _vm.$t('common.field_required') : null,"hasSuccess":passed},model:{value:(_vm.form.lastName),callback:function ($$v) {_vm.$set(_vm.form, "lastName", $$v)},expression:"form.lastName"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"addon-left-icon":"nc-icon nc-circle-10","placeholder":"Username","type":"text","name":"username","autocomplete":"username","error":failed ? _vm.$t('common.field_required') : null,"hasSuccess":passed},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"addon-left-icon":"nc-icon nc-email-85","placeholder":"E-mail","type":"email","name":"email","autocomplete":"email","error":failed ? _vm.$t('common.field_required') : null,"hasSuccess":passed},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"confirmation","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"password","name":"password","addon-left-icon":"nc-icon nc-key-25","placeholder":"Password","autocomplete":"password","error":failed ? _vm.$t('registration.password_required') : null,"hasSuccess":passed},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"password","name":"confirm","addon-left-icon":"nc-icon nc-key-25","placeholder":_vm.$t('registration.confirm_pass'),"error":failed ? _vm.$t('common.field_required') : null,"hasSuccess":passed},model:{value:(_vm.form.confirmPassword),callback:function ($$v) {_vm.$set(_vm.form, "confirmPassword", $$v)},expression:"form.confirmPassword"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":{required: { allowFalse: false }}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('p-checkbox',{staticClass:"text-left has-error",attrs:{"error":failed},model:{value:(_vm.form.acceptPrivacy),callback:function ($$v) {_vm.$set(_vm.form, "acceptPrivacy", $$v)},expression:"form.acceptPrivacy"}},[_c('p',{staticClass:"m-0 p-0"},[_c('b',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('registration.accept_check'))+" "),_c('router-link',{attrs:{"to":"/privacy_policy/","tag":"a","target":"_blank"}},[_vm._v("Privacy Policy")]),_vm._v(".")],1)])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":{required: { allowFalse: false }}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('p-checkbox',{staticClass:"text-left has-error",attrs:{"error":failed},model:{value:(_vm.form.acceptTerms),callback:function ($$v) {_vm.$set(_vm.form, "acceptTerms", $$v)},expression:"form.acceptTerms"}},[_c('p',{staticClass:"m-0 p-0"},[_c('b',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('registration.accept_check'))+" "),_c('router-link',{attrs:{"to":"/terms_and_conditions/","tag":"a","target":"_blank"}},[_vm._v(_vm._s(_vm.$t('common.terms_conditions')))]),_vm._v(".")],1)])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":{required: { allowFalse: false }}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('p-checkbox',{staticClass:"text-left has-error",attrs:{"error":failed},model:{value:(_vm.form.adult),callback:function ($$v) {_vm.$set(_vm.form, "adult", $$v)},expression:"form.adult"}},[_c('p',{staticClass:"m-0 p-0"},[_c('b',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('registration.adult_declaration'))+".")])])]}}],null,true)}),_c('p-checkbox',{staticClass:"text-left",model:{value:(_vm.form.newsletter),callback:function ($$v) {_vm.$set(_vm.form, "newsletter", $$v)},expression:"form.newsletter"}},[_c('p',{staticClass:"m-0 p-0"},[_vm._v(" "+_vm._s(_vm.$t('newsletter.subscribe_me')))])]),_c('vue-hcaptcha',{attrs:{"sitekey":_vm.captchaKey},on:{"verify":_vm.captchaVerified}}),_c('p-button',{staticClass:"mb-3",attrs:{"slot":"footer","native-type":"submit","type":"warning","round":"","block":"","disabled":_vm.isDisabled},slot:"footer"},[_vm._v(_vm._s(_vm.$t('registration.register')))])],1)]}}])})],2)],1)])])]),_c('app-footer'),_c('div',{staticClass:"full-page-background",staticStyle:{"background-image":"url(/static/img/background/jan-sendereks.jpg)"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }