<template>
  <div class="content">

    <div class="row">
      <div class="col-12">

        <div class="card">
          <div class="card-header">
            <h3 v-if="listingData">{{ listingData.listingTitle }}</h3>

            <div class="row">
              <div v-if="loading" class="loading col-12"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.loading') }}</div>
            </div>
          </div>


          <div class="card-body" v-if="listingData">
            <div class="row">
              <div class="col-6 col-md-4 col-xl-3">
                <img :src="listingData.coverImageUrl"
                     v-if="listingData.coverImageUrl"
                     width="200px" height="auto" class="m-auto d-block"/>
              </div>

              <div class="col-6 col-md-8 col-xl-9">
                <p class="m-2">{{ listingData.packageName }} - {{ listingData.currency }} {{ listingData.packagePrice }}</p>
                <p class="m-2"><i class="fa fa-star text-warning"></i> 4.5 (130 reviews)</p>
              </div>

            </div>
            <div class="row mt-4">
              <div class="col-12">
                <p style="white-space: pre-line">{{ listingData.listingDescription }}</p>
              </div>
            </div>

            <div class="row m-4" v-if="checkout && checkout.smartContractAddress && checkout.smartContractAddress !== 'none'">
              <div class="col-12 text-center" style="font-weight: bold">
                <a :href="'https://testnet.bscscan.com/address/' + checkout.smartContractAddress" target="_blank">Smart Contract</a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>



    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
          </div>
          <div class="card-body">
            <div class="row" v-if="activity">
              <div class="col-12 mb-2">
                <div v-if="activity.status === 'new'">
                  <p class="mt-2 text-center">The professional will start very soon to work for you!</p>
                </div>

                <div v-else-if="activity.status === 'workInProgress'">
                  <p class="mt-2 text-center">The professional is working for you! Let's wait for his amazing job!</p>
                </div>

                <div v-else-if="activity.status === 'waitingAuditors'">
                  <p class="mt-2 text-center">The result is ready! The auditors are reviewing it for you!</p>
                </div>

                <div v-else-if="activity.status === 'waitingApproval'">
                  <p class="mt-2 text-center">The result is ready! Review it and accept or reject it.</p>
                </div>

                <div v-else-if="activity.status === 'waitingFeedback'">
                  <p class="mt-2 text-center">Please leave your feedback!</p>
                </div>

                <div v-else>
                  <p class="mt-2 text-center">The transaction is completed!</p>
                </div>
              </div>
            </div>


            <div class="row">
              <div class="col-6 text-center">
                <i class="nc-icon nc-delivery-fast align-middle" style="font-size: 28px"></i> Delivery Time:

                <b>
                  <countdown v-if="countdownTime"
                             :time="countdownTime"
                             v-slot="{ days, hours, minutes, seconds }">
                    {{ days }} days, {{ hours }} hours, {{ minutes }} minutes, {{ seconds }} seconds.
                  </countdown>

                  <span v-else-if="delivered_in.minutes !== null">
                    <span v-if="delivered_in.days > 0">{{ delivered_in.days }} days, </span>
                    <span v-if="delivered_in.days > 0 || delivered_in.hours > 0">{{ delivered_in.hours }} hours, </span>
                    <span v-if="delivered_in.days > 0 || delivered_in.hours > 0 || delivered_in.minutes > 0">{{ delivered_in.minutes }} minutes.</span>
                    <span v-else> < 1 minute</span>
                  </span>

                  <span v-else> - </span>
                </b>

                <span v-if="listingData">
                  ({{ listingData.deliveryTime }} {{listingData.deliveryTimeUnit}})
                </span>

              </div>

              <div class="col-6 text-center">
                <i class="nc-icon nc-refresh-69 align-middle" style="font-size: 28px"></i> Revision n.
                <span v-if="listingData">
                  <b><span v-if="activity"> {{ activity.revisionNumber }}</span> <span v-else> 1</span></b>
                  (Max {{ listingData.revisionsMax }})
                </span>
                <span v-else> - </span>
              </div>
            </div>

            <div v-if="activity && (activity.status === 'new' || activity.status === 'workInProgress')" class="row mt-3">
              <div class="col-12 text-center">
                <p>Here you will be able to download all the material created by the professional for you.</p>
              </div>
            </div>

            <div v-else-if="activity && activity.linkToData" class="row mt-3">

              <div class="col-12 text-center">
                <a :href="activity.linkToData">
                  <i class="nc-icon nc-cloud-download-93 align-middle" style="font-size: 28px"></i> Download</a>
              </div>

              <div class="col-12 mt-4 text-center" v-if="activity.note">
                <p>A note from the professional:</p>
                <p>{{ activity.note }}</p>
              </div>

            </div>


            <div class="row" v-if="!activity || (activity && activity.status !== 'completed' && activity.status !== 'waitingFeedback')">
              <div class="col-6 text-center">
                <ValidationObserver v-slot="{ handleSubmit }">
                  <form @submit.prevent="handleSubmit(accept)">
                    <p-button native-type="submit" class="btn-success m-3"
                              :disabled="!activity || activity.status !== 'waitingApproval' || saving || loading">
                      <i class="nc-icon nc-check-2"></i> Approve</p-button>
                  </form>
                </ValidationObserver>
              </div>

              <div class="col-6 text-center">
                <ValidationObserver v-slot="{ handleSubmit }">
                  <form @submit.prevent="handleSubmit(reject)">
                    <p-button native-type="submit" class="btn-danger m-3"
                              :disabled="!activity || activity.status !== 'waitingApproval' || saving || loading">
                      <i class="nc-icon nc-simple-remove"></i> Reject</p-button>
                  </form>
                </ValidationObserver>
              </div>
            </div>





            <div class="row mt-4">
              <div class="col-12">
                <h4>Auditors comments</h4>

                <div v-if="checkout">
                  <div v-if="checkout.auditors === 0">
                    No auditors involved.
                  </div>
                  <div v-else-if="checkout.auditors > 0 && audits.length < checkout.auditors">
                    <p>{{ checkout.auditors }} auditors involved. Waiting for their feedback..</p>
                  </div>
                  <div v-else>
                    <collapse>
                      <collapse-item v-for="audit in audits" :key="audit.id"
                                     :title="'Auditor ' + audit.auditorAlias"
                                     :name="audit.auditorAlias">
                        <div v-if="audit.status === 'completed'" class="row">
                          <div class="col-2 text-center" style="border-right: 1px solid lightgrey;">
                            <p style="font-size: 28px"><i class="fa fa-star text-warning"></i> {{ audit.overallScore }}</p>
                          </div>
                          <div class="col-7">
                            <p>{{ audit.description }}</p>
                          </div>


                          <feedback-card v-if="feedback[audit.auditorAlias]"  class="col-3"
                                         :score="feedback[audit.auditorAlias].score"
                                         :review="feedback[audit.auditorAlias].review"
                                         :role="feedback[audit.auditorAlias].createdByRole"
                                         :created-on="feedback[audit.auditorAlias].createdOn.split('T')[0]">
                          </feedback-card>

                          <div v-else-if="activity.status === 'waitingFeedback' && newFeedback[audit.auditorAlias]" class="col-3">
                            <h6>Please leave your feedback about this auditor:</h6>
                            <ValidationObserver v-slot="{ handleSubmit }">
                              <form @submit.prevent="handleSubmit(sendAuditorFeedback(audit.auditorAlias))">
                                <div class="row">
                                  <div class="col-12">
                                    <ValidationProvider name="score" rules="required|min_value:1|max_value:5" v-slot="{ passed, failed }">
                                      <div class="form-group">
                                        <fg-input v-model="newFeedback[audit.auditorAlias].score"
                                                  :error="failed ? $t('common.field_required') : null" :hasSuccess="passed"
                                                  name="score"
                                                  placeholder="Score" type="number">
                                          <template slot="addonLeft"><i class="fa fa-star text-warning"></i></template>
                                        </fg-input>
                                      </div>
                                    </ValidationProvider>
                                  </div>
                                  <div class="col-12">
                                    <ValidationProvider name="review" rules="required" v-slot="{ passed, failed }">
                                      <textarea v-model="newFeedback[audit.auditorAlias].review"
                                                class="form-control" rows="8" name="review"
                                                placeholder="Please, write your review here">
                                      </textarea>

                                      <fg-input type="hidden" v-model="newFeedback[audit.auditorAlias].review"
                                                :error="failed ? $t('common.field_required') : null" :hasSuccess="passed"
                                                name="review">
                                      </fg-input>
                                    </ValidationProvider>
                                  </div>

                                  <div class="col-12">
                                    <p-button native-type="submit" class="btn-primary my-3"
                                        :disabled="saving">
                                      <i class="nc-icon nc-send"></i> Send</p-button>
                                  </div>
                                </div>
                              </form>
                            </ValidationObserver>
                          </div>
                        </div>

                        <div v-else class="row">
                          <div class="col-12 text-center">
                            <p>Waiting for Review by this Auditor.</p>
                          </div>
                        </div>

                      </collapse-item>
                    </collapse>
                  </div>
                </div>

              </div>
            </div>


          </div>
        </div>
      </div>
    </div>


    <div class="row" v-if="previousActivityVersions">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h4>Past Revisions</h4>
          </div>
          <div class="card-body">

            <div class="row">
              <div class="col-12">
                <collapse>
                  <collapse-item v-for="a in previousActivityVersions"
                                 :key="a.id"
                                 :title="'Revision #' + a.revisionNumber"
                                 :name="a.revisionNumber">
                    <div class="row">
                      <div class="col-12">
                        <p>
                          <i class="nc-icon nc-delivery-fast align-middle" style="font-size: 28px"></i> Delivered in
                          <b v-if="a.delivered_in && a.delivered_in.minutes">
                            <span v-if="a.delivered_in.days > 0">{{ a.delivered_in.days }} days, </span>
                            <span v-if="a.delivered_in.days > 0 || a.delivered_in.hours > 0">{{ a.delivered_in.hours }} hours, </span>
                            <span v-if="a.delivered_in.days > 0 || a.delivered_in.hours > 0 || a.delivered_in.minutes > 0">{{ a.delivered_in.minutes }} minutes.</span>
                            <span v-else> < 1 minute</span>
                          </b>
                        </p>
                      </div>

                      <div class="col-6" style="border-right: 1px solid lightgrey;">
                        <a :href="a.linkToData">
                          <i class="nc-icon nc-cloud-download-93 align-middle" style="font-size: 28px"></i> Download
                        </a>
                        <p v-if="a.note" class="mt-2">{{ a.note }}</p>
                      </div>

                      <div class="col-6">
                        <p>Reject reason: </p>
                        <p>{{ a.reviewCustomer }}</p>
                      </div>

                      <div class="col-12 px-5">
                        <collapse>
                          <collapse-item v-for="audit in a.auditsData"
                                         :key="audit.id"
                                         :title="'Auditor ' + audit.auditorAlias"
                                         :name="audit.auditorAlias">
                            <div class="row">
                              <div class="col-3 text-center" style="border-right: 1px solid lightgrey;">
                                <p style="font-size: 28px"><i class="fa fa-star text-warning"></i> {{ audit.overallScore }}</p>
                              </div>
                              <div class="col-9">
                                <p>{{ audit.description }}</p>
                              </div>
                            </div>
                          </collapse-item>
                        </collapse>
                      </div>

                    </div>
                  </collapse-item>
                </collapse>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="row">
      <feedback-card v-if="feedback['professional']" class="col-4"
                     :score="feedback['professional'].score"
                     :review="feedback['professional'].review"
                     :role="feedback['professional'].createdByRole"
                     :created-on="feedback['professional'].createdOn.split('T')[0]">
      </feedback-card>

      <feedback-card v-if="feedback['customer']" class="col-4"
                     :score="feedback['customer'].score"
                     :review="feedback['customer'].review"
                     :role="feedback['customer'].createdByRole"
                     :created-on="feedback['customer'].createdOn.split('T')[0]">
      </feedback-card>
    </div>

    <router-view></router-view>
  </div>
</template>

<script>
  import axios from 'axios';
  import {Table, TableColumn} from 'element-ui'
  import PButton from "src/components/UIComponents/Button";
  import VueCountdown from '@chenfengyuan/vue-countdown';
  import { Collapse, CollapseItem } from 'src/components/UIComponents';
  import Swal from 'sweetalert2';
  import { extend } from "vee-validate";
  import { required, min_value, max_value } from "vee-validate/dist/rules";
  import feedbackCard from "src/components/GeneralViews/FeedbackCard"

  extend("required", required);
  extend("min_value", min_value);
  extend("max_value", max_value);

  export default {
    name: 'ShowOrder',
    components: {
      PButton,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [VueCountdown.name]: VueCountdown,
      Collapse,
      CollapseItem,
      feedbackCard
    },
    data() {
      return {
        images_base_url: process.env.VUE_APP_IMAGES_BASE_URL,
        loading: true,
        error: null,
        saving: false,
        checkout: null,
        listingData: null,
        activity: null,
        previousActivityVersions: {},
        countdownTime: null,
        delivered_in: {
          days: null,
          hours: null,
          minutes: null},
        audits: [],
        feedback: {}, // key: auditorAlias, values: feedback instance
        newFeedback: {}
      }
    },
    methods: {
      getCoverImage(iri) {
        let id = iri.split("/")[3];
        axios.get(process.env.VUE_APP_SERVER_BASE_URL + "listing_images/" + id,
            {headers: {'X-LOCALE': this.$store.getters.getLang}})
        .then(res => {
          this.listingData.coverImageUrl = res.data.contentUrl;

          this.loading = false;
        }).catch(error => {
          this.loading = false;
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.$store.commit('logout');
            this.$router.push('/login');
          }
          else if (error.response && error.response.status === 404) {
            console.log("order not found");
            this.$router.push('/customer/dashboard');
          }
          else {
            this.error = 'err_data_not_received';
            console.log(error);
          }
        });
      },
      getListingData(id) {
        axios.get(process.env.VUE_APP_SERVER_BASE_URL + "checkout_listing_summaries/" + id,
            {headers: {'X-LOCALE': this.$store.getters.getLang}})
        .then(res => {
          this.listingData = res.data;
          // this.feedback[0].userRef = this.listingData.professional;
          this.getCoverImage(res.data.coverImage);
        }).catch(error => {
          this.loading = false;
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.$store.commit('logout');
            this.$router.push('/login');
          }
          else if (error.response && error.response.status === 404) {
            console.log("order not found");
            this.$router.push('/customer/dashboard');
          }
          else {
            this.error = 'err_data_not_received';
            console.log(error);
          }
        });
      },
      getActivityData(activityId, isCurrent) {
        axios.get(process.env.VUE_APP_SERVER_BASE_URL + "activities/" + activityId,
            {headers: {'X-LOCALE': this.$store.getters.getLang}})
        .then(response => {
          let act = response.data;

          if (act.audits) {
            act.auditsData = {};
            for (let i = 0; i < act.audits.length; i++) {
              let auditId = act.audits[i].split("/")[3];
              act.auditsData[auditId] = {};
              this.getAudit(auditId, isCurrent);
            }
          }

          if (isCurrent && act.status === 'workInProgress') {
            let startDate = new Date(act.startedAt);
            let now = new Date();

            if (this.listingData.deliveryTimeUnit === 'hour')
              startDate.setHours(startDate.getHours() + this.listingData.deliveryTime);
            else if (this.listingData.deliveryTimeUnit === 'day')
              startDate.setDate(startDate.getDate() + this.listingData.deliveryTime);
            else if (this.listingData.deliveryTimeUnit === 'month')
              startDate.setDate(startDate.getMonth() + this.listingData.deliveryTime);
            else if (this.listingData.deliveryTimeUnit === 'year')
              startDate.setDate(startDate.getFullYear() + this.listingData.deliveryTime);

            let diffMills = startDate.getTime() - now.getTime();
            this.countdownTime = diffMills > 0 ? diffMills : 0;
          } else if (act.status !== 'new' && act.lastChangeOn && act.startedAt) {
            let lastChangeOn = new Date(act.lastChangeOn);
            let startedAt = new Date(act.startedAt);

            var diffInTime = lastChangeOn.getTime() - startedAt.getTime();
            let d_in = {
              days: null,
              hours: null,
              minutes: null};

            d_in.days = parseInt(diffInTime / (1000 * 3600 * 24));
            diffInTime -= d_in.days * 1000 * 3600 * 24;
            d_in.hours = parseInt(diffInTime / (1000 * 3600));
            diffInTime -= d_in.hours * 1000 * 3600;
            d_in.minutes = parseInt(diffInTime / (1000 * 60));

            if(isCurrent)
              this.delivered_in = d_in;
            else
              act.delivered_in = d_in;

          }

          if (isCurrent) {
            this.activity = act;
          }
          else
            this.previousActivityVersions[activityId] = act;

        }).catch(error => {
          this.loading = false;
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.$store.commit('logout');
            this.$router.push('/login');
          }
          else if (error.response && error.response.status === 404) {
            console.log("activity not found");
            this.$router.push('/customer/dashboard');
          }
          else {
            this.error = 'err_data_not_received';
            console.log(error);
          }
        });
      },
      getAudit(auditId, isCurrent) {
        axios.get(process.env.VUE_APP_SERVER_BASE_URL + "audits/" + auditId,
            {headers: {'X-LOCALE': this.$store.getters.getLang}})
        .then(response => {
          if (response.data.status !== 'rejected') {
              if (isCurrent) {
                  this.audits.push(response.data);
                  if (this.feedback[response.data.auditorAlias] === undefined)
                      this.newFeedback[response.data.auditorAlias] = {score: "", review: ""};
              } else
                  this.previousActivityVersions[response.data.activity.split("/")[3]].auditsData[auditId] = response.data;
          }
        }).catch(error => {
          this.loading = false;
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.$store.commit('logout');
            this.$router.push('/login');
          }
          else if (error.response && error.response.status === 404) {
            console.log("audit not found");
            this.$router.push('/customer/dashboard');
          }
          else {
            this.error = 'err_data_not_received';
            console.log(error);
          }
          return null;
        });
      },
      getFeedback() {
        axios.get(process.env.VUE_APP_SERVER_BASE_URL + "feedback/get_user_feedback/" + this.checkout.id,
            {headers: {'X-LOCALE': this.$store.getters.getLang}})
        .then(response => {
          for(let i = 0; i< response.data['hydra:totalItems']; i++)
            this.feedback[response.data['hydra:member'][i].toAlias] = response.data['hydra:member'][i];

          if (!this.feedback.hasOwnProperty('professional'))
            this.newFeedback.professional = {score: "", review: ""};
        }).catch(error => {
          this.loading = false;
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.$store.commit('logout');
            this.$router.push('/login');
          }
          else {
            this.error = 'err_data_not_received';
            console.log(error);
          }
        });
      },
      accept()
      {
        Swal.fire({
          title: 'Satisfied!',
          icon: 'success',
          html:
              'Please leave a feedback for the professional!<br>' +
              '<label>Comment:</label><input id="swal-textarea" class="swal2-textarea">',
          input: 'range',
          inputLabel: 'label',
          inputAttributes: {
            min: 1,
            max: 10,
            step: 0.5
          },
          inputValue: 5,
          confirmButtonClass: "btn btn-success",
          type: "success",
          buttonsStyling: false
        })
        .then((result) => {
          this.saving = true;

          let config = {
            method: 'patch',
            url: process.env.VUE_APP_SERVER_BASE_URL + 'activities/' + this.activity.id + '/accept',
            data: {},
            headers: {'Content-Type': 'application/merge-patch+json'}
          };

          axios(config).then(response => {
            this.activity = response.data;

          }).catch(error => {
            this.saving = false;
            if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
              this.$store.commit('logout');
              this.$router.push('/login');
            }
            else if (error.response && error.response.status === 404) {
              console.log("audit not found");
              this.$router.push('/customer/dashboard');
            }
            else {
              this.error = 'err_data_not_received';
              console.log(error);
            }
          });

          axios.post(process.env.VUE_APP_SERVER_BASE_URL + "feedback", {
            score: parseInt(document.querySelector('.swal2-range > output').value),
            review: document.getElementById('swal-textarea').value,
            toAlias: 'professional',
            checkout: this.checkout['@id']
          }).then(response => {
            this.saving = false;
            this.feedback['professional'] = response.data;

          }).catch(error => {
            this.saving = false;
            if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
              this.$store.commit('logout');
              this.$router.push('/login');
            }
            else if (error.response && error.response.status === 404) {
              console.log("activity not found");
              this.$router.push('/customer/dashboard');
            }
            else {
              this.error = 'err_data_not_received';
              console.log(error);
            }
          });

        });
      },
      reject() {
        Swal.fire({
          title: 'Rejected!',
          icon: 'error',
          html:
              '<p>Please leave a reason for the rejection!</p><br>' +
              '<label>Comment:</label><input id="swal-textarea" class="swal2-textarea">',
          input: 'range',
          inputLabel: 'label',
          inputAttributes: {
            min: 1,
            max: 10,
            step: 0.5
          },
          inputValue: 5,
          confirmButtonClass: "btn btn-success",
          type: "error",
          buttonsStyling: false
        })
        .then((result) => {
          this.saving = true;

          let config = {
            method: 'patch',
            url: process.env.VUE_APP_SERVER_BASE_URL + 'activities/' + this.activity.id + '/reject',
            data: {
              reviewCustomer: document.getElementById('swal-textarea').value,
              scoreCustomer: parseInt(document.querySelector('.swal2-range > output').value)
            },
            headers: {'Content-Type': 'application/merge-patch+json'}
          };

          axios(config).then(response => {


            this.$router.go(0);
          }).catch(error => {
            this.loading = false;
            if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
              this.$store.commit('logout');
              this.$router.push('/login');
            }
            else if (error.response && error.response.status === 404) {
              console.log("activity not found");
              this.$router.push('/customer/dashboard');
            }
            else {
              this.error = 'err_data_not_received';
              console.log(error);
            }
          });
        });
      },
      sendAuditorFeedback (auditorAlias) {
        this.saving = true;
        axios.post(process.env.VUE_APP_SERVER_BASE_URL + "feedback", {
          score: parseFloat(this.newFeedback[auditorAlias].score),
          review: this.newFeedback[auditorAlias].review,
          toAlias: auditorAlias,
          checkout: this.checkout['@id']
        }).then(response => {
          this.saving = false;
          this.feedback[response.data.toAlias] = response.data;
          this.newFeedback[response.data.toAlias] = undefined;

        }).catch(error => {
          this.saving = false;
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.$store.commit('logout');
            this.$router.push('/login');
          }
          else if (error.response && error.response.status === 404) {
            console.log("activity not found");
            this.$router.push('/customer/dashboard');
          }
          else {
            this.error = 'err_data_not_received';
            console.log(error);
          }
        });
      }
    },
    beforeMount() {
      this.loading = true;
      let checkoutId = this.$route.params.checkout_id;
      if (!checkoutId)
        this.$router.push("/customer/dashboard");

      axios.get(process.env.VUE_APP_SERVER_BASE_URL + "checkouts/" + checkoutId,
          {headers: {'X-LOCALE': this.$store.getters.getLang}})
      .then(response => {
        this.checkout = response.data;
        this.getListingData(response.data.checkoutListingSummary.id);

        if (this.checkout.activities)
        {
          this.getActivityData(this.checkout.activities[0].split("/")[3], true);
          for (let i = 1; i< this.checkout.activities.length; i++)
            this.getActivityData(this.checkout.activities[i].split("/")[3], false);
        }

        this.getFeedback();

      }).catch(error => {
        this.loading = false;
        if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
          this.$store.commit('logout');
          this.$router.push('/login');
        }
        else if (error.response && error.response.status === 404) {
          console.log("order not found");
          this.$router.push('/customer/dashboard');
        }
        else {
          this.error = 'err_data_not_received';
          console.log(error);
        }
      });
    }
  }
</script>

<style scoped>

</style>
