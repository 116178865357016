<template>
  <div class="p-2">
    <card type="testimonial" class="m-0">
      <div class="icon icon-warning">
        <i class="fa fa-star"> {{ score }}</i>
      </div>
      <p class="card-description">
        {{ review }}
      </p>
      <template slot="footer">
        <h6 class="card-subcategories" v-if="role">{{ role }}</h6>
        <h6 class="card-category">{{ name }} on {{ createdOn }}</h6>
      </template>
    </card>
  </div>
</template>

<script>
  import card from "src/components/UIComponents/Cards/Card";

  export default {
    name: "feedbackCard",
    components: {
      card
    },
    data() {
      return {}
    },
    props: {
      score: {
        type: Number,
        required: true,
        validator(value) {
          return !(value < 1 || value > 10);
        },
        description: 'Score (float value between 1-10)'
      },
      review: {
        type: String,
        required: true,
        description: 'Review'
      },
      name: {
        type: String,
        default: 'Anonymous',
        description: 'Name of the author. Anonymous by default.'
      },
      role: {
        type: String,
        validator(value) {
          let acceptedValues = ['customer', 'professional', 'auditor'];
          return acceptedValues.indexOf(value) !== -1
        },
        description: 'Role the the author of the review (customer|professional|auditor)'
      },
      createdOn: {
        type: String
      }
    }
  }
</script>

<style scoped>

</style>
