<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <h4>Choose the payment method for the services of the professional</h4>

            <div v-if="loading" class="loading"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.loading') }}</div>
            <div v-if="error" class="loading">{{ error }}</div>


            <el-radio label="crypto"
                      v-if="listing.ETHAddress || listing.BTCAddress || listing.LTCAddress || listing.BNBAddress"
                      v-model="methodPro"
                      v-on:change="$emit('payment_method', {name: 'proCurrency', value: cryptoPro})">
              Crypto currencies</el-radio>
            <el-radio label="paypal"
                      v-if="listing.PayPalEmail"
                      v-model="methodPro"
                      v-on:change="$emit('payment_method', {name: 'proCurrency', value: '€'})">
              PayPal</el-radio>
<!--            <el-radio label="card" v-model="methodPro" v-on:change="$emit('payment_method', {name: 'proCurrency', value: '€'})">-->
<!--              Credit Card</el-radio>-->
            <el-radio label="bank"
                      v-if="listing.bankIBAN"
                      v-model="methodPro"
                      v-on:change="$emit('payment_method', {name: 'proCurrency', value: '€'})">
              Bank Transfer</el-radio>

            <div v-if="methodPro === 'crypto'" class="m-5">
              <el-radio label="ETH"
                        v-if="listing.ETHAddress"
                        v-model="cryptoPro"
                        v-on:change="$emit('payment_method', {name: 'proCurrency', value: cryptoPro})">
                <img src="/static/img/currencies/ETH.png" height="20px" width="auto"/> ETH
              </el-radio>
              <el-radio label="BTC"
                        v-if="listing.BTCAddress"
                        v-model="cryptoPro"
                        v-on:change="$emit('payment_method', {name: 'proCurrency', value: cryptoPro})">
                <img src="/static/img/currencies/BTC.png" height="20px" width="auto"/> BTC
              </el-radio>
              <el-radio label="LTC"
                        v-if="listing.LTCAddress"
                        v-model="cryptoPro"
                        v-on:change="$emit('payment_method', {name: 'proCurrency', value: cryptoPro})">
                <img src="/static/img/currencies/LTC.png" height="20px" width="auto"/> LTC
              </el-radio>
              <el-radio label="BNB"
                        v-if="listing.BNBAddress"
                        v-model="cryptoPro"
                        v-on:change="$emit('payment_method', {name: 'proCurrency', value: cryptoPro})">
                <img src="/static/img/currencies/BNB.png" height="20px" width="auto"/> BNB
              </el-radio>


              <div class="my-5" v-if="cryptoPro === 'ETH' || cryptoPro === 'BNB'">
                <el-checkbox v-model="useSCForPayment" v-on:change="$emit('payment_method', {name: 'useSCForPayment', value: useSCForPayment})">
                  Use Smart Contract to handle payment</el-checkbox>
              </div>
<!--              <div class="m-5">-->
<!--                <div class="row">-->
<!--                  <div class="col-12 text-center">-->
<!--                    <qrcode-vue :value="listing[cryptoPro + 'Address']"></qrcode-vue>-->
<!--                    <p>{{ listing[cryptoPro + 'Address'] }}</p>-->
<!--                    <p class="mt-2 text-danger">Warning! Please send only {{ cryptoPro }} at this wallet! If you send other currencies, they will be lost!</p>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
            </div>

<!--            <div v-if="methodPro === 'paypal'" class="m-5">-->
<!--              <p>Here you will find the button for the access to your PayPal</p>-->
<!--            </div>-->

<!--            <div v-if="methodPro === 'card'" class="m-5">-->
<!--              <p class="text-center"> We accept </p>-->
<!--              <img src="/static/img/currencies/cards.png" width="60%" height="auto" style="left: 20%"/>-->
<!--              <p class="text-center mt-2">Here you will find all the necessary field for the data of your card</p>-->
<!--            </div>-->

<!--            <div v-if="methodPro === 'bank'" class="m-5 text-center">-->
<!--              <p>Account Holder: <b>{{ listing.bankAccountHolder }}</b></p>-->
<!--              <p>IBAN: <b>{{ listing.bankIBAN }}</b></p>-->
<!--              <p>BIC/SWIFT: <b>{{ listing.bankBIC }}</b></p>-->
<!--              <p>Reason for Transfer: <b>{{ listing.bankNote }}</b></p>-->
<!--            </div>-->

            <hr class="mt-5"/>
          </div>

          <div class="col-12 mt-5">
            <el-checkbox v-model="useSETS" v-on:change="$emit('payment_method', {name: 'platformCurrency', value: useSETS})">
              Use SETS tokens to pay the services of our platform</el-checkbox>
            <div class="m-5">
              SETS tokens allows you to have a special discount on our services.
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import { Rate, Checkbox, Radio } from 'element-ui';
  import QrcodeVue from 'qrcode.vue'
  // import { Checkbox, Radio } from 'src/components/UIComponents';

  export default {
    components: {
      [Rate.name]: Rate,
      [Checkbox.name]: Checkbox,
      [Radio.name]: Radio,
      QrcodeVue,
    },
    data() {
      return {
        images_base_url: process.env.VUE_APP_IMAGES_BASE_URL,
        loading: true,
        error: null,
        saving: false,
        listingID: null,
        listing: {},
        methodPro: null,
        cryptoPro: null,
        useSETS: true,
        package: null,
        useSCForPayment: true
      }
    },
    methods: {
      validate() {
        this.error = null;
        if (!this.methodPro || (this.methodPro === 'crypto' && !this.cryptoPro))
        {
            this.error = 'select a valid payment method';
            return false;
        }
        if (this.methodPro === 'crypto' && !this.listing.ETHAddress && !this.listing.BTCAddress && !this.listing.LTCAddress
            && !this.listing.BNBAddress)
        {
            this.error = 'select a valid payment method';
            return false;
        }
        else if (this.methodPro === 'paypal' && !this.listing.PayPalEmail)
        {
            this.error = 'select a valid payment method';
            return false;
        }
        else if (this.methodPro === 'bank' && !this.listing.bankIBAN)
        {
            this.error = 'select a valid payment method';
            return false;
        }

        this.saving =  true;
        return axios.patch(process.env.VUE_APP_SERVER_BASE_URL + 'checkouts/' + this.checkoutID,
            {
              paymentMethod: this.methodPro === 'crypto' ? this.cryptoPro : this.methodPro,
              useSETS: this.useSETS,
              paymentUseSC: this.methodPro === 'crypto' && (this.cryptoPro === 'ETH' || this.cryptoPro === 'BNB') ? this.useSCForPayment : false
            },
            {headers: {'Content-Type': 'application/merge-patch+json'}})
          .then(response => {
            // TODO: check if status = changed to trigger an alert
            this.saving = false;
            this.$emit('on-validated', true);
            return true;
          }).catch(error => {
            this.saving = false;
            if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
              if (error.response.data.message) {
                console.log(error.response.data.message);
                this.$store.commit('logout');
                this.$router.push('/login');
              } else
                this.form_error = 'Error. Try again';
            } else if (error.response && error.response.data) {
              this.form_error = error.response.data.error;
            } else {
              this.form_error = 'Unknown error';
              console.log(error);
            }
            return false;
          });
      },
      initializeStep() {
        let split = this.listingIRI.split("/");
        this.listingID = split[split.length -1];
        axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'listings/' + this.listingID)
            .then(response => {
              this.listing = response.data;
              this.loading = false;
            }).catch(error => {
          this.loading = false;
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.$store.commit('logout');
            this.$router.push('/login');
          }
          else if (error.response && error.response.status === 404)
          {
            console.log("listing not found");
            this.$router.push('/customer/dashboard');
          }
          else {
            this.error = 'err_data_not_received';
            console.log(error);
          }
        });
        // this.$emit('payment_method', {name: 'proCurrency', value: 'ETH'});
        this.$emit('payment_method', {name: 'platformCurrency', value: 'SETS'});
      }
    },
    beforeMount() {

    },
    props: {
      listingIRI: String,
      checkoutID: String
    },
  }
</script>

<style scoped>

</style>
