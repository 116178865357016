<template>
  <div class="login-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="col-lg-4 col-md-6 ml-auto mr-auto">

              <card type="login">
                <ValidationObserver v-slot="{ handleSubmit }">
                  <form @submit.prevent="handleSubmit(check2FA)">
                    <h3 slot="header" class="header text-center">{{ $t('login.2fa') }}</h3>
                    <div v-if="error" class="alert text-danger"><strong>{{ $t(error) }}</strong></div>

                    <ValidationProvider name="code" rules="required|numeric" v-slot="{ passed, failed }">
                    <fg-input type="text" v-model="code" addon-left-icon="nc-icon nc-lock-circle-open"
                              :error="failed ? $t('common.numeric') : null" :hasSuccess="passed"
                              placeholder="Enter 2FA code" name="code"></fg-input>
                    </ValidationProvider>

                    <p-button native-type="submit" slot="footer" type="warning" round block class="mb-3"
                              :disabled="saving">{{ $t('login.login') }}</p-button>
                  </form>
                </ValidationObserver>

              </card>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
        <div class="full-page-background" style="background-image: url(/static/img/background/background-2.jpg)"></div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Card } from 'src/components/UIComponents';
  import AppNavbar from './AppNavbar'
  import AppFooter from './AppFooter'
  import { extend } from "vee-validate";
  import { required, numeric } from "vee-validate/dist/rules";
  import axios from 'axios';
  import Swal from 'sweetalert2';

  extend("required", required);
  extend("numeric", numeric);

  export default {
    name: "TwoFA",
    components: {
      Card,
      AppNavbar,
      AppFooter
    },
    methods: {
      toggleNavbar() {
        document.body.classList.toggle('nav-open');
      },
      closeMenu() {
        document.body.classList.remove('nav-open');
        document.body.classList.remove('off-canvas-sidebar');
      },
      check2FA() {
        this.error = '';
        this.saving = true;

        axios.post(process.env.VUE_APP_SERVER_BASE_URL + 'login/2fa_check', {
            _auth_code: this.code,
        })
        .then(response => {
          this.$store.commit('auth_success', response.data.user);
          this.$store.commit('setCurrencies', response.data.currencies);
          this.$router.push(this.$route.query.redirect || '/customer/dashboard');
        }).catch(error => {
          console.log(error);
          if(error.response && error.response.data && error.response.data.code === 401 &&
              error.response.data.message === 'Invalid credentials.')
            this.error = "login.invalid_credential";
          else if(error.response && error.response.data && error.response.data.code === 401 &&
              error.response.data.message === 'An authentication exception occurred.')
            this.error = "login.inactive_account";
          else
            this.error = "err_try_again";
        }).finally(() => {this.saving = false;});
      },
    },
    data() {
      return {
        code: "",
        error: null,
        saving: false
      }
    },
    beforeDestroy() {
      this.closeMenu()
    }
  }
</script>
<style>
  .loginBackground {
    /*background: url('./public/static/img/background.jpg');*/
  }
</style>

