<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div v-if="loading" class="loading"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.loading') }}</div>
      </div>
      <div class="col-12">
        <div class="row" v-if="listing">
          <div class="col-12">
            <h4>Your package</h4>
            <div class="row">
              <div class="col-3">
                <img :src="listing.coverImage.contentUrl" width="100%" height="auto" style="border-radius: 10%"/>
              </div>
              <div class="col-5">
                <p>{{ listing.title }}</p>
                <el-rate disabled :value="4.3" show-score></el-rate>
                <p class="mt-2">{{ package.name }}</p>
              </div>
              <div class="col-4">
                <p class="text-right">{{ package.currency.symbol }} {{ package.price }}</p>
              </div>
            </div>

            <hr/>
          </div>


          <div class="col-12">
            <ValidationObserver ref="form">
              <form @submit.prevent="validate">
                <div class="row mb-3">
                  <div class="col-9">
                    <h4>Add Extras</h4>
                  </div>
                  <div class="col-3 text-center mt-4 pt-3">
                    <el-tooltip content="Use a Smart Contract to track the result of the service.">
                      <b>Smart Contract *</b>
                    </el-tooltip>
                  </div>
                </div>

                <div class="row" v-if="package.deliveryTime && package.deliveryTime > 0">
                  <div class="col-6">
                    <li>Delivery Time:</li>
                  </div>
                  <div class="col-3">
                    <p class="text-right">{{ package.deliveryTime }} {{ package.deliveryTimeUnit }}</p>
                  </div>
                  <div class="col-3 text-center">
                    <el-checkbox v-if="package.deliveryTimeSC" v-model="deliveryTimeSC">
                      Free
                    </el-checkbox>
                  </div>
                </div>

                <div class="row" v-if="package.revisionsMax && package.revisionsMax > 0">
                  <div class="col-6">
                    <li>Revisions:</li>
                  </div>
                  <div class="col-3">
                    <p class="text-right">{{ package.revisionsMax }} </p>
                  </div>
                  <div class="col-3 text-center">
                    <el-checkbox v-if="package.revisionsSC" v-model="revisionsSC">
                      Free
                    </el-checkbox>
                  </div>
                </div>

                <div class="row" v-if="package.auditorsMax && package.auditorsMax > 0">
                  <div class="col-6">
                    <li>Auditors (Max {{ package.auditorsMax }} - {{ package.auditorsDuePercentage }}% paid):</li>
                  </div>
                  <div class="col-3">
                    <ValidationProvider name="auditors" :rules="'numeric|min_value:0|max_value:' + package.auditorsMax" v-slot="{ passed, failed }">
                      <fg-input name="auditors" class="small" v-model="auditors" type="number"
                                v-on:change="auditorsChanged"
                                :error="failed ? 'This field must be in range 0 - ' + package.auditorsMax : null"
                                :hasSuccess="passed"></fg-input>
                    </ValidationProvider>
                  </div>
                  <div class="col-3 text-center">
<!--                    <el-checkbox v-if="package.auditorsSC" v-model="auditorsSC" :disabled="auditors === 0"-->
<!--                                 v-on:change="$emit('checkout-service', {name: 'auditors_sc', value: auditorsSC})">-->
<!--                      € 5-->
<!--                    </el-checkbox>-->
                    <el-checkbox v-if="package.auditorsSC" v-model="auditorsSC" disabled> € 5 </el-checkbox>
                  </div>
                </div>

<!--            <div class="row">-->
<!--              <div class="col-8">-->
<!--                <el-checkbox v-model="checked" v-on:change="$emit('checkout-service', {name: 'expertsReview', value: checked})"> Let involve other experts for review</el-checkbox>-->
<!--              </div>-->
<!--              <div class="col-4">-->
<!--                <p class="text-right">€ 1</p>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="row">-->
<!--              <div class="col-8">-->
<!--                <el-checkbox v-model="checked2" v-on:change="$emit('checkout-service', {name: 'other', value: checked2})"> Other Service</el-checkbox>-->
<!--              </div>-->
<!--              <div class="col-4">-->
<!--                <p class="text-right">€ 5</p>-->
<!--              </div>-->
<!--            </div>-->

              </form>
            </ValidationObserver>

            <p class="description text-center mt-3" style="font-size: 12px">(*) Please remember that all the transactions made on the blockchain cannot be reverted or deleted.</p>
          </div>


          <div v-if="saving" class="col-12 text-danger text-center"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.saving') }}...</div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import { Rate, Checkbox, Tooltip } from 'element-ui';
  import { extend } from "vee-validate";
  import { numeric, min_value, max_value } from "vee-validate/dist/rules";

  extend("numeric", numeric);
  extend("min_value", min_value);
  extend("max_value", max_value);

  export default {
    components: {
      [Rate.name]: Rate,
      [Checkbox.name]: Checkbox,
      [Tooltip.name]: Tooltip
    },
    data() {
      return {
        images_base_url: process.env.VUE_APP_IMAGES_BASE_URL,
        loading: true,
        error: null,
        saving: false,
        listing: null,
        deliveryTimeSC: false,
        revisionsSC: false,
        auditors: 0,
        auditorsSC: true,
      }
    },
    props: {
      package: Object,
      checkoutID: String,
      totalServices: Number,
      totalAuditors: Number,
      listingID: String
    },
    methods: {
      auditorsChanged() {
        this.auditors = parseInt(this.auditors);
        if (isNaN(this.auditors) || this.auditors < 0 || this.auditors > this.package.auditorsMax)
          this.auditors = 0;
        this.$emit('checkout-service', {name: 'auditors', value: this.auditors})
      },
      validate() {
        return this.$refs.form.validate().then( res => {
          if (!res)
            return false;

          this.saving = true;
          let data = {
            listingPackage: this.package.id,
            deliveryTimeSC: this.deliveryTimeSC,
            revisionsSC: this.revisionsSC,
            auditors: parseInt(this.auditors),
            auditorsSC: this.auditorsSC,
            servicesTotalCost: this.totalServices,
            auditorsTotalCost: this.totalAuditors
          };

          let config = {};
          if (this.checkoutID === null) {
            config = {
              method: 'post',
              url: process.env.VUE_APP_SERVER_BASE_URL + 'checkouts',
              data: data
            };
          }
          else {
            config = {
              method: 'patch',
              url: process.env.VUE_APP_SERVER_BASE_URL + 'checkouts/' + this.checkoutID,
              headers: {'Content-Type': 'application/merge-patch+json'},
              data: data
            };
          }

          return axios(config)
            .then(response => {
              this.$emit('checkout', response.data);
              this.saving = false;
              return true;
            }).catch(error => {
              this.saving = false;
              if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                this.$store.commit('logout');
                this.$router.push('/login');
              }
              else {
                this.error = 'An error occurred. Please try again.';
                console.log(error);
              }
              return false;
            });
        });
      },
      initializeStep(idListing) {
        let id = this.listingID;
        if (id === null)
          id = idListing;
        if(id !== null) {
          axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'listings/' + id)
              .then(response2 => {
                this.listing = response2.data;
                this.loading = false;
              }).catch(error => {
            this.loading = false;
            if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
              this.$store.commit('logout');
              this.$router.push('/login');
            } else if (error.response && error.response.status === 404) {
              console.log("listing not found");
              this.$router.push('/customer/dashboard');
            } else {
              this.error = 'err_data_not_received';
              console.log(error);
            }
          });
        }
      }
    },

    beforeMount() {
    }
  }
</script>

<style scoped>

</style>
