<template>
  <div class="wrapper">
    <!--    <side-bar type="sidebar" :sidebar-links="$sidebar.sidebarLinks" :title="company_name">-->
    <!--      <user-menu></user-menu>-->
    <!--    </side-bar>-->
    <top-navbar></top-navbar>
    <div class="main-panel no-sidebar">
      <!--      <top-navbar></top-navbar>-->

      <div class="content" >
        <transition name="fade" mode="out-in">
          <!-- your content here -->


  <div class="content">

    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-lg-8">
            <div class="card">
              <div class="card-header">
                <h4 class="title">{{ listing.title }}</h4>
                <p class="m-2" v-if="feedback.length === 0">
                  <i class="fa fa-star text-warning"></i> No reviews.
                </p>
                <p class="m-2" v-else>
                  <el-rate class="d-inline"
                    v-model="avgFeedback"
                    disabled
                    show-score
                    text-color="#ff9900"
                    score-template="{value}">
                  </el-rate>
                  (<span >{{ feedback.length }}</span> reviews)
                </p>
              </div>
              <div class="card-body">
                <div v-if="loading" class="loading"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.loading') }}</div>
                <div class="row">
                  <div class="col-12">
                    <el-carousel height="450px">
                      <el-carousel-item v-for="item in listing.images" :key="listing.images.id">
                        <img :src="item.contentUrl" width="auto" height="400px" class="m-auto d-block"/>
                      </el-carousel-item>
                    </el-carousel>
                  </div>

                  <div class="col-12 mt-5">
                    <p class="d-block m-2" style="white-space: pre-line; overflow: auto;">{{ listing.description }} </p>
                  </div>

                </div>

              </div>
            </div>
          </div>

          <div class="col-12 col-lg-4">

            <card>
              <h5 slot="header" class="text-center">{{ $t('listing.price_packages') }}</h5>
              <tabs value="Description">
                <tab-pane :title="pack.name" :key="pack['@id']" v-for="pack in packages">
                  <h4 class="text-center mt-1">{{ pack.currency.symbol }} {{ pack.price }}</h4>
                  <p class="text-center d-block m-1" style="white-space: pre-line; overflow: auto;">{{ pack.description }}</p>

                  <div class="row">
                    <div class="col-12 text-center mt-3" v-if="pack.deliveryTime && pack.deliveryTime > 0">
                      <p>
                        <i class="nc-icon nc-delivery-fast align-middle" style="font-size: 20px"></i> Delivery Time:
                        <b class="ml-1">{{pack.deliveryTime}} {{pack.deliveryTimeUnit}}</b>
                        <el-tooltip v-if="pack.deliveryTimeSC" content="constraint verifiable by a Smart Contract" class="ml-1">
                          <i class="fa fa-check text-success align-middle" style="font-size: 20px"></i> with SC
                        </el-tooltip>
                        <el-tooltip v-else content="constraint not verifiable by a Smart Contract" class="ml-1">
                          <i class="fa fa-times text-danger align-middle" style="font-size: 20px"></i> without SC
                        </el-tooltip>
                      </p>
                    </div>


                    <div class="col-12 text-center mt-3" v-if="pack.revisionsMax && pack.revisionsMax > 0">
                      <p>
                        <i class="nc-icon nc-refresh-69 align-middle" style="font-size: 20px"></i> Max Revisions:
                        <b class="ml-1">{{pack.revisionsMax}}</b>
                        <el-tooltip v-if="pack.revisionsSC" content="constraint verifiable by a Smart Contract" class="ml-1">
                          <i class="fa fa-check text-success align-middle" style="font-size: 20px"></i> with SC
                        </el-tooltip>
                        <el-tooltip v-else content="constraint not verifiable by a Smart Contract" class="ml-1">
                          <i class="fa fa-times text-danger align-middle" style="font-size: 20px"></i> without SC
                        </el-tooltip>
                      </p>
                    </div>


                    <div class="col-12 text-center mt-3" v-if="pack.auditorsMax && pack.auditorsMax > 0">
                      <p class="mb-1">
                        <i class="nc-icon nc-badge align-middle" style="font-size: 20px"></i> Max Auditors:
                        <b class="ml-1">{{pack.auditorsMax}}</b>
                        <el-tooltip v-if="pack.auditorsSC" content="traceable by a Smart Contract" class="ml-1">
                          <i class="fa fa-check text-success align-middle" style="font-size: 20px"></i> with SC
                        </el-tooltip>
                        <el-tooltip v-else content="Not traceable by a Smart Contract" class="ml-1">
                          <i class="fa fa-times text-danger align-middle" style="font-size: 20px"></i> without SC
                        </el-tooltip>
                      </p>
                      <div v-if="pack.auditorsDuePercentage && pack.auditorsDuePercentage > 0">
                        <el-tooltip content="a part of the total cost for auditors, is included in the price of the package" class="ml-1">
                          <p><b>{{ pack.auditorsDuePercentage }}%</b> included in the price!</p>
                        </el-tooltip>

                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12 text-center">
                      <router-link  :to="'/checkout/' + pack.id" tag="p-button" class="btn-info">Buy</router-link>
                    </div>
                  </div>
                </tab-pane>
              </tabs>
            </card>

            <user-card v-if="listing.owner" :user_id="iriToId(listing.owner)">
            </user-card>

          </div>

        </div>
      </div>
    </div>


    <div class="row">
      <feedback-card v-for="f in feedback" :key="f.id" class="col-4"
                     :score="f.score"
                     :review="f.review"
                     :created-on="f.createdOn.date.split(' ')[0]">
      </feedback-card>
    </div>
    <router-view></router-view>
  </div>

          <router-view></router-view>
        </transition>
      </div>

      <content-footer></content-footer>
    </div>
  </div>
</template>

<script>

  import TopNavbar from '../TopNavbar.vue'
  import ContentFooter from '../ContentFooter.vue'
  import UserCard from '../User/UserCard.vue'
  import { Card, Tabs, TabPane } from 'src/components/UIComponents'
  import { Carousel, CarouselItem, Rate, Tooltip } from 'element-ui';
  import axios from 'axios';
  import feedbackCard from "src/components/GeneralViews/FeedbackCard";

  export default {
    components: {
      TopNavbar,
      ContentFooter,
      UserCard,
      Card,
      Tabs,
      TabPane,
      [Carousel.name]: Carousel,
      [CarouselItem.name]: CarouselItem,
      [Rate.name]: Rate,
      [Tooltip.name]: Tooltip,
      feedbackCard
    },
    data () {
      return {
        company_name: process.env.VUE_APP_COMPANY_NAME,
        loading: false,
        listing: {listingPackages: []},
        packages: [],
        images_base_url: process.env.VUE_APP_IMAGES_BASE_URL,
        error: null,
        feedback: [],
        avgFeedback: 0
      }
    },
    methods: {
      toggleSidebar () {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false)
        }
      },
      iriToId(iri) {
        if(typeof iri !== "string")
          return;
        let split = iri.split('/');
        return split[split.length-1];
      },
      getFeedback() {
        axios.get(process.env.VUE_APP_SERVER_BASE_URL + "feedback/get_listing_feedback/" + this.listing.id)
        .then(response => {
          this.feedback = response.data.list;
          this.avgFeedback = parseFloat(response.data.avg);
        }).catch(error => {
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.$store.commit('logout');
            this.$router.push('/login');
          } else if (error.response && error.response.status === 404) {
            console.log("feedback not found");
          } else {
            this.error = 'err_data_not_received';
            console.log(error);
          }
        });
      },
    },
    beforeMount() {
      let fetchedId = this.$route.params.id;
      this.loading = true;
      axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'listings/' + fetchedId)
      .then(response => {
        this.listing = response.data;
        this.getFeedback();

        for (let index = 0; index < response.data.listingPackages.length; index++)
        {
          let split = response.data.listingPackages[index].split('/');
          let idP = split[split.length -1];
          axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'listing_packages/' + idP)
          .then(res => {
            if(res.data.status === 'new')
              this.packages.push(res.data);
            this.loading = false;
          }).catch(error => {
            this.loading = false;
            if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
              this.$store.commit('logout');
              this.$router.push('/login');
            }
            else if (error.response && error.response.status === 404)
            {
              this.error = 'listing.err_listing_not_found';
              console.log(error);
            }
            else {
              this.error = 'err_data_not_received';
              console.log(error);
            }
          })
        }



      }).catch(error => {
        this.loading = false;
        if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
          this.$store.commit('logout');
          this.$router.push('/login');
        }
        else if (error.response && error.response.status === 404)
        {
          this.error = 'listing.err_listing_not_found';
          console.log(error);
        }
        else {
          this.error = 'err_data_not_received';
          console.log(error);
        }
      });
    }
  }

</script>
<style>

</style>
